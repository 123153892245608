import { gql } from 'urql';
import { queryContext } from '../utils/api.utils';
import * as G from '../../nom-server/src/generated/frontendGraphql';

// TODO add slides to RestaurantQuery
export default queryContext<
  G.QueryRestaurantArgs,
  { restaurant: G.Restaurant; restaurantV2: G.RestaurantV2 }
>(gql`
  query RestaurantQuery {
    restaurantV2
    restaurant {
      __typename
      id
      name
      settings {
        mainColor
        gotStarted
      }
      rootSections {
        __typename
        id
        name
        note
        childSections {
          __typename
          id
          name
          note
          items {
            __typename
            id
            name
            images
            description
            ingredients
            price
            optionGroups {
              __typename
              id
              name
              min
              max
              options {
                __typename
                id
                name
                price
              }
            }
            tags {
              __typename
              id
              label
              value
            }
          }
        }
      }
      stringified
    }
  }
`);
