/**
 * @file This file creates the view to show selected items in a list.
 */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { State } from '../state/store';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import {
  Order,
  SelectedItem,
  SelectedValue,
  SelectedItemSummary,
  OrderSummary,
} from '../models/selection.model';
import { Theme } from '../models/menu.model';
import { pluralize } from '../utils/model.utils';
import { CheckCircle, ListIcon, NoItemsIcon, NotchLeft } from '../icons';
import { buildRemoveItem } from '../state/selectedItemList/actionCreators.selectedItemList';
import SelItemSummary from '../components/SelItemSummary.component';
import './SelectedItemList.container.css';

function buildSelectedItemSummary(selItem: SelectedItem): SelectedItemSummary {
  const values: SelectedValue[] = [];
  const quantity: number = selItem.quantity;
  const totalPrice =
    selItem.options.reduce(
      (price, opt) =>
        price +
        opt.values.reduce((price, val) => {
          values.push(val);
          return price + (val.value.price || 0) * val.count;
        }, 0),
      selItem.item.price || 0,
    ) * quantity;

  return {
    ...selItem,
    totalPrice,
    values,
    quantity,
  };
}

function buildOrderSummary(order: Order): OrderSummary {
  const itemSummaries = Object.values(order).map((selItem) =>
    buildSelectedItemSummary(selItem),
  );
  const totalPrice = itemSummaries.reduce(
    (price, sum) => price + sum.totalPrice,
    0,
  );

  return {
    totalPrice,
    itemSummaries,
  };
}

interface SelectedItemListParams {
  onClose?: () => void;
  onConfirm: (name: string, message: string) => unknown;
  onRemove: (item: SelectedItem) => unknown;
  theme?: Theme;
}

function SelectedItemList(params: SelectedItemListParams): React.ReactElement {
  const { onConfirm, onRemove, theme } = params;
  const order = useSelector((state: State) => state.selectedItemList.order);
  const orderSummary = buildOrderSummary(order);
  const itemSummaries = orderSummary.itemSummaries;

  const numberOfItemsInCart = Object.values(order)
    .map((selItem) => selItem.quantity)
    .reduce((a, b) => a + b, 0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function returnToMenu() {
    navigate(-1);
  }

  function handleSelectedItemClick(
    itemSummary: SelectedItemSummary,
    event: React.MouseEvent,
  ) {
    navigate(`/order/${itemSummary.id}`);
    event.stopPropagation();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="selected-item-list">
      <>
        <div className="order-header">
          <Button
            variant="light"
            className="order-back-button"
            onClick={returnToMenu}
          >
            {NotchLeft}
          </Button>
          <div className="title">My List</div>
        </div>
        <div className="my-list-header">
          {numberOfItemsInCart ? (
            <div className="instructions">
              <div style={{ marginRight: '16px' }}>{CheckCircle}</div>
              <div>
                When ready, please read your order out loud to the{' '}
                {theme?.counterService ? (
                  <>staff at the counter</>
                ) : theme?.cafe ? (
                  <>barista</>
                ) : (
                  <>wait staff</>
                )}
                .
              </div>
            </div>
          ) : (
            <>
              <div className="no-items-icon">{NoItemsIcon}</div>
              <div className="title"> There are no items on your list. </div>
              <div className="instructions">
                No items on your list, yet! Start by adding items from the menu
                to draft your meal order.
              </div>
            </>
          )}
        </div>
      </>

      {numberOfItemsInCart ? (
        <div className="selected-items">
          {itemSummaries.map((itemSummary: SelectedItemSummary) => (
            <SelItemSummary
              key={itemSummary.id}
              itemSummary={itemSummary}
              editButton={true}
              onRemove={onRemove}
              onConfirm={onConfirm}
              onClick={handleSelectedItemClick}
            />
          ))}
          <div className="total">
            <div> Total </div>{' '}
            <div>${(orderSummary.totalPrice / 100).toFixed(2)}</div>
          </div>

          <div className="price-disclaimer">
            This is the estimated cost, without taxes or gratuities.
          </div>
        </div>
      ) : (
        <div className="button-container">
          <Button
            className="return-to-menu-button"
            variant="dark"
            onClick={() => returnToMenu()}
          >
            Go Back To Main Menu
          </Button>
        </div>
      )}
    </div>
  );
}

export default SelectedItemList;
