/**
 * @file This file is used to define the ViewScroller component which is a
 * button that when pressed, descends the user to the next view.
 */

import React from 'react';
import { DoubleNotchDown } from '../icons';
import cx from 'classnames';

import './ViewScroller.component.css';

interface ViewScroller {
  className?: string;
  text: string;
  onClick?: () => void;
}

function ViewScroller(params: ViewScroller) {
  const { className, text, onClick } = params;

  return (
    <div className={cx('view-scroller', className)} onClick={onClick}>
      <h3 className="view-scroller-text">{text}</h3>
      {DoubleNotchDown}
    </div>
  );
}

export default ViewScroller;
