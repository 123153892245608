import { css } from '@emotion/css';
import * as G from '../../nom-server/src/generated/frontendGraphql';

const SectionHeader: React.FC<{
  headerText: string;
  background?: string;
  restaurant: G.RestaurantV2;
}> = (props) => {
  return (
    <div
      className={css`
        background: ${props.background
          ? props.background
          : props.restaurant?.settings?.mainColor || '#032165'};
        padding: 20px 30px;
        text-transform: uppercase;
        text-align: center;
        ${'color: ' +
        (props.restaurant?.settings?.sectionHeaderTextColor
          ? props.restaurant?.settings?.sectionHeaderTextColor
          : '#fff') +
        ';'}
        font: 17px var(--font-sans);
      `}
    >
      {props.headerText}
    </div>
  );
};

export default SectionHeader;
