/**
 * @file This file is used to define the App component which provides the layout
 * for the app.
 */

import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  createContext,
} from 'react';
import ReactGA from 'react-ga4';
import * as R from 'ramda';
import {
  useMatch,
  useNavigate,
  useLocation,
  useSearchParams,
  BrowserRouter,
} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import MediaQuery from 'react-responsive';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import _ from 'lodash';
import { css } from '@emotion/css';
import { FaYelp } from 'react-icons/fa';
import { FiFacebook, FiInstagram } from 'react-icons/fi';

import { useGetMenu } from './fetchers/api';
import { usePatchRestaurantStringifiedMutation } from './fetchers/usePatchRestaurantStringifiedMutation';
import LandingView from './containers/LandingView.container';
import MenuView from './containers/MenuView.container';
import AboutView from './containers/AboutView.container';
import QrCodeView from './containers/QrCodeView.container';
import OrderView from './containers/OrderView.container';
import ConfirmationView from './containers/ConfirmationView.container';
import ConfirmationMsg from './components/ConfirmationMsg.component';
import { Menu } from './models/menu.model';
import ItemDetails from './containers/ItemDetails.container';
import { getLocationSubDomain } from './utils/window.utils';
import { useIntegrations } from './integrations/UseIntegrations';
import * as G from '../nom-server/src/generated/frontendGraphql';

import './App.css';
import './icons/index.css';
import { ConfirmationIcon } from './icons';
import useRestaurantQuery from './fetchers/useRestaurantQuery';
import { restaurantToMenu, restaurantV2ToMenu } from './utils/model.utils';
import { useMatches } from './utils/router.utils';
import { App as NomV2 } from './nomV2/index';

window.R = R;

const subDomain = window.location.hostname.split('.')[0];

const NOM_GA_TAG = 'G-E5HDFLBYVT';

if (subDomain === 'waldorfpedregal') {
  ReactGA.initialize('G-2ZEYB3NFCG');
} else if (subDomain === 'hiltonloscabos') {
  ReactGA.initialize('G-BTPTTJ32JD');
} else if (subDomain === 'banyasf') {
  ReactGA.initialize('G-QMY6MCWGCX');
} else if (subDomain === 'conradpdm') {
  ReactGA.initialize('G-9DXPR1V55Q');
} else if (subDomain === 'conradpdmes') {
  ReactGA.initialize('G-86V06F3TJR');
} else {
  ReactGA.initialize(NOM_GA_TAG);
}

const contactModalClass = css`
  color: white;
  background-color: #282828;
  border: none;
  display: flex;
  align-items: center;
  white-space: pre;
  padding: 32px;
`;

const lineClass = css`
  border-top: 2px solid #444;
  width: -webkit-fill-available;
  margin: 16px 32px;
  text-align: center !important;
`;

const iconListClass = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
`;

const contactClass = css`
  text-align: center;
`;

const iconClass = css`
  cursor: pointer;
`;

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);
}

type AppContentParams = {
  menu?: Menu;
  loading?: boolean;
  error?: Error;
};

const isTrainingURL = new URLSearchParams(window.location.search).get(
  'trainingMode',
);
const trainingMode = isTrainingURL === '1';

function AppContent(params: AppContentParams) {
  const { menu, loading, error } = params;
  usePageViews();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const matchAboutPagePath = useMatch('/about');
  const matchMenuPagePath = useMatches([
    '/explore/:sectionName',
    '/explore/:sectionName/:subsectionName',
    '/explore/:sectionName/:subsectionName/:itemName',
  ]);
  const matchQrPath = useMatch('/qr');
  const matchOrderPath = useMatches(['/order', '/order/:selectionId']);
  const appPaths = [
    matchAboutPagePath,
    matchMenuPagePath,
    matchQrPath,
    matchOrderPath,
  ];

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationItem, setConfirmationItem] = useState<
    string | undefined
  >();
  const [confirmationMessage, setConfirmationMessage] = useState<
    string | undefined
  >();

  function showConfirmation() {
    setConfirmationOpen(true);
  }

  function hideConfirmation() {
    setConfirmationOpen(false);
  }

  function handleShowConfirmation(itemName?: string, message?: string) {
    setConfirmationItem(itemName);
    setConfirmationMessage(message);
    showConfirmation();
    setTimeout(() => hideConfirmation(), 2000);
  }

  const history = useNavigate();

  useEffect(() => {
    const flagExists = location.search.includes('trainingMode=1');

    if (trainingMode && !flagExists) {
      const newUrl = `${window.location.pathname}?trainingMode=1`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }, [location.search, history, trainingMode]);

  useEffect(() => {
    // Update global fonts
    const updateCSSVariable = (varName: string, value: string) => {
      document.documentElement.style.setProperty(varName, value);
    };

    if (subDomain === 'waldorfpedregal') {
      updateCSSVariable('--font-sans', '"Waldorf Astoria Sans", sans-serif');
      updateCSSVariable('--font-serif', '"Waldorf Astoria Serif", serif');
    } else {
      updateCSSVariable('--font-serif', 'Ariel, sans-serif');
      updateCSSVariable('--font-sans', 'Ariel, sans-serif');
    }
  }, []);

  // TODO: Seems redundant but breaks if I change it
  const confirmationMessageModal = confirmationItem && confirmationMessage && (
    <div className="confirmation-msg">
      <Modal
        show={confirmationOpen}
        onHide={hideConfirmation}
        centered
        dialogClassName="confirmation-msg"
        size="sm"
      >
        <ConfirmationMsg
          icon={ConfirmationIcon}
          itemName={confirmationItem}
          message={confirmationMessage}
        />
      </Modal>
    </div>
  );

  if (matchQrPath) {
    return <QrCodeView />;
  }

  if (matchOrderPath && menu) {
    const { selectionId } = matchOrderPath.params;
    return <OrderView menu={menu} selectionId={selectionId} />;
  }

  if (error) {
    // TODO throw here
    return (
      <>
        <code>
          <b>Error:</b>
        </code>
        <br></br>
        <code>{error.message}</code>
      </>
    );
  }

  if (loading) {
    return (
      <h3
        style={{
          position: 'absolute',
          color: '#cccccc',
          display: 'flex',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Loading...
      </h3>
    );
  }

  if (menu) {
    const { sectionName, subsectionName, itemName } =
      matchMenuPagePath?.params || {};

    const item = menu.sections
      .find((section) => section.name === sectionName)
      ?.subsections.find((subsection) => subsection.name === subsectionName)
      ?.items.find((item) => item.name === itemName);

    const itemGroup = menu.sections
      .find((section) => section.name === sectionName)
      ?.subsections.find((subsection) => subsection.name === subsectionName)
      ?.itemGroups?.find((itemGroup) => String(itemGroup.id) === itemName);

    const clearItemSelection = () => {
      if (trainingMode) {
        navigate(-2);
      } else {
        navigate(-1);
      }
    };

    return (
      <>
        <MediaQuery maxWidth={1040}>
          <LandingView className="full-view landing" menu={menu} />
        </MediaQuery>
        {matchAboutPagePath && <AboutView menu={menu} />}
        {(matchMenuPagePath || !_.compact(appPaths).length) && (
          <>
            <MenuView
              className="view"
              menu={menu}
              onConfirm={handleShowConfirmation}
              isTraining={trainingMode}
            />
            {/* Bottom-sheet for mobile; Modal for desktop */}
            <MediaQuery maxWidth={1040}>
              <div className="item-details-sheet-mobile">
                <SwipeableBottomSheet
                  open={Boolean(itemName)}
                  onChange={clearItemSelection}
                  bodyStyle={{ borderRadius: '30px 30px 0 0' }}
                >
                  {item && (
                    <ItemDetails
                      itemName={itemName}
                      item={item}
                      onClose={clearItemSelection}
                      contest={menu.contest}
                      theme={menu.theme}
                      onConfirm={handleShowConfirmation}
                    />
                  )}
                </SwipeableBottomSheet>
              </div>
            </MediaQuery>
            <MediaQuery minWidth={1041}>
              <Modal
                show={Boolean(itemName)}
                onHide={clearItemSelection}
                centered
                dialogClassName="item-details-modal"
                size="xl"
              >
                {item && (
                  <ItemDetails
                    itemName={itemName}
                    item={item}
                    onClose={clearItemSelection}
                    contest={menu.contest}
                    theme={menu.theme}
                    onConfirm={handleShowConfirmation}
                  />
                )}
              </Modal>
            </MediaQuery>
          </>
        )}

        {confirmationMessageModal}
        <Modal
          contentClassName={contactModalClass}
          show={Boolean(searchParams.get('contact'))}
          onHide={() =>
            setSearchParams(deleteParam(searchParams, 'contact').toString())
          }
        >
          <h3>Visit Us</h3>
          <div className={lineClass} />
          <div className={contactClass}>{menu.info?.contact}</div>
          {(menu.info?.facebook || menu.info?.yelp || menu.info?.instagram) && (
            <>
              <div className={lineClass} />
              <div className={iconListClass}>
                {menu.info?.instagram && (
                  <FiInstagram
                    className={iconClass}
                    onClick={openUrl(menu.info.instagram)}
                    size={24}
                  />
                )}
                {menu.info?.facebook && (
                  <FiFacebook
                    className={iconClass}
                    onClick={openUrl(menu.info.facebook)}
                    size={24}
                  />
                )}
                {menu.info?.yelp && (
                  <FaYelp
                    className={iconClass}
                    onClick={openUrl(menu.info.yelp)}
                    size={24}
                  />
                )}
              </div>
            </>
          )}
        </Modal>
        <Modal
          contentClassName={contactModalClass}
          show={Boolean(searchParams.get('hours'))}
          onHide={() =>
            setSearchParams(deleteParam(searchParams, 'hours').toString())
          }
        >
          <h3>Hours</h3>
          <div className={lineClass} />
          <div className={contactClass}>{menu.info?.hours}</div>
        </Modal>
      </>
    );
  }

  return <h1>Error: no menu</h1>;
}

function App(): React.ReactElement {
  const subdomain = getLocationSubDomain().toLowerCase();

  let { data: menu, loading, error } = useGetMenu(subdomain);
  const [result] = useRestaurantQuery();
  if (error) {
    // Use stringified hack for now
    const stringified = result.data?.restaurant.stringified;
    const stringifiedV2 = result.data?.restaurantV2;
    if (stringifiedV2) {
      menu = restaurantV2ToMenu(stringifiedV2 as unknown as G.RestaurantV2);
    } else if (stringified) {
      menu = restaurantToMenu(JSON.parse(stringified) as G.Restaurant);
    }
    loading = result.fetching;
    error = result.error;
  }

  (window as any).menu = menu;

  useIntegrations(menu?.integrations);

  const [, patchRestaurantStringifiedMutation] =
    usePatchRestaurantStringifiedMutation();

  (window as any).patchRestaurantStringifiedMutation =
    patchRestaurantStringifiedMutation;
  (window as any).prest = (name: string, dname: string, id: number) => {
    console.log('prest', name, dname, id);
    patchRestaurantStringifiedMutation({
      input: JSON.stringify(
        (window as any).mapMenuToRestaurant(
          (window as any).keyedCustomerData[name],
          { id },
          name,
          dname,
        ),
      ),
      id,
    });
  };

  useEffect(() => {
    // sets up custom theme
    const root = document.documentElement;
    menu?.theme?.mainColor
      ? root.style.setProperty('--main-bg-color', menu.theme.mainColor)
      : root.style.setProperty('--main-bg-color', '#eadecc');
  }, [menu]);

  if (
    (subdomain === 'waldorfpedregal' ||
      subdomain === 'wptest' ||
      subdomain === 'ivwbwinebar' ||
      subdomain === 'hiltonloscabos' ||
      subdomain === 'ivwbwines' ||
      subdomain === 'banyasf' ||
      subdomain === 'manhattanclub' ||
      subdomain === 'ivwbkapolei' ||
      subdomain === 'conradnycdowntown' ||
      subdomain === 'conradpdm' ||
      subdomain === 'conradpdmes' ||
      subdomain === 'waldorfpedregalresidences' ||
      subdomain === 'epicmiami' ||
      subdomain === 'oceanasantamonica' ||
      subdomain === 'icbarclay' ||
      subdomain === 'hiltonhv' ||
      subdomain === 'holidayinnwaikiki' ||
      subdomain === 'halekulani' ||
      subdomain === 'halepuna'||
      subdomain === 'hyattwaikiki' ||
      subdomain === 'alohilani' ||
      subdomain === 'hlctest' ||
      subdomain === 'halepunatest' ||
      subdomain === 'courtyardnorthshore' ||
      subdomain === 'kahala' ||
      subdomain === 'demo') &&
    result.data?.restaurantV2
  ) {
    document.body.style.setProperty('background-color', 'white');
    return <NomV2 menu={result.data.restaurantV2} />;
  }

  return (
    <div className="app">
      <BrowserRouter>
        <AppContent menu={menu} loading={loading} error={error} />
      </BrowserRouter>
    </div>
  );
}

function deleteParam(s: URLSearchParams, p: string) {
  s.delete(p);
  return s;
}

function openUrl(url: string) {
  return () => window.open(url, '_blank');
}

export const training = createContext(trainingMode);
export default App;
