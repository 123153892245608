import { useMemo } from 'react';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import FullBleed from './FullBleed';
import ListItem from './ListItem';
import { SectionInline } from './SectionInline';
import { SectionNav } from './SectionNav';
import * as M from '../models/menu.model';
import { css } from '@emotion/css';
import Markdown from './Markdown';
import { useSelector } from 'react-redux';
import { State } from '../state/store';
import { getLocalizedValue } from '../utils/translate.utils';

/**
 * Example: https://www.figma.com/file/HFdZpzKn7MSGPhx2pBtVRF/Nom-Waldorf?node-id=929%3A24225&t=GLGArAUzSWebGayf-4
 */
export function SectionExpanded({
  restaurant,
  section,
  sectionPathAry,
  children,
  hideName,
}: {
  restaurant: M.Restaurant;
  section: G.SectionV2;
  sectionPathAry: G.SectionV2[];
  children?: React.ReactNode;
  hideName?: boolean;
}): JSX.Element {
  const path = useMemo(
    () => sectionPathAry.map((s) => s.id).join('/'),
    [sectionPathAry],
  );

  const childSections = useMemo(
    () =>
      section.childSectionIds
        ?.map((sectionId) => restaurant.sectionById[sectionId])
        .filter((s) => !s.disabled) || [],
    [section],
  );

  const items = useMemo(
    () => section.itemIds?.map((itemId) => restaurant.itemById[itemId]) || [],
    [section],
  );

  const selectedLanguage = useSelector(
    (state: State) => state.language.language,
  );

  function renderChilden() {
    if (children) return children;
    return (
      <>
        {items.map((item) => {
          const tagValues: string[] = [];
          item.tagIds.forEach((tagId) => {
            const tagObj = restaurant?.tags?.find((tag) => tag.id == tagId);
            if (tagObj) {
              tagValues.push(tagObj.value);
            }
          });

          const itemTitle = getLocalizedValue(
            item,
            'namesLocalized',
            selectedLanguage,
            item.name,
          );
          const itemDescription = getLocalizedValue(
            item,
            'descriptionsLocalized',
            selectedLanguage,
            item.description ?? '',
          );

          return (
            <>
              {item.available ? (
                <ListItem
                  key={item.id}
                  restaurant={restaurant}
                  id={item.id}
                  colorScheme="light"
                  to={'/' + path + '/' + item.id}
                  title={item.name}
                  titleLocalized={itemTitle}
                  description={itemDescription || ''}
                  price={item.price?.toString() || ''}
                  section={section.name}
                  expanded={section.type === 'expanded'}
                  tags={tagValues}
                />
              ) : null}
            </>
          );
        })}
        {childSections.map((childSection) => {
          switch (childSection.type) {
            case 'nav':
              return (
                <SectionNav
                  key={childSection.id}
                  restaurant={restaurant}
                  section={childSection}
                  sectionPathAry={sectionPathAry}
                >
                  {children}
                </SectionNav>
              );
            case 'inline':
              return (
                <SectionInline
                  key={childSection.id}
                  restaurant={restaurant}
                  section={childSection}
                  sectionPathAry={sectionPathAry}
                />
              );
            case 'expanded':
            default:
              return (
                <SectionInline
                  key={childSection.id}
                  restaurant={restaurant}
                  section={childSection}
                  sectionPathAry={sectionPathAry}
                />
              );
          }
        })}
      </>
    );
  }

  const fullBleedImage = section.images && section.images[0];
  const fullBleedTitle = hideName
    ? undefined
    : getLocalizedValue(
        section,
        'namesLocalized',
        selectedLanguage,
        section.name ?? '',
      );
  const sectionDescription = getLocalizedValue(
    section,
    'descriptionsLocalized',
    selectedLanguage,
    section.description ?? '',
  );

  return (
    <>
      {fullBleedImage || fullBleedTitle ? (
        <FullBleed
          title={fullBleedTitle}
          variant="framed"
          bgSrc={
            fullBleedImage ||
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADAQMAAACOOjyFAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAHVaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJYTVAgQ29yZSA1LjQuMCI+CiAgIDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+CiAgICAgIDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiCiAgICAgICAgICAgIHhtbG5zOnRpZmY9Imh0dHA6Ly9ucy5hZG9iZS5jb20vdGlmZi8xLjAvIj4KICAgICAgICAgPHRpZmY6Q29tcHJlc3Npb24+MTwvdGlmZjpDb21wcmVzc2lvbj4KICAgICAgICAgPHRpZmY6T3JpZW50YXRpb24+MTwvdGlmZjpPcmllbnRhdGlvbj4KICAgICAgICAgPHRpZmY6UGhvdG9tZXRyaWNJbnRlcnByZXRhdGlvbj4yPC90aWZmOlBob3RvbWV0cmljSW50ZXJwcmV0YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgoC2IAFAAAAA1BMVEVHcEyC+tLSAAAAAXRSTlMAQObYZgAAAApJREFUCB1jAAMAAAYAAcXjo3IAAAAASUVORK5CYII='
          }
          restaurant={restaurant}
        />
      ) : null}
      {section.description ? (
        <Markdown
          className={css`
            font: 17px var(--font-sans);
            text-align: left;
            padding: 20px;
            background: ${restaurant.settings?.mainColor || '#000'};
            color: ${restaurant.settings?.sectionHeaderTextColor};
          `}
        >
          {sectionDescription}
        </Markdown>
      ) : null}

      {/* Child sections and items */}
      {renderChilden()}
    </>
  );
}
