import { useEffect } from 'react';

/**
 * useEffect hook to insert a style tag into the DOM to override styles for
 * html, body, etc.
 */
export function useGlobalStyles(css: string) {
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = css;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);
}
