import { ChatState, ChatActionTypes, Msg } from './types.chat';

const initialState: ChatState = {
  messages: [],
  welcomeMessage: '',
  chatWebSocket: null,
};

const aggregateBotMessages = (messages: Msg[]): Msg[] => {
  const aggregatedMessages: Msg[] = [];
  let currentBotMessage = '';
  let collectedSuggestions: JSX.Element[] = [];

  messages.forEach((msg, index) => {
    if (msg.type === 'user') {
      // Push any accumulated chatbot message before a new user message
      if (currentBotMessage) {
        aggregatedMessages.push({
          type: 'ai',
          text: currentBotMessage.trim(),
          suggestions: collectedSuggestions,
        });
        currentBotMessage = '';
        collectedSuggestions = [];
      }
      aggregatedMessages.push(msg);
    } else if (msg.type === 'ai') {
      currentBotMessage += msg.text;
      collectedSuggestions = [
        ...new Set([...collectedSuggestions, ...msg.suggestions]),
      ];
    }

    // If the last message found, push the remaining chatbot message
    if (index === messages.length - 1 && currentBotMessage) {
      aggregatedMessages.push({
        type: 'ai',
        text: currentBotMessage,
        suggestions: collectedSuggestions,
      });
    }
  });

  return aggregatedMessages;
};

export const chatReducer = (
  state = initialState,
  action: ChatActionTypes,
): ChatState => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return {
        ...state,
        messages: aggregateBotMessages([...state.messages, action.payload]),
      };
    case 'SET_WELCOME_MESSAGE':
      return {
        ...state,
        welcomeMessage: action.payload,
      };
    case 'INITIALIZE_WEBSOCKET':
      return {
        ...state,
        chatWebSocket: action.payload,
      };
    default:
      return state;
  }
};
