export const FacebookIcon: React.FC<{
  color?: string;
  className?: string;
}> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      className={props.className}
    >
      <path
        d="M23 11.5698C23 5.21895 17.8509 0.0698242 11.5 0.0698242C5.14913 0.0698242 0 5.21895 0 11.5698C0 17.3102 4.20517 22.0674 9.70312 22.9299V14.8943H6.78308V11.5689H9.70312V9.03695C9.70312 6.15524 11.4205 4.56249 14.0472 4.56249C15.3046 4.56249 16.6213 4.7877 16.6213 4.7877V7.61766H15.1704C13.7415 7.61766 13.2959 8.50412 13.2959 9.41357V11.5698H16.4852L15.9754 14.8952H13.2959V22.9309C18.7948 22.0674 23 17.3093 23 11.5698Z"
        fill={props.color}
      />
    </svg>
  );
};
