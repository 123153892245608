import ReactGA from 'react-ga4';
import { url } from '../index';

export const recordAndRedirect = (url?: string, tag?: string) => {
  ReactGA.event({
    category: 'Outbound Link',
    action: 'click',
    label: `${tag}: ${url}`,
  });
  if (url && window != null) {
    window.open(url, '_blank')?.focus();
  }
};

/**
 * Analytics:
 * Example event:
  event = {
    label: 'kipsuButton',
    action: 'click',
    time: 'Thu, 21 Dec 2023 07:52:31 GMT'
  }
*/
export type AnalyticsEvent = {
  label: string;
  category: string;
  action: string;
  time: string;
  id?: string;
  rootSectionName?: string;
  rootSectionId?: string;
  gridPosition?: number;
};

export async function customAnalyticsEvent(event: AnalyticsEvent) {
  const serverUrl = url + '/analytics';
  console.log(serverUrl, { eventLabel: event.label, event: event })
  const request = await fetch(serverUrl, {
    method: 'POST',
    body: JSON.stringify({ eventLabel: event.label, event: event }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
}

export async function fetchChat(msg: string) {
  const serverUrl = url + '/get-chatbot';
  console.log(serverUrl, {msg: msg })
  const request = await fetch(serverUrl, {
    method: 'POST',
    body: JSON.stringify({ msg: msg }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
  const response = await request.json();
  return response
}