export interface LanguageState {
  language: string;
}

export const SET_LANGUAGE = 'SET_LANGUAGE';

interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  payload: string;
}

export type LanguageActionTypes = SetLanguageAction;
