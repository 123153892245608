import React from 'react';

import { Item, ItemGroup, Tag } from '../models/menu.model';
import { filterByValue } from '../utils/filter.utils';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import ItemSummary from '../components/ItemSummary.component';

import './ItemGroupSummary.component.css';

function ItemGroupSummary({
  itemGroup,
}: {
  itemGroup: ItemGroup;
}): React.ReactElement {
  const items = itemGroup.items;
  const prices = itemGroup.items.map((item) => item.price);
  const totalPrice = prices.reduce(
    (total: number, price) => total + (price || 0),
    0,
  );
  const hasNullPrice = prices.some((price) => price == null);

  const priceRender =
    itemGroup.price != null
      ? `$${(itemGroup.price / 100).toFixed(2)}`
      : `${(totalPrice / 100).toFixed(2)}${hasNullPrice ? '+' : ''}`;

  return (
    <div className="item-group-summary">
      <div className="title">
        <div className="names">
          {items.map((item, i) => (
            <>
              {i > 0 ? <div className="conjunctive">pairs with</div> : <></>}
              <div className="name">{item.name}</div>
            </>
          ))}
        </div>
        {totalPrice > 0 && <div className="price">{priceRender}</div>}
      </div>
      {itemGroup.description && (
        <div className="description">{itemGroup.description}</div>
      )}
    </div>
  );
}

export default ItemGroupSummary;
