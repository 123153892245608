import { css } from '@emotion/css';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import Description from './DescriptionComponent';
import FullBleed from './FullBleed';
import KipsuButton from './KipsuButton';
import { Title } from './Title';
import * as M from '../models/menu.model';
import { useSelector } from 'react-redux';
import { State } from '../state/store';
import { getLocalizedValue } from '../utils/translate.utils';

const inputStyles = css`
  border: 1px solid lightgrey;
  border-radius: 3px;
  height: 50px;
  font-size: 16px;
  color: lightgrey;
  background-color: #161616;
  margin: 15px auto;
  width: 350px;
  padding: 0 15px;
`;

function Item({
  item,
  restaurant,
}: {
  item: G.ItemV2;
  restaurant: M.Restaurant;
}): JSX.Element {
  const headImage = _.head(item.images);
  const tailImages = _.tail(item.images);
  const [isFoodItem, setFoodItem] = useState(false);
  const [isKids, setKidsClub] = useState(false);
  const [isDining, setDining] = useState(false);
  const [party, setParty] = useState(0);
  const [celebration, setCelebration] = useState('');
  const [allergies, setAllergies] = useState('');
  const [fb, setFB] = useState('');
  const [tags, setTags] = useState(['']);

  const [btnEnabled, setBtnEnabled] = useState(true);

  const selectedLanguage = useSelector(
    (state: State) => state.language.language,
  );

  useEffect(() => {
    const sectionId = window.location.pathname.split('/')[1];
    const rootSection = restaurant.sectionById[sectionId];
    setBtnEnabled(!rootSection.itemCTADisabled);

    // Sets whether item is orderable or reservable
    setFoodItem(rootSection?.ctaMessageIsOrder? true : false);

    const kidsClub = window.location.href.includes(
      'e3236f80-9dc2-11ed-934b-bd4b47b5cc9c',
    );

    if (kidsClub) {
      setKidsClub(true);
    }

    const dining = window.location.href.includes(
      '8fbd11f0-a03f-11ed-a994-69591e1e74c0',
    );

    if (dining) {
      setDining(true);
    }

    if (item.tagIds) {
      const tagValues: string[] = [];
      item.tagIds.forEach((tagId) => {
        const tagObj = restaurant?.tags?.find((tag) => tag.id == tagId);
        if (tagObj) {
          tagValues.push(tagObj.value);
        }
      });
      setTags(tagValues);
    }
  }, []);

  const itemTitle = getLocalizedValue(
    item,
    'namesLocalized',
    selectedLanguage,
    item.name,
  );
  const itemDescription = getLocalizedValue(
    item,
    'descriptionsLocalized',
    selectedLanguage,
    item.description ?? '',
  );

  return (
    <div
      className={css`
        ${restaurant?.settings?.itemBackgroundColor
          ? 'background-color: ' +
            restaurant?.settings.itemBackgroundColor +
            ';'
          : 'background-color: #000;'}
        ${restaurant?.settings?.itemTextColor
          ? 'color: ' + restaurant?.settings.itemTextColor
          : 'white'}
        font: 16px var(--font-sans);
      `}
    >
      {headImage ? (
        <FullBleed
          variant="corner-text"
          textColor={'#fff'}
          bgSrc={headImage}
          title={itemTitle}
          left={true}
          restaurant={restaurant}
        />
      ) : (
        <div
          className={css`
            padding: 30px 20px 10px 20px;
          `}
        >
          <Title
            title={itemTitle}
            left={true}
            variant={restaurant?.settings?.sectionHeaderTextColor || undefined}
            textColor={
              restaurant.settings?.itemTextColor
                ? restaurant.settings?.itemTextColor
                : '#fff'
            }
          />
        </div>
      )}
      <Description
        textColor={
          restaurant.settings?.itemTextColor
            ? restaurant.settings?.itemTextColor
            : '#fff'
        }
        backgroundColor={
          restaurant.settings?.itemBackgroundColor
            ? restaurant.settings?.itemBackgroundColor
            : '#000'
        }
        text={itemDescription || ''}
        sans={true}
      />
      {tailImages.map((imgUrl) => {
        return (
          <img
            key={imgUrl}
            alt={imgUrl}
            src={imgUrl}
            className={css`
              filter: brightness(0.7);
              width: 100%;
              height: auto;
            `}
          />
        );
      })}
      <div
        className={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 10px 30px;
        `}
      >
        <div>
          {tags
            ? tags?.map((tag, i) => (
                <span
                  key={i}
                  className={css`
                    font: 12px var(--font-sans);
                    text-transform: uppercase;
                    color: #ffffff;
                    padding: 2px 6px;
                    margin-right: 12px;
                    background-color: #40a07b;
                  `}
                >
                  {tag}
                </span>
              ))
            : null}
        </div>
        {item.price ? (
          <div
            className={css`
              color: ${restaurant.settings?.itemTextColor || '#fff'};
              font: var(--font-sans);
              text-align: right;
            `}
          >
            ${item.price / 100}
          </div>
        ) : null}
      </div>
      {/* <div
        className={css`
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        `}
      >
        <input
          type="number"
          placeholder={'Party size'}
          onKeyUp={(e) =>
            setParty(parseInt((e.target as HTMLInputElement).value))
          }
          className={inputStyles}
        />
        <input
          type="text"
          placeholder={'Allergies or dietary restrictions'}
          onKeyUp={(e) => setAllergies((e.target as HTMLInputElement).value)}
          className={inputStyles}
        />
        <input
          type="text"
          placeholder={'Any preferences for F&B'}
          onKeyUp={(e) => setFB((e.target as HTMLInputElement).value)}
          className={inputStyles}
        />
        <input
          type="text"
          placeholder={'Are you celebrating anything?'}
          onKeyUp={(e) => setCelebration((e.target as HTMLInputElement).value)}
          className={inputStyles}
        />
      </div> */}
      <div
        className={css`
          background: ${restaurant?.settings?.itemBackgroundColor
            ? restaurant.settings.itemBackgroundColor
            : '#000'};
          display: flex;
          justify-content: center;
          padding-bottom: 32px;
        `}
      >
        {restaurant.settings?.kipsuNumber &&
        (isKids ||
          !window.location.href.includes(
            'e3236f80-9dc2-11ed-934b-bd4b47b5cc9c',
          )) &&
        btnEnabled ? (
          <KipsuButton
            requestedItem={item.name}
            isFood={isFoodItem}
            mainColor={restaurant.settings?.mainColor || ''}
            settings={restaurant.settings!}
            sectionById={restaurant.sectionById}
            id={item.id}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Item;
