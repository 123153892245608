import { css } from '@emotion/css';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import UAParser from 'ua-parser-js';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import { customAnalyticsEvent } from '../utils/analytics.utils';
import * as M from '../models/menu.model';

interface KipsuButtonProps {
  requestedItem: string;
  isFood?: boolean;
  kidsClub?: boolean;
  isInRoomDining?: boolean;
  fullWidth?: boolean;
  isSearch?: boolean;
  partySize?: number;
  allergies?: string;
  fb?: string;
  celebration?: string;
  formEnabled?: boolean;
  mainColor?: string;
  settings: G.RestaurantSettings;
  roomNumber?: number;
  sectionById: M.Restaurant["sectionById"];
  isFooter?: boolean;
  formBtnUrl?: string;
  isChatbotRequest?: boolean;
  chatbotUserPrompt?: string;
  id?: string;
}

const isVowel = (str: string) => {
  const letter = str.substring(0, 1);
  return (
    letter === 'A' ||
    letter === 'E' ||
    letter === 'I' ||
    letter === 'O' ||
    letter === 'U'
  );
};

const formatPhoneNumber = (str: string) => {
  let newStr = str.replace(/[() -]/g, '');
  if (!newStr.includes('+')) {
    newStr = '+' + newStr;
  }
  return newStr;
};

export const handleClick = (
  props: KipsuButtonProps,
  rootSectionName: string,
) => {
  const getSmsQuery = () => {
    const parser = new UAParser();
    let number = props.settings?.kipsuNumber || '';
    if (window.location.href.includes('waldorfpedregal')) {
      number = '+16123510077';
    }
    number = formatPhoneNumber(number);

    if (parser.getOS().name === 'Android') {
      return 'sms:' + number + '?body=';
    }
    return 'sms:' + number + '&body=';
  };

  const getRootSectionName = () => {
    // Prepend RootSection name
    if (
        window.location.origin.includes('hiltonloscabos') || 
        window.location.origin.includes('conradpdm')
      ) {
      return encodeURIComponent("[" + rootSectionName.toLocaleUpperCase() + "] ");
    }
    return "";
  }

  const getSmsBody = () => {
    if (props.isInRoomDining) {
      return encodeURIComponent(
        `Room ${props.roomNumber} is finished with the room service and the tray is ready to be collected.`,
      );
    }
    if (props.kidsClub) {
      return encodeURIComponent(
        'Hi, I’d like to make a reservation at the Kids Club',
      );
    }
    if (props.isFood) {
      return encodeURIComponent(
        `Hi, I'd like to order ${isVowel(props.requestedItem) ? 'an' : 'a'} ${
          props.requestedItem
        }`,
      );
    }
    if (props.isSearch) {
      return encodeURIComponent('Hi, I ... ');
    }
    if (props.isChatbotRequest) {
      return encodeURIComponent(
        `NOMĀDO Chatbot Request: ${props.chatbotUserPrompt}`,
      );
    }
    return encodeURIComponent(
      `Hi, I'm interested in the ${props.requestedItem}`,
    );
  };

  const possibleRoomDining = () => {
    return encodeURIComponent('. ');
  };

  const additionalInfo = () => {
    let info = '';
    if (props.partySize && props.partySize > 0) {
      info += ' Party size: ' + props.partySize + '.';
    }
    if (props.allergies) {
      info += ' Allergies or dietary restrictions: ' + props.allergies + '.';
    }
    if (props.celebration) {
      info += ' Are you celebrating anything? ' + props.celebration + '.';
    }
    if (props.fb) {
      info += ' Food & beverage requests: ' + props.fb + '.';
    }
    return encodeURIComponent(info);
  };

  let smsStr = '';
  if (props.isChatbotRequest) {
    smsStr = getSmsQuery() + getSmsBody();
  } else if (props.requestedItem === 'N/A') {
    smsStr = getSmsQuery();
  } else {
    smsStr = getSmsQuery() + getRootSectionName() + getSmsBody() + possibleRoomDining();
  }
  if (props.formEnabled) {
    smsStr += additionalInfo();
  }

  console.log('SMS', smsStr);

  // ANALYTICS
  let ctaCategory = 'Item';
  if (props.isInRoomDining) {
    ctaCategory = 'Tray Collection';
  } else if (window.innerWidth > 500 && props.settings.ctaEmail) {
    ctaCategory = 'Desktop';
  } else if (props.formEnabled) {
    ctaCategory = 'Make Reservation';
  }

  const eventData = {
    category: ctaCategory + ' CTA' + (props.isFooter ? ' Footer' : ''),
    action: 'CTA Clicked',
    label: props.requestedItem,
  };
  ReactGA.event(eventData);

  // Custom Analytics
  const extendedEventData = {
    category:
      eventData.category +
      (window.origin.includes('localhost') ? ' Localhost' : ''),
    label: eventData.label,
    action: eventData.action,
    time: new Date().toUTCString(),
    rootSectionName: rootSectionName,
    id: props.id,
  };
  customAnalyticsEvent(extendedEventData); // TODO: analytics

  setTimeout(() => {
    if (props.settings.ctaEmail && window.innerWidth > 500) {
      window.location.href = 'mailto:' + props.settings.ctaEmail;
    } else if (props.formBtnUrl) {
      window.location.href = props.formBtnUrl;
    } else {
      window.location.href = smsStr;
    }
  }, 300);
};

const KipsuButton: React.FC<KipsuButtonProps> = (props) => {
  // temp code for fecthing root section name
  let rootSectionName = '';
  let rootSectionId = '';
  if (props.requestedItem !== 'N/A') {
    rootSectionId = window.location.pathname.split('/')[1];
    rootSectionName = props.sectionById[rootSectionId].name;
  }

  const [excluded, setExcluded] = useState(false);

  let buttonText;
  console.log(props);
  if (props.isInRoomDining) {
    buttonText = 'Collect My Tray';
  } else if (props.fullWidth && !excluded) {
    buttonText = 'Make Reservation';
  } else if (props.isFooter) {
    buttonText = 'Contact Concierge';
  } else if (props.formBtnUrl) {
    buttonText = 'Submit'
  } else {
    buttonText = 'Request Availability';
  }

  if (excluded) {
    return null;
  }

  return (
    <a
      onClick={() => handleClick(props, rootSectionName)}
      className={css`
        background: ${props.settings.mainColor
          ? props.settings.mainColor
          : '#032165'};
        padding: ${props.isFooter ? '0px 30px' : '20px 30px'};
        cursor: pointer;
        margin-top:  ${props.isFooter ? '0px' : '32px'};
        text-transform: uppercase;
        text-align: center;
        justify-content: center;
        text-decoration: none;
        color: #ffffff !important;
        font: 15px var(--font-sans);
        display: inline-flex;
        align-items: center;
        ${props.fullWidth
          ? `width: 100%;
           margin-top: 0;
           display: flex;
          justify-content: center;
          padding-right: 0;
          padding-left: 0;`
          : null}
        ${props.requestedItem === 'N/A' ? 
          `
          width: 200px;
          height: 60px;
          background: ${props.settings.CTAFooterColor};
          color: ${props.settings.CTAFooterTextColor};
        `:null}
      `}
    >
      {buttonText}
    </a>
  );
};

export default KipsuButton;
