import { css } from '@emotion/css';
import { useEffect, useMemo, useState } from 'react';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import FullBleed from './FullBleed';
import ListItem from './ListItem';
import { SectionExpanded } from './SectionExpanded';
import { SectionInline } from './SectionInline';
import * as M from '../models/menu.model';
import KipsuForm from './KipsuForm';
import Markdown from './Markdown';
import KipsuButton from './KipsuButton';
import { useSelector } from 'react-redux';
import { State } from '../state/store';
import { getLocalizedValue } from '../utils/translate.utils';

/**
 * Example: https://www.figma.com/file/HFdZpzKn7MSGPhx2pBtVRF/Nom-Waldorf?node-id=929%3A24225&t=GLGArAUzSWebGayf-4
 */
export function SectionNav({
  restaurant,
  section,
  sectionPathAry,
  children,
}: {
  restaurant: M.Restaurant;
  section: G.SectionV2;
  sectionPathAry: G.SectionV2[];
  children?: React.ReactNode;
}): JSX.Element {
  const [modalVis, setModalVis] = useState(false);

  const path = useMemo(
    () => sectionPathAry.map((s) => s.id).join('/'),
    [sectionPathAry],
  );

  const childSections = useMemo(
    () =>
      section.childSectionIds
        ?.map((sectionId) => restaurant.sectionById[sectionId])
        .filter((s) => !s.disabled) || [],
    [section],
  );

  const items = useMemo(
    () => section.itemIds?.map((itemId) => restaurant.itemById[itemId]) || [],
    [section],
  );

  const selectedLanguage = useSelector(
    (state: State) => state.language.language,
  );

  const [selectedSection, setSelection] = useState(
    childSections[0] ? childSections[0].id : '',
  );

  const handleClick = (id: string) => {
    setSelection(id);
  };

  const inRoomDining = useMemo(
    () =>
      sectionPathAry[sectionPathAry.length - 1]?.name === 'In Room Dining Menu',
    [sectionPathAry],
  );

  const [truncated, setTruncated] = useState(true);
  const [child, setChild] = useState('');

  useEffect(() => {
    if (
      window.location.href.includes('76bba292-f110-0b91-2b22-0ae35f222222') &&
      !window.location.href.includes('7d6b3190-ab6f-11ed-9496-c3a3e6544634')
    ) {
      if (
        window.location.href.split('76bba292-f110-0b91-2b22-0ae35f222222/')[1]
      )
        restaurant.sections?.forEach((s) => {
          if (s.id === '76bba292-f110-0b91-2b22-0ae35f222222') {
            s.childSectionIds?.forEach((subSection) => {
              if (
                subSection ===
                window.location.href
                  .split('76bba292-f110-0b91-2b22-0ae35f222222/')[1]
                  .split('/')[0]
              ) {
                setTruncated(false);
              }
            });
          }
          if (
            s.id ===
            window.location.href
              .split('76bba292-f110-0b91-2b22-0ae35f222222/')[1]
              .split('/')[0]
          ) {
            setChild(s.name);
          }
        });
    }
  }, []);

  function renderChilden() {
    if (children) return children;
    return (
      <>
        {items.map((item) => {
          const tagValues: string[] = [];
          item.tagIds.forEach((tagId) => {
            const tagObj = restaurant?.tags?.find((tag) => tag.id == tagId);
            if (tagObj) {
              tagValues.push(tagObj.value);
            }
          });

          const itemTitle =
            selectedLanguage !== 'en' && 'namesLocalized' in item
              ? (item as any).namesLocalized[selectedLanguage]
              : item.name;

          return (
            <>
              {item.available ? (
                <ListItem
                  key={item.id}
                  restaurant={restaurant}
                  id={item.id}
                  colorScheme="light"
                  to={'/' + path + '/' + item.id}
                  title={item.name}
                  titleLocalized={itemTitle}
                  description={item.description || ''}
                  price={item.price?.toString() || ''}
                  section={section.name}
                  expanded={section.type === 'expanded'}
                  tags={tagValues}
                />
              ) : null}
            </>
          );
        })}

        {childSections.map((childSection) => {
          if (childSection.id === selectedSection) {
            switch (childSection.type) {
              case 'nav':
                return (
                  <SectionNav
                    key={childSection.id}
                    restaurant={restaurant}
                    section={childSection}
                    sectionPathAry={sectionPathAry}
                  >
                    {children}
                  </SectionNav>
                );
              case 'inline':
                return (
                  <SectionInline
                    key={childSection.id}
                    restaurant={restaurant}
                    section={childSection}
                    sectionPathAry={sectionPathAry}
                    navParent={childSection.id}
                  />
                );
              case 'expanded':
              default:
                return (
                  <SectionExpanded
                    key={childSection.id}
                    restaurant={restaurant}
                    section={childSection}
                    sectionPathAry={sectionPathAry}
                    hideName={true}
                  >
                    {children}
                  </SectionExpanded>
                );
            }
          }
        })}
      </>
    );
  }

  function renderNamelessSections() {
    return (
      <>
        {childSections.map((childSection, i) => {
          // Render all sections after the first that don't have a name: edge case for food notices/etc
          if (i > 0 && !childSection.name) {
            switch (childSection.type) {
              case 'nav':
                return (
                  <SectionNav
                    key={childSection.id}
                    restaurant={restaurant}
                    section={childSection}
                    sectionPathAry={sectionPathAry}
                  >
                    {children}
                  </SectionNav>
                );
              case 'inline':
                return (
                  <SectionInline
                    key={childSection.id}
                    restaurant={restaurant}
                    section={childSection}
                    sectionPathAry={sectionPathAry}
                    navParent={childSection.id}
                  />
                );
              case 'expanded':
              default:
                return (
                  <SectionExpanded
                    key={childSection.id}
                    restaurant={restaurant}
                    section={childSection}
                    sectionPathAry={sectionPathAry}
                  >
                    {children}
                  </SectionExpanded>
                );
            }
          }
        })}
      </>
    );
  }

  const fullBleedTitle = getLocalizedValue(
    section,
    'namesLocalized',
    selectedLanguage,
    section.name,
  );
  const sectionDescription = getLocalizedValue(
    section,
    'descriptionsLocalized',
    selectedLanguage,
    section.description ?? '',
  );

  return (
    <>
      {modalVis ? (
        <div
          className={css`
            width: 100vw;
            height: 100vh;
            position: fixed;
            left: 0;
            z-index: 10;
            bottom: 0;
            background: #00000094;
          `}
          onClick={() => setModalVis(!modalVis)}
        ></div>
      ) : null}
      {(section.images && section.images.length > 0) || section.name ? (
        <FullBleed
          title={section.name === 'Spanish' ? 'Español' : fullBleedTitle}
          variant="framed"
          bgSrc={
            section.images && section.images.length > 0
              ? section.images[0]
              : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADAQMAAACOOjyFAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAHVaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJYTVAgQ29yZSA1LjQuMCI+CiAgIDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+CiAgICAgIDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiCiAgICAgICAgICAgIHhtbG5zOnRpZmY9Imh0dHA6Ly9ucy5hZG9iZS5jb20vdGlmZi8xLjAvIj4KICAgICAgICAgPHRpZmY6Q29tcHJlc3Npb24+MTwvdGlmZjpDb21wcmVzc2lvbj4KICAgICAgICAgPHRpZmY6T3JpZW50YXRpb24+MTwvdGlmZjpPcmllbnRhdGlvbj4KICAgICAgICAgPHRpZmY6UGhvdG9tZXRyaWNJbnRlcnByZXRhdGlvbj4yPC90aWZmOlBob3RvbWV0cmljSW50ZXJwcmV0YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgoC2IAFAAAAA1BMVEVHcEyC+tLSAAAAAXRSTlMAQObYZgAAAApJREFUCB1jAAMAAAYAAcXjo3IAAAAASUVORK5CYII='
          }
          restaurant={restaurant}
        />
      ) : null}
      {section.description ? (
        <Markdown
          className={css`
            padding: 20px;
            ${'background-color: ' +
            (restaurant?.settings?.mainColor
              ? restaurant?.settings?.mainColor
              : '#032165') +
            ';'}
            color: white;
            font: 17px var(--font-sans);

            display: flex;
            flex-direction: column;
            align-items: center;

            text-align: left;
            > p {
              text-align: center;
            }
          `}
        >
          {sectionDescription}
        </Markdown>
      ) : null}

      {/* KIPSU FORM */}
      {modalVis &&
      restaurant.settings?.kipsuNumber &&
      section.hasSectionCTAButton ? (
        <KipsuForm
          requestedItem={child}
          settings={restaurant.settings!}
          mainColor={restaurant.settings?.mainColor || ''}
          isInRoomDining={inRoomDining}
          sectionById={restaurant.sectionById}
        />
      ) : null}

      {/* KIPSU BUTTON */}
      {restaurant.settings?.kipsuNumber && section.hasSectionCTAButton ? (
        <button
          className={css`
            ${'background-color: ' +
            (restaurant?.settings?.mainColor
              ? restaurant.settings.mainColor
              : '#032165') +
            ';'}
            padding: 20px 30px;
            cursor: pointer;
            margin-top: 32px;
            text-transform: uppercase;
            text-align: center;
            text-decoration: none;
            color: #ffffff !important;
            font: 15px var(--font-sans);
            display: inline-flex;
            width: 100%;
            margin-top: 0;
            display: flex;
            justify-content: center;
            padding-right: 0;
            padding-left: 0;
          `}
          onClick={() => setModalVis(!modalVis)}
        >
          {inRoomDining ? 'COLLECT MY TRAY' : 'MAKE RESERVATION'}
        </button>
      ) : null}

      {/* Nav pills */}
      <div
        className={css`
          ${'background-color: ' +
          (restaurant?.settings?.mainColor
            ? restaurant.settings.mainColor
            : '#032165') +
          ';'}
        `}
      >
        <div
          className={css`
            text-align: center;
            height: 60px;
            mask-image: linear-gradient(
              to right,
              transparent,
              #000000aa 10%,
              black 12%,
              black 88%,
              #000000aa 90%,
              transparent
            );
            white-space: nowrap;
            overflow-x: auto;
            padding: 0 12%;
            ${section.childSectionIds && section.childSectionIds?.length > 2
              ? `justify-content: flex-start;`
              : `justify-content: center;`}
          `}
        >
          {childSections.map((childSection, i) => {
            const sectionTitle =
              selectedLanguage !== 'en' && 'namesLocalized' in childSection
                ? (childSection as any).namesLocalized[selectedLanguage]
                : childSection.name;

            return (
              <>
                {childSection.name || i === 0 ? (
                  <div
                    key={childSection.id}
                    // href={path + '/' + childSection.id}
                    className={css`
                      display: inline-block;
                      margin: 10px 2px;
                      text-decoration: none;
                      color: white !important;
                      cursor: pointer;
                    `}
                    onClick={() => handleClick(childSection.id)}
                  >
                    <div
                      className={css`
                        font: 14px var(--font-sans);
                        text-transform: uppercase;
                        height: 40px;
                        min-width: 95px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        word-break: normal;
                        text-align: center;
                        padding: 0 15px;
                        ${selectedSection === childSection.id
                          ? `background-color:rgba(255, 255, 255, 0.26);`
                          : null}
                      `}
                    >
                      {/**
                       * TODO: REFACTOR AT SOME POINT:
                       * This code is added for Waldorf Cabo restaurant menus.
                       * The Breakfast section has a Breakfast subsection,
                       * but we didn't want the name to repeat and didn't want a second image to show up,
                       * since the Waldorf team didn't like the aesthetic. We need to roll this back eventually.
                       */}
                      {childSection.name === 'Spanish'
                        ? 'Español'
                        : !childSection.name && i === 0
                        ? section.name
                        : sectionTitle || childSection.name}
                    </div>
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      </div>
      {/* Child sections and items */}
      {renderChilden()}
      {/** Edge case sections */}
      {renderNamelessSections()}
    </>
  );
}
