import { css } from '@emotion/css';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import { AnalyticsEvent, customAnalyticsEvent } from '../utils/analytics.utils';

interface GridCellProps {
  title: string;
  titleLocalized?: string;
  src?: string;
  size: string;
  to: string;
  settings: G.RestaurantSettings;
  position: number;
  rootSectionId: string;
}

const GridCell = ({ title, titleLocalized, src, size, to, settings, position, rootSectionId }: GridCellProps) => {
  const navigate = useNavigate();

  const eventData = {
    category: 'Tile Clicks',
    action: 'Tile Clicked',
    label: title,
  };

  const generateBase64Pixel = () => {
    let color = '#032165';
    if (settings?.mainColor) {
      color = settings.mainColor;
    }
    const canvas = document.createElement('canvas');
    canvas.width = 1;
    canvas.height = 1;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, 1, 1);
    }
    return canvas.toDataURL();
  };

  // const navigate = () => {
  //   window.location.href = to;
  // };

  const registerClick = () => {
    ReactGA.event(eventData);
    // Custom Analytics
    const extendedEventData = {
      category:
        'Tile Clicks' +
        (window.origin.includes('localhost') ? ' Localhost' : ''),
      label: eventData.label,
      action: eventData.action,
      time: new Date().toUTCString(),
      gridPosition: position+1,
      rootSectionName: title,
      rootSectionId: rootSectionId
    };
    customAnalyticsEvent(extendedEventData);

    setTimeout(() => {
      navigate(to);
    }, 300);
  };

  const maxWordLen = (sentence: string) => {
    let max = 0;
    sentence.split(' ').forEach((word) => {
      if (word.length > max) {
        max = word.length;
      }
    });
    return max;
  };

  return (
    <div
      onClick={registerClick}
      className={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: span 4;
        grid-row: span 4;
        aspect-ratio: 1 / 1;
        box-sizing: border-box;
        ${`width: calc(${
          size === 'sm' ? '33.3333' : size === 'md' ? 50 : 66.6667
        } * min(1vw, 600px / 100));`}
        ${`height: calc(${
          size === 'sm' ? '33.3333' : size === 'md' ? 50 : 66.6667
        } * min(1vw, 600px / 100));`}
        ${`min-height: calc(${
          size === 'sm' ? '33.3333' : size === 'md' ? 50 : 66.6667
        } * min(1vw, 600px / 100));`}
        ${`max-height: calc(${
          size === 'sm' ? '33.3333' : size === 'md' ? 50 : 66.6667
        } * min(1vw, 600px / 100));`}
        padding: 15px;
        cursor: pointer;
        ${size === 'md'
          ? `grid-column: span 6;
        grid-row: span 6;
        `
          : size === 'lg'
          ? `grid-column: span 8;
        grid-row: span 8;
        `
          : ``}
      `}
    >
      <img
        src={src ? src : generateBase64Pixel()}
        className={css`
          ${src ? 'filter: brightness(0.7);' : null}
          position: absolute;
          ${`width: calc(${
            size === 'sm' ? '33.3333' : size === 'md' ? 50 : 66.6667
          } * min(1vw, 600px / 100) - 34px);`}
          ${`height: calc(${
            size === 'sm' ? '33.3333' : size === 'md' ? 50 : 66.6667
          } * min(1vw, 600px / 100) - 34px);`}
            ${`min-height: calc(${
            size === 'sm' ? '33.3333' : size === 'md' ? 50 : 66.6667
          } * min(1vw, 600px / 100) - 34px);`}
            ${`max-height: calc(${
            size === 'sm' ? '33.3333' : size === 'md' ? 50 : 66.6667
          } * min(1vw, 600px / 100) - 34px);`}
            z-index: 0;
          object-fit: cover;
        `}
      />
      <div
        className={css`
          border: 2px solid ${settings.gridcellBorderColor || '#fff'};
          color: #fff;
          text-transform: uppercase;
          font-size: 12px;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          font: 12px var(--font-sans);
          text-align: center;
          z-index: 10;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: pre-wrap;
          word-break: initial;
          ${maxWordLen(title) > 9 && size === 'sm' ? 'font-size: 9px;' : null}
        `}
      >
        {titleLocalized || title}
      </div>
    </div>
  );
};

export default GridCell;
