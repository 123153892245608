import * as G from '../../nom-server/src/generated/frontendGraphql';

/**
 * Get the localized (translated) value of an item.
 * If the key exists and the value is not empty, return the translated value. Otherwise, return the fallback value.
 *
 * @param item The item/promotion/section to get the translated value from.
 * @param key The key to get the translated value from.
 * @param selectedLanguage The selected language (see language codes at https://cloud.google.com/translate/docs/languages).
 * @param fallback The fallback value.
 *
 * @example
 * // This example checks if the namesLocalized field exists in the item and is not an empty string, returning the translated value if it exists.
 * const itemName = getLocalizedValue(item, 'namesLocalized', selectedLanguage, item.name ?? '');
 */
export function getLocalizedValue(
  item: G.ItemV2 | G.Promotion | G.SectionV2,
  key: string,
  selectedLanguage: string,
  fallback: string,
) {
  return selectedLanguage !== 'en' &&
    key in item &&
    (item as any)[key][selectedLanguage]
    ? (item as any)[key][selectedLanguage]
    : fallback;
}
