/**
 * @file This file defines the landing view, a teaser view for the restaurant as
 * well as any other promotional material.
 */

import React, { useRef, useMemo, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import cx from 'classnames';
import { css } from '@emotion/css';
import { FaYelp } from 'react-icons/fa';
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import { flatten } from 'ramda';

import { Menu, Slide, Item, Section, Subsection } from '../models/menu.model';
import ViewScroller from '../components/ViewScroller.component';
import Image from '../components/Image.component';

import { LandingNotchLeft, LandingNotchRight } from '../icons';

import './LandingView.container.css';
import smoothscroll from 'smoothscroll-polyfill';
import MediaQuery from 'react-responsive';
import TableOfContents from '../components/TableOfContents.compoent';

const iconClass = css`
  cursor: pointer;
  color: white;
`;
interface LandingViewParams {
  className?: string;
  menu: Menu;
}

function LandingView(params: LandingViewParams): React.ReactElement {
  const { className, menu } = params;
  const { slides, theme } = menu;

  const [slideNum, setSlideNum] = useState(0);

  const landingViewEl = useRef<HTMLDivElement>(null);

  function handleScrollerClick() {
    if (landingViewEl && landingViewEl.current) {
      smoothscroll.polyfill();
      window.scroll({
        // Scrolls 1 page down
        top: landingViewEl.current.clientHeight,
        behavior: 'smooth',
      });
    }
  }

  // console.log('res', theme?.logoCoverPage)
  // console.log('theme', theme)

  function renderContents(slide: Slide) {
    if (slide.contents === true) {
      return (
        <TableOfContents
          className="table-of-contents"
          items={ContentsItems}
          onClick={handleScrollerClick}
        />
      );
    } else {
      console.log(slide);
      return null;
    }
  }

  const { sections } = menu;
  const ContentsItems: string[] = useMemo(
    () => flatten(sections.map((section) => section.name)),
    [sections],
  );

  const carouselItems = slides.map((slide, i) => (
    <Carousel.Item key={i}>
      {slide.image && (
        <div className="landing-image-container">
          <MediaQuery maxWidth={1040}>
            <Image className="landing-image" src={slide.image} />
            <Image className="landing-image-underlay" src={slide.image} />
          </MediaQuery>
          <MediaQuery minWidth={1041}>
            <Image className="landing-image" src={slide.desktopImage} />
            <Image
              className="landing-image-underlay"
              src={slide.desktopImage}
            />
          </MediaQuery>
        </div>
      )}
      <Carousel.Caption>
        {theme?.logoCoverPage && slide.text !== 'About Us' ? (
          <>
            <img src={theme.logoCoverPage} alt="restaurant logo img" />
          </>
        ) : (
          <>
            <h3>{slide.text}</h3>
            {slide.description && <p>{slide.description}</p>}
            <p>{renderContents(slide)}</p>
          </>
        )}
      </Carousel.Caption>
    </Carousel.Item>
  ));

  return (
    <div className={cx('landing-view', className)} ref={landingViewEl}>
      {theme && theme.restaurantWebsite && (
        <div className="return-to-restuarant-website">
          <a href={theme.restaurantWebsite}>&#8592; Return to Main Website</a>
        </div>
      )}
      <div className="nav-bar">
        {menu.info?.instagram && (
          <FiInstagram
            className={iconClass}
            onClick={openUrl(menu.info.instagram)}
            size={24}
          />
        )}
        {menu.info?.facebook && (
          <FiFacebook
            className={iconClass}
            onClick={openUrl(menu.info.facebook)}
            size={24}
          />
        )}
        {menu.info?.yelp && (
          <FaYelp
            className={iconClass}
            onClick={openUrl(menu.info.yelp)}
            size={24}
          />
        )}
      </div>
      <Carousel
        interval={null}
        wrap={false}
        indicators={false}
        prevIcon={
          <LandingNotchLeft>{slides[slideNum - 1]?.text}</LandingNotchLeft>
        }
        nextIcon={
          <LandingNotchRight>{slides[slideNum + 1]?.text}</LandingNotchRight>
        }
        onSlide={(eventKey, direction) => {
          if (direction === 'right') {
            setSlideNum((num) => (num - 1) % slides.length);
          } else {
            setSlideNum((num) => (num + 1) % slides.length);
          }
        }}
      >
        {carouselItems}
      </Carousel>
      <ViewScroller
        className="menu-scroller"
        text="Menu"
        onClick={handleScrollerClick}
      />
    </div>
  );
}

function openUrl(url: string) {
  return () => window.open(url, '_blank');
}

export default LandingView;
