import React from 'react';

import { Tag } from '../models/menu.model';
import { filterByValue } from '../utils/filter.utils';

import './Tags.component.css';

function getIcon(tag: Tag) {
  return filterByValue[tag.value]?.icon;
}

const DEFAULT_ARRAY: any[] = [];

export function Tags({
  tags = DEFAULT_ARRAY,
  labels = DEFAULT_ARRAY,
}: {
  tags?: Tag[];
  labels?: string[];
}): React.ReactElement | null {
  const visibleTags = tags.filter(getIcon);

  const tagEls = visibleTags.map((tag) => (
    <span key={tag.value} className="icon">
      {getIcon(tag)}
    </span>
  ));

  const labelEls = labels.map((label) => (
    <span className="icon" key={label}>
      <span style={{ color: '#45D854' }}>{label}</span>
    </span>
  ));

  if (!labelEls.length && !tagEls.length) {
    return null;
  }

  return (
    <span className="tags">
      {tagEls}
      {labelEls}
    </span>
  );
}
