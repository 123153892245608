import {
  LanguageState,
  SET_LANGUAGE,
  LanguageActionTypes,
} from './types.language';

const initialState: LanguageState = {
  language: 'en',
};

export const languageReducer = (
  state = initialState,
  action: LanguageActionTypes,
): LanguageState => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};
