import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Integrations } from '../models/integrations.model';
import { Item } from '../models/menu.model';
import { State } from '../state/store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useMaybeMenufy<T, U extends unknown[], V>(
  elseDispatchItem?: (item: Item, ...args: U) => T,
  elsePivotOrder?: () => V,
) {
  const menufy = useSelector((state: State) => state.integrations.menufy);

  function dispatchItem(item: Item, ...args: U) {
    if (menufy) {
      const menufyLink = menufy.itemToUrl[item.name] || menufy.baseUrl;
      window.open(menufyLink, '_blank');
    } else if (elseDispatchItem) {
      return elseDispatchItem(item, ...args);
    }
  }

  function pivotOrder() {
    if (menufy) {
      window.open(menufy.baseUrl, '_blank');
    } else if (elsePivotOrder) {
      return elsePivotOrder();
    }
  }

  return [dispatchItem, pivotOrder] as const;
}

export function useIntegrations(integs: Integrations = {}): void {
  const dispatch = useDispatch();
  useEffect(() => {
    if (integs.menufy) {
      dispatch({ type: 'IntegrateMenufy', menufy: integs.menufy });
    }
  }, [integs]);
}
