import React from 'react';
import './ConfirmationMsg.component.css';

interface ConfirmationMsgParams {
  icon: JSX.Element;
  itemName: string;
  message: string;
}

function ConfirmationMsg(params: ConfirmationMsgParams) {
  const { icon, itemName, message } = params;
  return (
    <div className="confirmation-msg">
      <div className="icon">{icon} </div>
      <div className="text">
        {itemName}&nbsp;{message}
      </div>
    </div>
  );
}

export default ConfirmationMsg;
