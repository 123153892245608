import React, { useState } from 'react';
import * as R from 'ramda';
import cx from 'clsx';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';

import { Item, Option } from '../models/menu.model';
import { Checkmark, PlusCircleIcon, MinusCircleIcon } from '../icons';

import './ItemOption.component.css';

interface ItemOptionParams {
  item: Item;
  option: Option;
  opIdx: number;
  valueCounts: number[][];
  incValueCounts: (i: number, j: number) => unknown;
  decValueCounts: (i: number, j: number) => unknown;
}

function ItemOption(params: ItemOptionParams) {
  const { item, option, opIdx, valueCounts, incValueCounts, decValueCounts } =
    params;
  // To save selected option in radio layout
  const [radioOptionId, setRadioOptionId] = useState<number>();
  const [radioValueId, setRadioValueId] = useState<number>();

  function explainOption({ min, max }: Option): string {
    if (!min && max === undefined) {
      return 'As many as desired';
    }

    if (min && max === undefined) {
      return `At least ${min}`;
    }

    if (!min && max) {
      return `At most ${max}`;
    }

    if (min === max) {
      return `Pick ${min}`;
    }

    return `Min ${min}, Max ${max}`;
  }

  function ValueButtons(params: {
    option: Option;
    optionIdx: number;
    valueIdx: number;
  }) {
    const { option, optionIdx, valueIdx } = params;
    const count = valueCounts[optionIdx]?.[valueIdx] || 0;
    const { max } = option;
    const totalOptionCount = R.sum(valueCounts[optionIdx] || []);
    const incCount = () => incValueCounts(optionIdx, valueIdx);
    const decCount = () => decValueCounts(optionIdx, valueIdx);
    if (valueCounts[optionIdx]?.[valueIdx] === 1) {
      setRadioOptionId(optionIdx);
      setRadioValueId(valueIdx);
    }
    const handleRadioSelection = () => {
      if (radioOptionId !== undefined && radioValueId !== undefined) {
        // Decrease count of now unselected option
        decValueCounts(radioOptionId, radioValueId);
      }
      setRadioOptionId(optionIdx);
      setRadioValueId(valueIdx);
      incCount();
    };

    const atMax = totalOptionCount === max;

    if (max === 1) {
      return (
        <div className="value-buttons">
          {item && (
            <Form.Check
              key={item.name + option.name + optionIdx + valueIdx}
              id={item.name + option.name + optionIdx + valueIdx}
              aria-label={option.name + optionIdx + valueIdx}
            >
              <FormCheck.Input
                id={item.name + option.name + optionIdx + valueIdx}
                type="radio"
                onChange={handleRadioSelection}
                checked={
                  /* TODO: account for updating radio button item */
                  optionIdx === radioOptionId && valueIdx === radioValueId
                }
              />
              <div className="circle">&nbsp;</div>
            </Form.Check>
          )}
        </div>
      );
    }

    if (option.notIncrementable) {
      return (
        <div className="value-buttons">
          {!count ? (
            // <PlusCircleIcon onClick={incCount} />
            <div className="option-checkbox" onClick={incCount}>
              &nbsp;
            </div>
          ) : (
            // <PlusCircleIcon className="filled" onClick={decCount} />
            <div className="option-checkbox checked" onClick={decCount}>
              <div className="check">{Checkmark}</div>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="value-buttons">
        {!count ? (
          <PlusCircleIcon onClick={incCount} />
        ) : (
          <>
            <MinusCircleIcon className="filled" onClick={decCount} />
            <span className="value-count">{count}</span>
            <PlusCircleIcon
              className={cx('filled', atMax && 'disabled')}
              onClick={incCount}
            />
          </>
        )}
      </div>
    );
  }
  return (
    <div className="option" key={option.name}>
      <h1>{option.name}</h1>
      <h2>{explainOption(option)}</h2>
      {option.values.map((value, j) => (
        <div className="value-container" key={value.name}>
          <ValueButtons option={option} optionIdx={opIdx} valueIdx={j} />

          <div className="value-name">{value.name}</div>
          {value.price && (
            <p className="value-price">${(value.price / 100).toFixed(2)}</p>
          )}
        </div>
      ))}
    </div>
  );
}

export default ItemOption;
