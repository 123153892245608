import { cx, css } from '@emotion/css';
import './Selector.css';

import * as M from '../models/menu.model';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import { useGlobalStyles } from './utils/useGlobalStyles';

import { Key, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NavBar from './NavBar';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../state/store';
import { addMessage, setWelcomeMessage } from '../state/chat/actions.chat';
import { handleClick } from './KipsuButton';
import { Msg } from '../state/chat/types.chat';
import { customAnalyticsEvent } from '../utils/analytics.utils';

const promptInputStyle = css`
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 5px;
  margin-right: 1rem;
`;

const sendButtonStyle = css`
  padding: 10px 20px;
  font-size: 16px;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const redirectButtonStyle = css`
  padding: 10px 10px;
  font-size: 16px;
  background: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
  font-weight: bold;
  margin-right: 1rem;
`;

const ChatWrapper = ({ restaurant }: { restaurant: M.Restaurant }) => {
  const defaultColor = restaurant.settings?.mainColor || '#032165'; // using waldorfpedregal blue color as default
  const params = useParams();
  const pathAry = useMemo(() => params['*']?.split('/') || [], [params['*']]);
  const navigate = useNavigate();

  useGlobalStyles(`
  html {
    background: ${restaurant.settings?.mainColor || '#000'};
  }
  body {
    margin: 0 auto;
    max-width: 600px;
  }
  `);

  const dispatch = useDispatch();
  const { messages, welcomeMessage } = useSelector(
    (state: State) => state.chat,
  );

  const [userInput, setUserInput] = useState('');
  const wsRef = useRef<WebSocket | null>(null);
  const [sessionId, setSessionId] = useState(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();

  useEffect(() => {
    (wsRef as any).current = new WebSocket('wss://apps.aiassistant.co:4005/');

    (wsRef as any).current.onopen = () => {
      const initMessage = {
        type: 'init',
        productId: 'hiltonloscabos',
        channel: 'web',
      };
      (wsRef as any).current.send(JSON.stringify(initMessage));
    };

    (wsRef as any).current.onerror = (error: any) => {
      console.error('WebSocket error:', error);
    };

    (wsRef as any).current.onclose = (event: any) => {
      console.log(`Connection closed: ${event.code} ${event.reason}`);
    };

    return () => {
      if (wsRef.current) {
        (wsRef.current as any).close();
      }
    };
  }, [dispatch]);

  const handleChatRedirect = (
    sectionId?: string,
    isChatbotRequest?: boolean,
    userPrompt?: string,
  ) => {
    if (sectionId) {
      const extendedEventData = {
        category: `Chatbot Clicks${
          window.origin.includes('localhost') ? ' Localhost' : ''
        }`,
        label:
          restaurant.sectionById[sectionId]?.name || 'Page Redirect Clicked',
        action: 'Chat Redirect Clicked',
        time: new Date().toUTCString(),
      };
      customAnalyticsEvent(extendedEventData);
    } else {
      const extendedEventData = {
        category: `Chatbot Clicks${
          window.origin.includes('localhost') ? ' Localhost' : ''
        }`,
        label: 'Kipsu Concierge',
        action: 'Chat Redirect Clicked',
        time: new Date().toUTCString(),
      };
      customAnalyticsEvent(extendedEventData);
    }

    if (!isChatbotRequest) {
      return () => navigate(`../${sectionId}`);
    } else {
      return () =>
        handleClick(
          {
            settings: restaurant.settings as G.RestaurantSettings,
            chatbotUserPrompt: userPrompt,
            isChatbotRequest: true,
            requestedItem: 'N/A',
            sectionById: restaurant.sectionById,
          },
          'Chatbot Request',
        );
    }
  };

  useEffect(() => {
    const handleSuggestionsNaive = (
      userPrompt: string,
      chatbotResponse: string,
    ) => {
      const lowercaseText = chatbotResponse.toLowerCase();
      const buttons: JSX.Element[] = [];

      if (lowercaseText.includes('concierge')) {
        buttons.push(
          <button
            className={redirectButtonStyle}
            onClick={handleChatRedirect('', true, userPrompt)}
          >
            Contact Concierge →
          </button>,
        );
      }

      if (
        lowercaseText.includes('spa') ||
        lowercaseText.includes('wellness') ||
        lowercaseText.includes('massage')
      ) {
        buttons.push(
          <button
            className={redirectButtonStyle}
            onClick={handleChatRedirect('bf209910-5649-11ee-989a-6de780094898')}
          >
            Spa & Wellness →
          </button>,
        );
      }
  
      if (
        lowercaseText.includes('restaurant') ||
        lowercaseText.includes('food') ||
        lowercaseText.includes('lounge') ||
        lowercaseText.includes('breakfast') ||
        lowercaseText.includes('lunch') ||
        lowercaseText.includes('dinner') ||
        lowercaseText.includes('dining')
      ) {
        buttons.push(
          <button
            className={redirectButtonStyle}
            onClick={handleChatRedirect('0b88c330-5416-11ee-adba-571740a2cd91')}
          >
            Restaurants + Lounges →
          </button>,
        );
      }
  
      if (lowercaseText.includes('room service')) {
        buttons.push(
          <button
            className={redirectButtonStyle}
            onClick={handleChatRedirect('bc559230-5649-11ee-989a-6de780094898')}
          >
            In-Room Dining →
          </button>,
        );
      }
      return buttons;
    };

    (wsRef as any).current.onmessage = (event: any) => {
      const parsedMessage = JSON.parse(event.data);

      if (parsedMessage?.type === 'start' && parsedMessage?.text) {
        dispatch(setWelcomeMessage(parsedMessage.text));
        setSessionId(parsedMessage.session_id);
      } else if (parsedMessage?.type === 'text') {
        dispatch(
          addMessage({
            type: 'ai',
            text: parsedMessage.text,
            suggestions: [],
          }),
        );
      } else if (parsedMessage?.type === 'end') {
        dispatch(
          addMessage({
            type: 'ai',
            text: '',
            suggestions: handleSuggestionsNaive(
              messages[messages.length - 2].text,
              messages[messages.length - 1].text,
            ),
          }),
        );
      }
    };
  }, [dispatch, messages]);

  const handleUserInput = (event: any) => {
    setUserInput(event.target.value);
  };

  const handleSendMessage = () => {
    if (userInput.trim() === '') {
      console.error('Cannot send an empty message.');
      return;
    }

    if ((wsRef as any).current.readyState === WebSocket.OPEN) {
      const queryMessage = {
        type: 'query',
        productId: 'hiltonloscabos',
        text: userInput,
        summary: false,
      };
      (wsRef as any).current.send(JSON.stringify(queryMessage));
      dispatch(addMessage({ type: 'user', text: userInput, suggestions: [] }));
      setUserInput('');
    } else {
      console.error('WebSocket is not open.');
    }
  };

  // Send query on Enter key press
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  // Extract prompt from URL query string and send prompt to chatbot if available
  // Prompts from the modal are passed to the chatbot via the URL query string
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const prompt = searchParams.get('p');

    if (prompt) {
      console.log(prompt);
      setUserInput(prompt);
      if (sessionId) {
        handleSendMessage();

        const queryParams = new URLSearchParams(location.search);

        if (queryParams.has('p')) {
          queryParams.delete('p');

          navigate(`${location.pathname}?${queryParams.toString()}`, {
            replace: true,
          });
        }
      }
    }
  }, [location.search, sessionId, navigate, location]);

  // Automatically scroll to the bottom of the chat window when new messages are added
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [messages]);

  const copyMessageToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text to clipboard', err);
      });
  };

  const renderMessages = () => {
    const renderedMessages: any[] = [];
    let currentMessage = '';

    messages.forEach((message: Msg, index: number) => {
      const messageContainerStyle = css`
        display: flex;
        justify-content: ${message.type === 'user' ? 'flex-end' : 'flex-start'};
      `;

      const messageStyle = css`
        background: ${message.type === 'user' ? '#ccc' : '#f5f5f5'};
        border-radius: 10px;
        padding: 10px;
        max-width: 75%;
      `;

      const copyButtonStyle = css`
        cursor: pointer;
        background: ${message.type === 'user' ? '#ccc' : '#f5f5f5'};
        border: none;
        border-radius: 4rem;
        width: 2rem;
        font-size: 24px;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        height: 100%;
      `;

      if (message.type === 'user') {
        currentMessage = message.text;
        renderedMessages.push(
          <div key={index} className={messageContainerStyle}>
            <p className={messageStyle}>{currentMessage}</p>
          </div>,
        );
      } else {
        currentMessage = message.text;
        renderedMessages.push(
          <>
            <div key={index} className={messageContainerStyle}>
              <p className={messageStyle}>{currentMessage}</p>
              <button
                className={copyButtonStyle}
                onClick={() => copyMessageToClipboard(currentMessage)}
              >
                &#x2398;
              </button>
            </div>
            <div>{message.suggestions}</div>
          </>,
        );
      }
    });

    return renderedMessages;
  };

  useEffect(() => {
    console.log(messages)
  }, [messages])

  return (
    <>
      <NavBar
        colorScheme="blue"
        backTo={'/' + pathAry.slice(0, -1).join('/')}
        backLabel={'Back'}
        settings={restaurant.settings}
      />
      <div
        className={css`
          padding: 1rem;
          background-color: ${restaurant.settings?.mainColor || defaultColor};
          font: 16px var(--font-sans);
          display: flex;
          flex-direction: column;
          height: 80vh;
        `}
      >
        <h3
          className={css`
            text-align: center;
            color: white;
          `}
        >
          Ask NOMĀDO AI
        </h3>
        {/* Messages container */}
        <div
          className={css`
            overflow-y: auto;
            flex-grow: 1;
          `}
        >
          <div className="welcome-box">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                overflowY: 'auto',
              }}
            >
              <p
                className="welcome-message"
                style={{
                  textAlign: 'left',
                  background: '#f5f5f5',
                  borderRadius: '10px',
                  padding: '10px',
                  maxWidth: '75%',
                }}
              >
                {welcomeMessage}
              </p>
            </div>
          </div>
          <div className="conversation">
            <div className="messages">{renderMessages()}</div>
          </div>
          <div ref={messagesEndRef} />
        </div>
        {/* Message box and send button */}
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
          `}
        >
          <input
            type="text"
            value={userInput}
            onChange={handleUserInput}
            onKeyDown={handleKeyPress}
            placeholder="Type your message..."
            className={promptInputStyle}
          />
          <button className={sendButtonStyle} onClick={handleSendMessage}>
            Send
          </button>
        </div>
      </div>
    </>
  );
};

export default ChatWrapper;
