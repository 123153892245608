import React from 'react';
import { css } from '@emotion/css';

import { MainNavigation } from './MainNavigation.container';
import { Menu, Section, Item, Theme } from '../models/menu.model';

const container = css`
  height: 100%;
  width: 100%;
  min-height: 100vh;
`;

const pageHeader = css`
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 64px;
  background-color: var(--main-bg-color);
  justify-content: space-between;
  flex-direction: column;
`;

const pageContent = css`
  color: white;
  padding-top: 64px;
  padding-left: 15%;
  padding-right: 15%;
  white-space: pre;
  line-height: 32px;
  font-size: 16px;
`;

export const AboutView: React.FC<{ menu: Menu }> = ({ menu }) => {
  const aboutText = menu.info?.about || '';
  return (
    <div className={container}>
      <MainNavigation menu={menu} />
      <div className={pageHeader}>
        <h1>About Us</h1>
      </div>
      <p className={pageContent}>{aboutText}</p>
    </div>
  );
};

export default AboutView;
