/**
 * @file This file is used to define the TableOfContents component which is a
 * button that when pressed, descends the user to the section corresponding with the section clicked.
 */

import React from 'react';
import cx from 'classnames';
import { Section } from '../models/menu.model';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

import './TableOfContents.component.css';

interface TableOfContents {
  className?: string;
  items: string[];
  onClick?: () => void;
}

function TableOfContents(params: TableOfContents) {
  const { className, items, onClick } = params;

  return (
    <div className={cx('view-scroller', className)}>
      <div className="table-of-contents-title">CONTENTS</div>
      {items.map((item) => (
        <div className="table-of-contents-text" key={item}>
          <Link to={`/explore/${item}`} onClick={onClick}>
            {item}
          </Link>
        </div>
      ))}
    </div>
  );
}

export default TableOfContents;
