import { gql } from 'urql';
import { mutationContext } from '../utils/api.utils';

export const usePatchRestaurantStringifiedMutation = mutationContext(gql`
  mutation PatchRestaurantStringifiedMutation($input: String!, $id: Int) {
    patchRestaurantStringified(input: $input, id: $id) {
      __typename
      id
      stringified
    }
  }
`);

export default usePatchRestaurantStringifiedMutation;
