import { css } from '@emotion/css';
import Markdown from './Markdown';

const Description: React.FC<{
  text: string;
  textColor: string;
  backgroundColor: string;
  sans?: boolean;
}> = (props) => {
  return (
    <div
      className={css`
        color: ${props.textColor};
        background-color: ${props.backgroundColor};
        padding: 30px 28px;
        ${props.sans
          ? 'font: 17px var(--font-sans); line-height: 1.5em;'
          : 'font: 17px var(--font-serif);'}
      `}
    >
      <Markdown>{props.text}</Markdown>
    </div>
  );
};

export default Description;
