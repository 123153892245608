/**
 * @file This file creates the Menu View to show all the food and drink items
 * that exist in the menu.
 */

import React, { useState, useEffect, useMemo, useRef, createContext } from 'react';
import _ from 'lodash';
import ReactGA from 'react-ga4';
import Nav from 'react-bootstrap/Nav';
import cx from 'classnames';
import * as R from 'ramda';
import { flatten, compose } from 'ramda';
import {
  useNavigate,
  useMatch,
  useLocation,
  matchPath,
} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import MediaQuery from 'react-responsive';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { css } from '@emotion/css';

import { MainNavigation } from './MainNavigation.container';
import { recordAndRedirect } from '../utils/analytics.utils';
import FilterPanel, { useFilter } from '../components/FilterPanel.component';
import { Menu, Section, Item, Theme } from '../models/menu.model';
import { useMaybeMenufy } from '../integrations/UseIntegrations';
import {
  indexItemByName,
  sortItemByImage,
  pluralize,
  randomizeItem,
} from '../utils/model.utils';
import { preventDefault } from '../utils/event.utils';
import MenuItem from '../components/MenuItem.component';
import {
  doubleRightArrow,
  doubleLeftArrow,
  ListIconBlack,
  FilterIcon,
  GridViewIcon,
} from '../icons/index';
import { dispatch, State, store } from '../state/store';
import { buildAddItem } from '../state/selectedItemList/actionCreators.selectedItemList';
import './MenuView.container.css';
import { useMatches } from '../utils/router.utils';
import { MenuItemGroup } from '../components/MenuItemGroup.component';
import { DateTime } from 'luxon';

(window as any).matchPath = matchPath;

const reduxDispatchItem = compose(dispatch, buildAddItem);

const titleClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  text-align: center;
  margin-right: 8px;
`;

const textClass = css`
  font-size: 14px;
  font-weight: 300;
`;

const desktopImageClass = css`
  width: 100vw;
`;

interface MenuViewParams {
  className?: string;
  menu: Menu;
  onConfirm: (name: string, message: string) => unknown;
  isTraining: boolean;
}

// const isTrainingURL = new URLSearchParams(window.location.search).get(
//   'trainingMode',
// );
// const trainingMode = isTrainingURL === '1';

function MenuView({
  className,
  menu,
  onConfirm,
  isTraining,
}: MenuViewParams): React.ReactElement {
  const { sections, theme, name: title, info, slides } = menu;
  console.log('menu', menu);
  const menuRef = React.useRef<HTMLDivElement>(null);


  const location = useLocation();

  // useEffect(() => {

  //   const flagExists = location.search.includes('trainingMode=1');

  //   if (trainingMode && !flagExists) {
  //     const newUrl = `${window.location.pathname}?trainingMode=1`;
  //     window.history.pushState({ path: newUrl }, '', newUrl);
  //   } 
  // }, [location.search, history, trainingMode]);

  // Doesn't seem to be working
  // const onSubSectionClick = () => {
  //   if (menuRef.current) {
  //     menuRef.current.focus();
  //   }
  // };
  // const [category, setCategory] = useState(false);



  const allItems: Item[] = useMemo(
    () =>
      flatten(
        sections.map((section) =>
          section.subsections.map((subsection) => subsection.items),
        ),
      ),
    [sections],
  );

  const navigate = useNavigate();
  // const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location],
  );

  const pushOrderHistory = () => navigate(`/order`);

  const [dispatchItem, pivotOrder] = useMaybeMenufy(
    reduxDispatchItem,
    pushOrderHistory,
  );

  const exploreMatch = useMatches([
    '/explore/:sectionName',
    '/explore/:sectionName/:subsectionName',
    '/explore/:sectionName/:subsectionName/:itemName',
  ]);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item | undefined>();
  const [minimized, setMinimized] = useState<boolean | undefined>(
    theme?.minimized || undefined,
  );
  const filteredItems = useFilter(allItems);

  // New state for randomize button
  const [isRandomized, setIsRandomized] = useState(false);

  const shownItems = R.indexBy((item) => item.id, filteredItems);
  const { sectionName, subsectionName } = exploreMatch?.params || {};

  const dt = DateTime.local();
  (window as any).dt = dt;
  const currentDay = dt.weekday - 1;
  const currentTime = dt.toLocaleString(DateTime.TIME_24_SIMPLE);

  const finalSections = sections.filter((section) => {
    if (section.disabled) {
      return false;
    }

    const sectionEndTime = section.timedMenu?.endTime;
    const sectionStartTime = section.timedMenu?.startTime;
    const availableDays = section.timedMenu?.availableDays;

    // Invalid/incomplete/missing timed menu -> render normally
    if (!sectionEndTime || !sectionStartTime || !availableDays?.length) {
      return true;
    }

    // Check if current time within timed menu config
    if (
      availableDays.includes(currentDay) &&
      currentTime >= sectionStartTime &&
      currentTime <= sectionEndTime
    ) {
      return true;
    }

    // Don't render/use
    return false;
  });

  const section =
    sections.find((section) => section.name === sectionName) ||
    finalSections.find((section) => !section.disabled);
  const subsection =
    section?.subsections.find(
      (subsection) => subsection.name === subsectionName,
    ) || section?.subsections.find((subsection) => !subsection.disabled);

  const filteredSubsectionItems = subsection?.items.filter((item) => {
    if (item.id && !shownItems[item.id]) {
      return false;
    }

    // The code below deals with a small quirk. Here's the situation:
    // - Items from the DB have `{inStock: true}` if enabled or
    //  `{inStock: undefined}` (for some reason, not `false`) if disabled.
    // - Items from the JSON menu are missing the `inStock` property. They
    //   should all be enabled.
    //
    // Here are the 3 possibilities for the inStock property:
    //
    //    | Menu is from ... | inStock is ...      | Show or hide the item? |
    // -- | ---------------- | ------------------- | ---------------------- |
    // #1 | DB               | true                | Show                   |
    // #2 | DB               | undefined           | Hide                   |
    // #3 | JSON             | missing (undefined) | Show                   |
    //
    // We need to differentiate between case #2 and #3, and we can't do that by
    // checking the value because they both return a value of `undefined`.
    // Instead, we can check whether the `inStock` property exists.
    //
    // The `'inStock' in item` part and this comment can be removed once we stop
    // using JSON menus.

    return 'inStock' in item ? item.inStock : true;
  });

  function showFilterPanel(event: React.MouseEvent) {
    setFilterPanelOpen(true);
    event.stopPropagation();
  }

  function hideFilterPanel() {
    setFilterPanelOpen(false);
  }

  function handleSelectLink(
    eventKey: any,
    event: React.SyntheticEvent<unknown>,
  ) {
    navigate(eventKey as string);
    preventDefault(event);
    setSelectedItem(undefined);
  }

  function handleGoToOrder() {
    ReactGA.event({
      category: 'My List',
      action: 'go to my list',
      label: 'my list',
    });
    pivotOrder();
  }

  const sectionsElement: React.ReactElement[] = finalSections.map((section) => {
    const key = `/explore/${section.name}`;
    return (
      <Nav.Item key={key}>
        <Nav.Link
          className="section-title"
          eventKey={key}
          href={key}
          onSelect={handleSelectLink}
        >
          {section.name}
        </Nav.Link>
      </Nav.Item>
    );
  });

  const subsectionsElement = section?.subsections
    .filter((subsection) => !subsection.disabled)
    .map((subsection) => {
      const subKey = `/explore/${section.name}/${subsection.name}`;
      return (
        <Nav.Item key={subKey}>
          <Nav.Link eventKey={subKey} href={subKey} onSelect={handleSelectLink}>
            {subsection.name}
          </Nav.Link>
        </Nav.Item>
      );
    });

  function handleMenuItemClick(item: Item, event: React.MouseEvent) {
    
    ReactGA.event({
      category: 'Order',
      action: 'selected',
      label: item.name,
    });
    navigate(`/explore/${section?.name}/${subsection?.name}/${item.name}`);
    event.stopPropagation();
  }

  const itemGroupsRender = (subsection?.itemGroups || []).map((itemGroup) => (
    <MenuItemGroup
      key={itemGroup.id}
      itemGroup={itemGroup}
      onConfirm={onConfirm}
    />
  ));

  const isSmallItem =
    minimized != null ? minimized : subsection?.displayMinimized;

  const itemsElement = useMemo(() => {
    if (isRandomized) {
      return randomizeItem(filteredSubsectionItems || []).map((item) => (
        <MenuItem
          key={item.name}
          highlighted={item.name === selectedItem?.name}
          item={item}
          small={isSmallItem}
          onClick={handleMenuItemClick}
          onConfirm={onConfirm}
        />
      ));
    } else {
      return sortItemByImage(filteredSubsectionItems || []).map((item) => (
        <MenuItem
          key={item.name}
          highlighted={item.name === selectedItem?.name}
          item={item}
          small={isSmallItem}
          onClick={handleMenuItemClick}
          onConfirm={onConfirm}
        />
      ));
    }
  }, [isRandomized, subsectionsElement]);
  const menuContentRender = itemGroupsRender.concat(itemsElement);

  const order = useSelector((state: State) => state.selectedItemList.order);
  const numberOfItemsInCart = Object.values(order)
    .map((selItem) => selItem.quantity)
    .reduce((a, b) => a + b, 0);

  return (
    // <training.Provider value={trainingMode}>
      <div ref={menuRef} className={cx('menu-view', className)}>
        <div className="menu-container">
          {/* Desktop navigation top bar */}
          <MediaQuery minWidth={1041}>
            <MainNavigation menu={menu} />
            {slides[0]?.desktopImage && (
              <img
                className={desktopImageClass}
                src={slides[0]?.desktopImage}
                alt="restaurant background"
              />
            )}
          </MediaQuery>
          <div className="banner">
            <div className={titleClass}>
              {/* Desktop */}
              <MediaQuery minWidth={1041}>
                {theme && theme.logo ? (
                  <img src={theme.logo} alt="restaurant logo img" />
                ) : (
                  <h1>{title}</h1>
                )}
              </MediaQuery>
              {/* Mobile */}
              <MediaQuery maxWidth={1040}>
                {theme && theme.logo ? (
                  <img src={theme.logo} alt="restaurant logo img" />
                ) : (
                  <h2>{title}</h2>
                )}
              </MediaQuery>
            </div>
            <div className="btn-container">
              {theme && theme.filters ? (
                <Button
                  className="filter-button"
                  variant="light"
                  onClick={showFilterPanel}
                >
                  {FilterIcon}
                </Button>
              ) : null}
              <Button
                className="minimize-toggle"
                variant="light"
                onClick={() => setMinimized((m) => !m)}
              >
                {GridViewIcon}
              </Button>
            </div>
          </div>
          {theme && theme.specialAnnouncements ? (
            <div className="special-announcements">
              {/* Commenting this out for now; may set this header to be toggalable in the future */}
              {/* <div className="sa-header">Special Announcements</div> */}
              {/*TODO: Turn sa-events into carousel to allow for multiple events */}
              <div className="sa-event">
                <div className="event-image">
                  <img
                    src={theme.specialAnnouncements.image}
                    alt="special announcement img"
                  />
                </div>
                <div className="event-description">
                  <div className="event-title">
                    {theme.specialAnnouncements.title}
                  </div>
                  <div className="event-subtitle">
                    {theme.specialAnnouncements.subtitle}
                  </div>
                  <a
                    className="announcement-button"
                    href={theme.specialAnnouncements.link}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      //e.preventDefault();
                      recordAndRedirect(theme.specialAnnouncements?.link, 'SA');
                    }}
                  >
                    {theme.specialAnnouncements.buttonText}
                  </a>
                </div>
              </div>
            </div>
          ) : null}
          <div className="top-bar">
            <div className="nav-bars">
              {sectionsElement.length && (
                <ScrollMenu
                  data={sectionsElement}
                  arrowLeft={doubleLeftArrow}
                  arrowRight={doubleRightArrow}
                  arrowClass="section-arrow"
                  arrowDisabledClass="disabled-arrow"
                  alignCenter={false}
                  menuClass="section flex-nowrap text-nowrap"
                  itemClassActive="active-section-header"
                  hideArrows={true}
                  hideSingleArrow={true}
                  selected={`/explore/${section?.name}`}
                  scrollToSelected={true}
                  useButtonRole={false}
                  inertiaScrolling={true}
                />
              )}

              <ScrollMenu
                data={subsectionsElement}
                arrowLeft={doubleLeftArrow}
                arrowRight={doubleRightArrow}
                arrowClass="nav-arrow"
                arrowDisabledClass="disabled-arrow"
                alignCenter={false}
                menuClass="subsection"
                itemClassActive="active-subsection-header"
                hideArrows={true}
                hideSingleArrow={true}
                selected={`/explore/${section?.name.trim()}/${subsection?.name.trim()}`}
                scrollToSelected={true}
                useButtonRole={false}
                inertiaScrolling={true}
              />
            </div>
          </div>

          <div className="item-list">
            <div className="note-and-items">
              {subsection?.note && (
                <div
                  className="note"
                  dangerouslySetInnerHTML={{ __html: subsection.note }}
                ></div>
              )}
              {/* New button to randomize item orders.*/}
              {isTraining ? (
                <div className="random">
                  <Button
                    className="randomize"
                    variant="light"
                    onClick={() => setIsRandomized(!isRandomized)}
                  >
                    {!isRandomized ? 'Randomize Order' : 'Original Order'}
                  </Button>
                </div>
              ) : (
                ''
              )}

              <div className="items">{menuContentRender}</div>
              {menuContentRender.length ? null : (
                <div className="no-items-message">
                  <p>
                    Sorry, no items in this category match your selected
                    filters.
                  </p>
                  <p>Please expand your search.</p>
                </div>
              )}
            </div>

            <div className="list-footer">
              {theme && theme.footerImage ? (
                <img src={theme?.footerImage} alt="restaurant front" />
              ) : null}
              {theme && theme.photoCreds && menuContentRender.length ? (
                <div className="text">
                  Photography Credits:
                  <a href={theme.photoCreds.link}> {theme.photoCreds.name}</a>
                </div>
              ) : null}
              {theme && theme.footerText ? (
                <div className="text"> {theme.footerText} </div>
              ) : null}
              {theme && theme.customLink ? (
                <div className="text">
                  <a href={theme.customLink}>
                    {' '}
                    {theme.customLinkText ?? theme.customLink}{' '}
                  </a>
                </div>
              ) : null}
              <p className="text branding">
                This digital menu is powered by Nom <br />
                <a href="https://www.nommenu.com/">www.nommenu.com</a>
              </p>
            </div>

            <div className="floating-footer">
              <Button
                className="order-button"
                variant="light"
                onClick={handleGoToOrder}
              >
                <div className="image-button">
                  <div
                    className={css`
                      position: relative;
                      min-width: 36px;
                    `}
                  >
                    {ListIconBlack}
                    {numberOfItemsInCart ? (
                      <div
                        className={css`
                          top: 0;
                          right: 0;
                          width: 16px;
                          height: 16px;
                          background: #eb3313;
                          border-radius: 50%;
                          padding: 0;
                          margin: 0;
                          color: white;
                          font-weight: 600;
                          position: absolute;
                          font-size: 10px;
                        `}
                      >
                        {numberOfItemsInCart}
                      </div>
                    ) : null}
                  </div>
                </div>{' '}
                {/* Old code for the larger review my list button
              <div style={{ color: 'blue' }}>
                {pluralize(numberOfItemsInCart, 'item')}
              </div> */}
              </Button>
            </div>
          </div>
          {/* Bottom-sheet for mobile; Modal for desktop*/}
          <MediaQuery maxWidth={1040}>
            <div className="filter-sheet-mobile">
              <SwipeableBottomSheet
                open={filterPanelOpen}
                onChange={hideFilterPanel}
                overlay={true}
                bodyStyle={{ borderRadius: '8px 8px 0 0' }}
              >
                <FilterPanel onClose={hideFilterPanel} theme={theme} />
              </SwipeableBottomSheet>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={1041}>
            <Modal
              dialogClassName="filter-modal"
              show={filterPanelOpen}
              onHide={hideFilterPanel}
            >
              <FilterPanel onClose={hideFilterPanel} theme={theme} />
            </Modal>
          </MediaQuery>
        </div>
      </div>
  );
}


export default MenuView;
