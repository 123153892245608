export const ListItemArrow: React.FC<{
  color?: string;
}> = (props) => {
  return (
    <svg
      width="6"
      height="11"
      style={{
        maxWidth: '100%',
        minWidth: '10px',
        color: props.color || 'black',
      }}
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.772 0.696V10.704L5.776 5.7L0.772 0.696Z" fill={props.color} />
    </svg>
  );
};
