import * as React from 'react';
import { PhotoIcon } from '../icons';
import './PhotoContest.component.css';

interface PhotoContestParams {
  link: string;
  title: string;
}

function PhotoContest(params: PhotoContestParams) {
  const { link, title } = params;
  return (
    <div className="photo-contest">
      <div className="banner">
        <div className="photo-icon">{PhotoIcon}</div>
        <h4>
          <b>{title}</b>
        </h4>
        <p>
          Submit your best photo of this dish to enter our photo competition.
        </p>
        <a
          className="submit-btn"
          href={link}
          rel="noopener noreferrer"
          target="_blank"
        >
          Submit a photo
        </a>
      </div>
    </div>
  );
}

export default PhotoContest;
