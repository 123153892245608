import { map, fromPairs } from 'ramda';

import { tagToString } from '../../utils/model.utils';
import { filters } from '../../utils/filter.utils';

import { FiltersState as State } from './types.filters';
import { FiltersAction as Action } from './actions.filters';

const defaultState = {
  filterMap: fromPairs(
    map((tag) => [tagToString(tag), { ...tag, checked: false }], filters),
  ),
};

export function filtersReducer(
  state: State | undefined,
  action: Action,
): State {
  state = state || defaultState;

  switch (action.type) {
    case 'SetFilterMap':
      return {
        ...state,
        filterMap: action.filterMap,
      };
    default:
      return state;
  }
}
