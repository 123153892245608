import {
  VegetarianIcon,
  VeganIcon,
  GlutenFreeIcon,
  HalalIcon,
  ChiliOneIcon,
  PeanutIcon,
  SesameFreeIcon,
  SignatureIcon,
  SesameIcon,
  NutIcon,
  SoyIcon,
  DairyIcon,
  PracticingOrganicIcon,
  CertifiedOrganicIcon,
  BiodynamicIcon,
  LocallySourcedIcon,
  CertifiedSustainableIcon,
  DairyFreeIcon,
  NutsFreeIcon,
  ContainsGlutenIcon,
  ContainsSeedsIcon,
  LactoseFreeIcon
} from '../icons';
import { groupTagByLabel, indexTagByValue } from '../utils/model.utils';
import { FilterTag } from '../models/filter.model';

// prettier-ignore
/** Current available filters. Filter.value is unique. */
export const filters: FilterTag[] = [
  { label: 'dietary', value: 'vegetarian', type: 'include', icon: VegetarianIcon },
  { label: 'dietary', value: 'vegan', type: 'include', icon: VeganIcon },
  { label: 'dietary', value: 'gluten-free', type: 'include', icon: GlutenFreeIcon },
  { label: 'dietary', value: 'dairy-free', type: 'include', icon: DairyFreeIcon },
  { label: 'dietary', value: 'nuts-free', type: 'include', icon: NutsFreeIcon },
  { label: 'dietary', value: 'contains-gluten', type: 'include', icon: ContainsGlutenIcon },
  { label: 'dietary', value: 'contains-seeds', type: 'include', icon: ContainsSeedsIcon },
  { label: 'dietary', value: 'lactose-free', type: 'include', icon: LactoseFreeIcon },
  // { label: 'dietary', value: 'halal', type: 'include', icon: HalalIcon },
  { label: 'dietary', value: 'spicy', type: 'include', icon: ChiliOneIcon },
  { label: 'dietary', value: 'sesame-free', type: 'include', icon: SesameFreeIcon },
  { label: 'dietary', value: 'signature', type: 'include', icon: SignatureIcon }, 
  //ivwb wine filters
  { label: 'dietary', value: 'practicing organic', type: 'include', icon: PracticingOrganicIcon }, 
  { label: 'dietary', value: 'certified organic', type: 'include', icon: CertifiedOrganicIcon }, 
  { label: 'dietary', value: 'certified sustainable', type: 'include', icon: CertifiedSustainableIcon }, 
  { label: 'dietary', value: 'biodynamic', type: 'include', icon: BiodynamicIcon }, 
  { label: 'dietary', value: 'Locally Sourced', type: 'include', icon: LocallySourcedIcon }, 
  //end ivwb wine filters
  { label: 'allergy', value: 'peanut', type: 'exclude', icon: PeanutIcon },
  { label: 'allergy', value: 'sesame', type: 'exclude', icon: SesameIcon },
  // { label: 'allergy', value: 'fish', type: 'exclude' },
  { label: 'allergy', value: 'shellfish', type: 'exclude' },
  // { label: 'allergy', value: 'egg', type: 'exclude' },
  { label: 'allergy', value: 'dairy', type: 'exclude', icon: DairyIcon },
  // { label: 'allergy', value: 'wheat', type: 'exclude' },
  // { label: 'allergy', value: 'tree nut', type: 'exclude' },
  { label: 'allergy', value: 'nut', type: 'exclude', icon: NutIcon },
  { label: 'allergy', value: 'soy', type: 'exclude', icon: SoyIcon },

  // Items with this tag will always be included
  { label: 'meta', value: 'always include', type: 'meta' },
];

/** A map of Filter.label => Filter[] */
export const filtersByLabel = groupTagByLabel(filters);

/** A map of Filter.value => Filter */
export const filterByValue = indexTagByValue(filters);
