import { DocumentNode } from 'graphql';
import {
  useQuery,
  useMutation,
  gql,
  UseQueryArgs,
  UseQueryResponse,
  AnyVariables,
  TypedDocumentNode,
} from 'urql';

// eslint-disable-next-line @typescript-eslint/ban-types
export function queryContext<
  ContextVariables extends AnyVariables = AnyVariables,
  ContextData extends AnyVariables = AnyVariables,
>(
  // TODO type this better
  query:
    | string
    | DocumentNode
    | TypedDocumentNode<ContextData, ContextVariables>,
) {
  return function useQueryContext<
    Variables extends AnyVariables = ContextVariables,
    Data = ContextData,
  >(
    args?: Omit<UseQueryArgs<Variables, Data>, 'query'>,
  ): UseQueryResponse<Data, Variables> {
    const queryArgs = {
      query,
      ...(args || {}),
    } as any;
    return useQuery<Data, Variables>(queryArgs);
  };
}

// TODO type it better
export function mutationContext(query: ReturnType<typeof gql>) {
  return function useMutationContext(): ReturnType<typeof useMutation> {
    return useMutation(query);
  };
}
