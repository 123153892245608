import { cx, css } from '@emotion/css';
import { Title } from './Title';
import * as G from '../../nom-server/src/generated/frontendGraphql';

interface FullBleedProps {
  title?: string;
  bgSrc: string;
  variant?: string;
  left?: boolean;
  restaurant?: G.RestaurantV2;
  textColor?: string;
}
const FullBleed = ({
  title,
  bgSrc,
  variant,
  left,
  restaurant,
  textColor,
}: FullBleedProps) => {
  const variantCss =
    variant === 'framed'
      ? `border: 2px solid #fff;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 80%;`
      : `width: 90%;
      height: 90%;
      align-items: flex-end;`;
  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        ${restaurant?.settings?.mainColor
          ? 'background-color: ' + restaurant?.settings?.mainColor
          : 'background-color: #032165'};
      `}
    >
      <img
        src={bgSrc}
        className={css`
          filter: brightness(0.7);
          ${left
            ? `max-height: 60vh;
            object-fit: cover;
            width: 100%;`
            : `width: 100%;
            height: auto;`}
        `}
      />
      {title && (
        <div
          className={cx(
            'nom-title',
            css`
              z-index: 1;
              position: absolute;
              display: flex;
              text-align: center;
              ${variantCss}
            `,
          )}
        >
          <Title title={title} left={left} textColor={textColor} />
        </div>
      )}
    </div>
  );
};

export default FullBleed;
