import { css } from '@emotion/css';
import { useMemo } from 'react';
import GridCell from './GridCell';
import * as G from '../../nom-server/src/generated/frontendGraphql';

const GRID_LAYOUT = [
  'lg',
  'sm',
  'sm',
  'md',
  'md',

  'sm',
  'lg',
  'sm',
  'md',
  'md',

  'lg',
  'sm',
  'sm',
  'md',
  'md',

  'sm',
  'lg',
  'sm',
  'md',
  'md',
];

type GridBlock = {
  title: string;
  titleLocalized?: string;
  to: string;
  src: string;
};

function GridComponent({ blocks, restaurant }: { blocks: GridBlock[], restaurant: G.RestaurantV2 }) {
  const gridCells = useMemo(() => {
    return blocks.map((b, i) => {
      return { ...b, size: GRID_LAYOUT[i] || 'sm' };
    });
  }, [blocks]);

  return (
    <div
      className={css`
        margin: 0 auto;
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-auto-flow: row dense;
      `}
    >
      {gridCells.map((grid, idx) => (
        <GridCell
          key={grid.title}
          title={grid.title}
          titleLocalized={grid.titleLocalized}
          size={grid.size}
          to={grid.to}
          src={grid.src}
          settings={restaurant.settings!}
          position={idx}
          rootSectionId={grid.to}
        />
      ))}
    </div>
  );
}

export default GridComponent;
