/**
 * @file This file creates the view to show QR codes from the url.
 */

import React, { useState } from 'react';

import QrCode from '../components/QrCode.component';

import './QrCodeView.container.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface QrCodeViewParams {}

function QrCodeView(params: QrCodeViewParams) {
  const [tableCount, setTableCount] = useState<number>(20);
  const { host } = window.location;
  const url = 'http://' + host;

  return (
    <div className="qr-code-view">
      <h1>QR Codes</h1>
      <div className="qr-codes-container">
        <QrCode title="No Table" text={url} />
        {[...new Array(tableCount)].map((e, i) => (
          <QrCode
            key={i}
            title={`Table ${i + 1}`}
            text={`${url}?tableId=${i + 1}`}
          />
        ))}
      </div>
    </div>
  );
}

export default QrCodeView;
