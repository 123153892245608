import { css } from '@emotion/css';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../state/language/actions.language';

function LanguageToggle({
  languages,
  fontColor,
}: {
  languages: string[];
  fontColor: string;
}) {
  const dispatch = useDispatch();

  const handleLanguageClick = (language: string) => {
    dispatch(setLanguage(language));
    console.log('Language set to', language);
  };

  languages = ['en', ...languages];

  return (
    <div
      className={css`
        color: ${fontColor};
        margin: 10px 30px;
        text-align: right;
      `}
    >
      {languages.map((language, index) => (
        <>
          <span
            key={index}
            onClick={() => handleLanguageClick(language)}
            className={css`
              cursor: pointer;
            `}
          >
            {language.toUpperCase()}
          </span>
          {index !== languages.length - 1 && ' | '}
        </>
      ))}
    </div>
  );
}

export default LanguageToggle;
