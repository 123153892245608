import { useEffect } from 'react';

/**
 * useEffect hook to set the page title.
 */
export function usePageTitle(title?: string | false | null) {
  useEffect(() => {
    if (!title) {
      return;
    }
    const originalTitle = document.title;
    document.title = title;

    // Dynamically set manifest.json
    // const origionalManifest = (document.querySelector('#manifest-json') as HTMLAnchorElement).href;
    console.log('adding manifest')

    const myDynamicManifest = {
      "short_name": title,
      "name": title,
      "icons": [
        {
          "src": "favicon.ico",
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon"
        },
        {
          "src": "logo192.png",
          "type": "image/png",
          "sizes": "192x192"
        },
        {
          "src": "logo512.png",
          "type": "image/png",
          "sizes": "512x512"
        }
      ],
      "start_url": ".",
      "display": "standalone",
      "orientation": "portrait",
      "theme_color": "#000000",
      "background_color": "#ffffff"
    }

    const manifest = document.createElement('Link')
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    (manifest as HTMLAnchorElement).rel = "manifest";
    manifest.setAttribute('href', manifestURL);

    // manifest.setAttribute('href', 'data:application/json;charset=8' + stringManifest);
    document.head.appendChild(manifest);

    return () => {
      document.title = originalTitle;
      // (document.querySelector('#manifest-json') as HTMLAnchorElement).href = origionalManifest;
    };
  }, [title]);
}
