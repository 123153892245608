import React from 'react';

/* Menu Interface icons */

const landingNotchStyle = {
  color: 'white',
  padding: '8px',
  fontSize: '18px',
  fontWeight: 500,
};

export const LandingNotchLeft: React.FC = ({ children }) => (
  <>
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93881 1L0.999998 9.11736L8.93881 17.2347"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <span style={landingNotchStyle}>{children}</span>
  </>
);

export const LandingNotchRight: React.FC = ({ children }) => (
  <>
    <span style={landingNotchStyle}>{children}</span>
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17.2347L8.93881 9.11739L0.999999 1.00003"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

export const NotchLeft = (
  <>
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93881 1L0.999998 9.11736L8.93881 17.2347"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

export const NotchRight = (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 17.2347L8.93881 9.11739L0.999999 1.00003"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DoubleNotchDown = (
  <svg
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L5.60714 5.60714L10.2143 1"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 7.14288L5.60714 11.75L10.2143 7.14288"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const XButton = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21 21L2 2" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path d="M21 2L2 21" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export const PhotoIcon = (
  <svg
    width="65"
    height="65"
    viewBox="0 0 65 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32.5" cy="32.5" r="32.5" fill="white" fillOpacity="0.5" />
    <rect
      x="18"
      y="25.0349"
      width="28.832"
      height="18.2097"
      rx="4"
      fill="white"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M21.035 25.0349H27.8636V24C27.8636 22.8954 26.9682 22 25.8636 22H23.035C21.9304 22 21.035 22.8954 21.035 24V25.0349Z"
      fill="#E9C795"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M43.7971 28.8199C44.2113 28.8199 44.5471 28.4841 44.5471 28.0699C44.5471 27.6557 44.2113 27.3199 43.7971 27.3199V28.8199ZM39.2446 28.8199H43.7971V27.3199H39.2446V28.8199Z"
      fill="black"
    />
    <circle
      cx="32.416"
      cy="34.1398"
      r="4.55242"
      fill="#E9C795"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
);

export const NotchDown = (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <circle cx="25.5" cy="23.5" r="18.5" fill="#282828" />
      <path
        d="M17.5714 20.8571L25.6888 28.7959L33.8061 20.8571"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="51"
        height="51"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="3.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const FilterButton = (
  <svg
    width="53"
    height="53"
    viewBox="-1 -3 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)"></g>
    <line
      x1="13.75"
      y1="13.25"
      x2="28.25"
      y2="13.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="34.75"
      y1="13.25"
      x2="38.25"
      y2="13.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="23.75"
      y1="22.25"
      x2="38.25"
      y2="22.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="13.75"
      y1="22.25"
      x2="17.25"
      y2="22.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="13.75"
      y1="31.25"
      x2="25.25"
      y2="31.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="31.75"
      y1="31.25"
      x2="38.25"
      y2="31.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="31.5" cy="13.5" r="2.75" stroke="white" strokeWidth="1.5" />
    <circle cx="20.5" cy="21.5" r="2.75" stroke="white" strokeWidth="1.5" />
    <circle cx="28.5" cy="30.5" r="2.75" stroke="white" strokeWidth="1.5" />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="53"
        height="53"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const BottomSheetTopLine = (
  <svg
    width="52"
    height="4"
    viewBox="0 0 52 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="2"
      y1="2"
      x2="50"
      y2="2"
      stroke="#A5A2A2"
      strokeOpacity="0.49"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Checkmark = (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 4.66014L2.62776 8L8.25 1"
      stroke="#282828"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* Item Details Icons */

export const ChiliOneIcon = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.74942 2.56721C6.77156 2.54404 6.79786 2.52567 6.8268 2.51313C6.85574 2.50059 6.88676 2.49414 6.91809 2.49414C6.94942 2.49414 6.98044 2.50059 7.00938 2.51313C7.03832 2.52567 7.06462 2.54404 7.08676 2.56721C7.41622 2.91164 8.21184 3.4273 8.95845 3.07273C8.83411 2.66209 8.59928 2.29758 8.28204 2.02277C7.96481 1.74795 7.57858 1.57447 7.16953 1.52304C7.23196 0.819872 7.60082 0.498819 8.34949 0.498819C8.41276 0.498819 8.47344 0.472542 8.51818 0.425769C8.56292 0.378995 8.58806 0.315557 8.58806 0.24941C8.58806 0.183262 8.56292 0.119824 8.51818 0.0730503C8.47344 0.0262769 8.41276 0 8.34949 0C7.33361 0 6.76958 0.528449 6.69461 1.52009C6.28023 1.56638 5.88772 1.7379 5.56509 2.01367C5.24246 2.28945 5.00367 2.65752 4.87793 3.0729C5.6245 3.42812 6.41999 2.91182 6.74942 2.56721Z"
      fill="#E73434"
    />
    <path
      d="M8.43508 3.69704C7.87695 3.67888 7.34161 3.4614 6.91819 3.08081C6.36129 3.56676 5.5372 3.871 4.78599 3.57705C4.78209 3.63212 4.77108 3.68498 4.77108 3.74112C4.77108 6.43567 3.11901 7.98109 0.238334 7.98109C0.189623 7.98114 0.142094 7.99677 0.102135 8.02589C0.0621755 8.05502 0.031701 8.09623 0.0148072 8.14399C-0.00208657 8.19176 -0.00459 8.24379 0.00763325 8.29308C0.0198565 8.34238 0.0462206 8.38658 0.0831823 8.41975C0.984135 9.23856 2.13799 9.68902 3.33178 9.68799C4.13206 9.68239 4.92166 9.49541 5.64614 9.13994C7.72311 8.14136 9.06528 6.0221 9.06528 3.74112C9.06528 3.68604 9.05454 3.63414 9.05077 3.58007C8.85394 3.65743 8.64537 3.69706 8.43508 3.69704Z"
      fill="#E73434"
    />
  </svg>
);

export const ChiliTwoIcon = (
  <svg
    width="15"
    height="10"
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.74942 2.56721C6.77156 2.54404 6.79786 2.52567 6.8268 2.51313C6.85574 2.50059 6.88676 2.49414 6.91809 2.49414C6.94942 2.49414 6.98044 2.50059 7.00938 2.51313C7.03832 2.52567 7.06462 2.54404 7.08676 2.56721C7.41622 2.91164 8.21184 3.4273 8.95845 3.07273C8.83411 2.66209 8.59928 2.29758 8.28204 2.02277C7.96481 1.74795 7.57858 1.57447 7.16953 1.52304C7.23196 0.819872 7.60082 0.498819 8.34949 0.498819C8.41276 0.498819 8.47344 0.472542 8.51818 0.425769C8.56292 0.378995 8.58806 0.315557 8.58806 0.24941C8.58806 0.183262 8.56292 0.119824 8.51818 0.0730503C8.47344 0.0262769 8.41276 0 8.34949 0C7.33361 0 6.76958 0.528449 6.69461 1.52009C6.28023 1.56638 5.88772 1.7379 5.56509 2.01367C5.24246 2.28945 5.00367 2.65752 4.87793 3.0729C5.6245 3.42812 6.41999 2.91182 6.74942 2.56721Z"
      fill="#E73434"
    />
    <path
      d="M8.43508 3.69704C7.87695 3.67888 7.34161 3.4614 6.91819 3.08081C6.36129 3.56676 5.5372 3.871 4.78599 3.57705C4.78209 3.63212 4.77108 3.68498 4.77108 3.74112C4.77108 6.43567 3.11901 7.98109 0.238334 7.98109C0.189623 7.98114 0.142094 7.99677 0.102135 8.02589C0.0621755 8.05502 0.031701 8.09623 0.0148072 8.14399C-0.00208657 8.19176 -0.00459 8.24379 0.00763325 8.29308C0.0198565 8.34238 0.0462206 8.38658 0.0831823 8.41975C0.984135 9.23856 2.13799 9.68902 3.33178 9.68799C4.13206 9.68239 4.92166 9.49541 5.64614 9.13994C7.72311 8.14136 9.06528 6.0221 9.06528 3.74112C9.06528 3.68604 9.05454 3.63414 9.05077 3.58007C8.85394 3.65743 8.64537 3.69706 8.43508 3.69704Z"
      fill="#E73434"
    />
    <path
      d="M11.7494 2.56721C11.7716 2.54404 11.7979 2.52567 11.8268 2.51313C11.8557 2.50059 11.8868 2.49414 11.9181 2.49414C11.9494 2.49414 11.9804 2.50059 12.0094 2.51313C12.0383 2.52567 12.0646 2.54404 12.0868 2.56721C12.4162 2.91164 13.2118 3.4273 13.9585 3.07273C13.8341 2.66209 13.5993 2.29758 13.282 2.02277C12.9648 1.74795 12.5786 1.57447 12.1695 1.52304C12.232 0.819872 12.6008 0.498819 13.3495 0.498819C13.4128 0.498819 13.4734 0.472542 13.5182 0.425769C13.5629 0.378995 13.5881 0.315557 13.5881 0.24941C13.5881 0.183262 13.5629 0.119824 13.5182 0.0730503C13.4734 0.0262769 13.4128 0 13.3495 0C12.3336 0 11.7696 0.528449 11.6946 1.52009C11.2802 1.56638 10.8877 1.7379 10.5651 2.01367C10.2425 2.28945 10.0037 2.65752 9.87793 3.0729C10.6245 3.42812 11.42 2.91182 11.7494 2.56721Z"
      fill="#E73434"
    />
    <path
      d="M13.4351 3.69704C12.8769 3.67888 12.3416 3.4614 11.9182 3.08081C11.3613 3.56676 10.5372 3.871 9.78599 3.57705C9.78209 3.63212 9.77108 3.68498 9.77108 3.74112C9.77108 6.43567 8.11901 7.98109 5.23833 7.98109C5.18962 7.98114 5.14209 7.99677 5.10213 8.02589C5.06218 8.05502 5.0317 8.09623 5.01481 8.14399C4.99791 8.19176 4.99541 8.24379 5.00763 8.29308C5.01986 8.34238 5.04622 8.38658 5.08318 8.41975C5.98414 9.23856 7.13799 9.68902 8.33178 9.68799C9.13206 9.68239 9.92166 9.49541 10.6461 9.13994C12.7231 8.14136 14.0653 6.0221 14.0653 3.74112C14.0653 3.68604 14.0545 3.63414 14.0508 3.58007C13.8539 3.65743 13.6454 3.69706 13.4351 3.69704Z"
      fill="#E73434"
    />
  </svg>
);

export const ChiliThreeIcon = (
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.74942 2.56721C6.77156 2.54404 6.79786 2.52567 6.8268 2.51313C6.85574 2.50059 6.88676 2.49414 6.91809 2.49414C6.94942 2.49414 6.98044 2.50059 7.00938 2.51313C7.03832 2.52567 7.06462 2.54404 7.08676 2.56721C7.41622 2.91164 8.21184 3.4273 8.95845 3.07273C8.83411 2.66209 8.59928 2.29758 8.28204 2.02277C7.96481 1.74795 7.57858 1.57447 7.16953 1.52304C7.23196 0.819872 7.60082 0.498819 8.34949 0.498819C8.41276 0.498819 8.47344 0.472542 8.51818 0.425769C8.56292 0.378995 8.58806 0.315557 8.58806 0.24941C8.58806 0.183262 8.56292 0.119824 8.51818 0.0730503C8.47344 0.0262769 8.41276 0 8.34949 0C7.33361 0 6.76958 0.528449 6.69461 1.52009C6.28023 1.56638 5.88772 1.7379 5.56509 2.01367C5.24246 2.28945 5.00367 2.65752 4.87793 3.0729C5.6245 3.42812 6.41999 2.91182 6.74942 2.56721Z"
      fill="#E73434"
    />
    <path
      d="M8.43508 3.69704C7.87695 3.67888 7.34161 3.4614 6.91819 3.08081C6.36129 3.56676 5.5372 3.871 4.78599 3.57705C4.78209 3.63212 4.77108 3.68498 4.77108 3.74112C4.77108 6.43567 3.11901 7.98109 0.238334 7.98109C0.189623 7.98114 0.142094 7.99677 0.102135 8.02589C0.0621755 8.05502 0.031701 8.09623 0.0148072 8.14399C-0.00208657 8.19176 -0.00459 8.24379 0.00763325 8.29308C0.0198565 8.34238 0.0462206 8.38658 0.0831823 8.41975C0.984135 9.23856 2.13799 9.68902 3.33178 9.68799C4.13206 9.68239 4.92166 9.49541 5.64614 9.13994C7.72311 8.14136 9.06528 6.0221 9.06528 3.74112C9.06528 3.68604 9.05454 3.63414 9.05077 3.58007C8.85394 3.65743 8.64537 3.69706 8.43508 3.69704Z"
      fill="#E73434"
    />
    <path
      d="M11.7494 2.56721C11.7716 2.54404 11.7979 2.52567 11.8268 2.51313C11.8557 2.50059 11.8868 2.49414 11.9181 2.49414C11.9494 2.49414 11.9804 2.50059 12.0094 2.51313C12.0383 2.52567 12.0646 2.54404 12.0868 2.56721C12.4162 2.91164 13.2118 3.4273 13.9585 3.07273C13.8341 2.66209 13.5993 2.29758 13.282 2.02277C12.9648 1.74795 12.5786 1.57447 12.1695 1.52304C12.232 0.819872 12.6008 0.498819 13.3495 0.498819C13.4128 0.498819 13.4734 0.472542 13.5182 0.425769C13.5629 0.378995 13.5881 0.315557 13.5881 0.24941C13.5881 0.183262 13.5629 0.119824 13.5182 0.0730503C13.4734 0.0262769 13.4128 0 13.3495 0C12.3336 0 11.7696 0.528449 11.6946 1.52009C11.2802 1.56638 10.8877 1.7379 10.5651 2.01367C10.2425 2.28945 10.0037 2.65752 9.87793 3.0729C10.6245 3.42812 11.42 2.91182 11.7494 2.56721Z"
      fill="#E73434"
    />
    <path
      d="M13.4351 3.69704C12.8769 3.67888 12.3416 3.4614 11.9182 3.08081C11.3613 3.56676 10.5372 3.871 9.78599 3.57705C9.78209 3.63212 9.77108 3.68498 9.77108 3.74112C9.77108 6.43567 8.11901 7.98109 5.23833 7.98109C5.18962 7.98114 5.14209 7.99677 5.10213 8.02589C5.06218 8.05502 5.0317 8.09623 5.01481 8.14399C4.99791 8.19176 4.99541 8.24379 5.00763 8.29308C5.01986 8.34238 5.04622 8.38658 5.08318 8.41975C5.98414 9.23856 7.13799 9.68902 8.33178 9.68799C9.13206 9.68239 9.92166 9.49541 10.6461 9.13994C12.7231 8.14136 14.0653 6.0221 14.0653 3.74112C14.0653 3.68604 14.0545 3.63414 14.0508 3.58007C13.8539 3.65743 13.6454 3.69706 13.4351 3.69704Z"
      fill="#E73434"
    />
    <path
      d="M16.7494 2.56721C16.7716 2.54404 16.7979 2.52567 16.8268 2.51313C16.8557 2.50059 16.8868 2.49414 16.9181 2.49414C16.9494 2.49414 16.9804 2.50059 17.0094 2.51313C17.0383 2.52567 17.0646 2.54404 17.0868 2.56721C17.4162 2.91164 18.2118 3.4273 18.9585 3.07273C18.8341 2.66209 18.5993 2.29758 18.282 2.02277C17.9648 1.74795 17.5786 1.57447 17.1695 1.52304C17.232 0.819872 17.6008 0.498819 18.3495 0.498819C18.4128 0.498819 18.4734 0.472542 18.5182 0.425769C18.5629 0.378995 18.5881 0.315557 18.5881 0.24941C18.5881 0.183262 18.5629 0.119824 18.5182 0.0730503C18.4734 0.0262769 18.4128 0 18.3495 0C17.3336 0 16.7696 0.528449 16.6946 1.52009C16.2802 1.56638 15.8877 1.7379 15.5651 2.01367C15.2425 2.28945 15.0037 2.65752 14.8779 3.0729C15.6245 3.42812 16.42 2.91182 16.7494 2.56721Z"
      fill="#E73434"
    />
    <path
      d="M18.4351 3.69704C17.8769 3.67888 17.3416 3.4614 16.9182 3.08081C16.3613 3.56676 15.5372 3.871 14.786 3.57705C14.7821 3.63212 14.7711 3.68498 14.7711 3.74112C14.7711 6.43567 13.119 7.98109 10.2383 7.98109C10.1896 7.98114 10.1421 7.99677 10.1021 8.02589C10.0622 8.05502 10.0317 8.09623 10.0148 8.14399C9.99791 8.19176 9.99541 8.24379 10.0076 8.29308C10.0199 8.34238 10.0462 8.38658 10.0832 8.41975C10.9841 9.23856 12.138 9.68902 13.3318 9.68799C14.1321 9.68239 14.9217 9.49541 15.6461 9.13994C17.7231 8.14136 19.0653 6.0221 19.0653 3.74112C19.0653 3.68604 19.0545 3.63414 19.0508 3.58007C18.8539 3.65743 18.6454 3.69706 18.4351 3.69704Z"
      fill="#E73434"
    />
  </svg>
);

export const ChiliFourIcon = (
  <svg
    width="25"
    height="10"
    viewBox="0 0 25 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.74942 2.56721C6.77156 2.54404 6.79786 2.52567 6.8268 2.51313C6.85574 2.50059 6.88676 2.49414 6.91809 2.49414C6.94942 2.49414 6.98044 2.50059 7.00938 2.51313C7.03832 2.52567 7.06462 2.54404 7.08676 2.56721C7.41622 2.91164 8.21184 3.4273 8.95845 3.07273C8.83411 2.66209 8.59928 2.29758 8.28204 2.02277C7.96481 1.74795 7.57858 1.57447 7.16953 1.52304C7.23196 0.819872 7.60082 0.498819 8.34949 0.498819C8.41276 0.498819 8.47344 0.472542 8.51818 0.425769C8.56292 0.378995 8.58806 0.315557 8.58806 0.24941C8.58806 0.183262 8.56292 0.119824 8.51818 0.0730503C8.47344 0.0262769 8.41276 0 8.34949 0C7.33361 0 6.76958 0.528449 6.69461 1.52009C6.28023 1.56638 5.88772 1.7379 5.56509 2.01367C5.24246 2.28945 5.00367 2.65752 4.87793 3.0729C5.6245 3.42812 6.41999 2.91182 6.74942 2.56721Z"
      fill="#E73434"
    />
    <path
      d="M8.43508 3.69704C7.87695 3.67888 7.34161 3.4614 6.91819 3.08081C6.36129 3.56676 5.5372 3.871 4.78599 3.57705C4.78209 3.63212 4.77108 3.68498 4.77108 3.74112C4.77108 6.43567 3.11901 7.98109 0.238334 7.98109C0.189623 7.98114 0.142094 7.99677 0.102135 8.02589C0.0621755 8.05502 0.031701 8.09623 0.0148072 8.14399C-0.00208657 8.19176 -0.00459 8.24379 0.00763325 8.29308C0.0198565 8.34238 0.0462206 8.38658 0.0831823 8.41975C0.984135 9.23856 2.13799 9.68902 3.33178 9.68799C4.13206 9.68239 4.92166 9.49541 5.64614 9.13994C7.72311 8.14136 9.06528 6.0221 9.06528 3.74112C9.06528 3.68604 9.05454 3.63414 9.05077 3.58007C8.85394 3.65743 8.64537 3.69706 8.43508 3.69704Z"
      fill="#E73434"
    />
    <path
      d="M11.7494 2.56721C11.7716 2.54404 11.7979 2.52567 11.8268 2.51313C11.8557 2.50059 11.8868 2.49414 11.9181 2.49414C11.9494 2.49414 11.9804 2.50059 12.0094 2.51313C12.0383 2.52567 12.0646 2.54404 12.0868 2.56721C12.4162 2.91164 13.2118 3.4273 13.9585 3.07273C13.8341 2.66209 13.5993 2.29758 13.282 2.02277C12.9648 1.74795 12.5786 1.57447 12.1695 1.52304C12.232 0.819872 12.6008 0.498819 13.3495 0.498819C13.4128 0.498819 13.4734 0.472542 13.5182 0.425769C13.5629 0.378995 13.5881 0.315557 13.5881 0.24941C13.5881 0.183262 13.5629 0.119824 13.5182 0.0730503C13.4734 0.0262769 13.4128 0 13.3495 0C12.3336 0 11.7696 0.528449 11.6946 1.52009C11.2802 1.56638 10.8877 1.7379 10.5651 2.01367C10.2425 2.28945 10.0037 2.65752 9.87793 3.0729C10.6245 3.42812 11.42 2.91182 11.7494 2.56721Z"
      fill="#E73434"
    />
    <path
      d="M13.4351 3.69704C12.8769 3.67888 12.3416 3.4614 11.9182 3.08081C11.3613 3.56676 10.5372 3.871 9.78599 3.57705C9.78209 3.63212 9.77108 3.68498 9.77108 3.74112C9.77108 6.43567 8.11901 7.98109 5.23833 7.98109C5.18962 7.98114 5.14209 7.99677 5.10213 8.02589C5.06218 8.05502 5.0317 8.09623 5.01481 8.14399C4.99791 8.19176 4.99541 8.24379 5.00763 8.29308C5.01986 8.34238 5.04622 8.38658 5.08318 8.41975C5.98414 9.23856 7.13799 9.68902 8.33178 9.68799C9.13206 9.68239 9.92166 9.49541 10.6461 9.13994C12.7231 8.14136 14.0653 6.0221 14.0653 3.74112C14.0653 3.68604 14.0545 3.63414 14.0508 3.58007C13.8539 3.65743 13.6454 3.69706 13.4351 3.69704Z"
      fill="#E73434"
    />
    <path
      d="M16.7494 2.56721C16.7716 2.54404 16.7979 2.52567 16.8268 2.51313C16.8557 2.50059 16.8868 2.49414 16.9181 2.49414C16.9494 2.49414 16.9804 2.50059 17.0094 2.51313C17.0383 2.52567 17.0646 2.54404 17.0868 2.56721C17.4162 2.91164 18.2118 3.4273 18.9585 3.07273C18.8341 2.66209 18.5993 2.29758 18.282 2.02277C17.9648 1.74795 17.5786 1.57447 17.1695 1.52304C17.232 0.819872 17.6008 0.498819 18.3495 0.498819C18.4128 0.498819 18.4734 0.472542 18.5182 0.425769C18.5629 0.378995 18.5881 0.315557 18.5881 0.24941C18.5881 0.183262 18.5629 0.119824 18.5182 0.0730503C18.4734 0.0262769 18.4128 0 18.3495 0C17.3336 0 16.7696 0.528449 16.6946 1.52009C16.2802 1.56638 15.8877 1.7379 15.5651 2.01367C15.2425 2.28945 15.0037 2.65752 14.8779 3.0729C15.6245 3.42812 16.42 2.91182 16.7494 2.56721Z"
      fill="#E73434"
    />
    <path
      d="M18.4351 3.69704C17.8769 3.67888 17.3416 3.4614 16.9182 3.08081C16.3613 3.56676 15.5372 3.871 14.786 3.57705C14.7821 3.63212 14.7711 3.68498 14.7711 3.74112C14.7711 6.43567 13.119 7.98109 10.2383 7.98109C10.1896 7.98114 10.1421 7.99677 10.1021 8.02589C10.0622 8.05502 10.0317 8.09623 10.0148 8.14399C9.99791 8.19176 9.99541 8.24379 10.0076 8.29308C10.0199 8.34238 10.0462 8.38658 10.0832 8.41975C10.9841 9.23856 12.138 9.68902 13.3318 9.68799C14.1321 9.68239 14.9217 9.49541 15.6461 9.13994C17.7231 8.14136 19.0653 6.0221 19.0653 3.74112C19.0653 3.68604 19.0545 3.63414 19.0508 3.58007C18.8539 3.65743 18.6454 3.69706 18.4351 3.69704Z"
      fill="#E73434"
    />
    <path
      d="M21.7494 2.56721C21.7716 2.54404 21.7979 2.52567 21.8268 2.51313C21.8557 2.50059 21.8868 2.49414 21.9181 2.49414C21.9494 2.49414 21.9804 2.50059 22.0094 2.51313C22.0383 2.52567 22.0646 2.54404 22.0868 2.56721C22.4162 2.91164 23.2118 3.4273 23.9585 3.07273C23.8341 2.66209 23.5993 2.29758 23.282 2.02277C22.9648 1.74795 22.5786 1.57447 22.1695 1.52304C22.232 0.819872 22.6008 0.498819 23.3495 0.498819C23.4128 0.498819 23.4734 0.472542 23.5182 0.425769C23.5629 0.378995 23.5881 0.315557 23.5881 0.24941C23.5881 0.183262 23.5629 0.119824 23.5182 0.0730503C23.4734 0.0262769 23.4128 0 23.3495 0C22.3336 0 21.7696 0.528449 21.6946 1.52009C21.2802 1.56638 20.8877 1.7379 20.5651 2.01367C20.2425 2.28945 20.0037 2.65752 19.8779 3.0729C20.6245 3.42812 21.42 2.91182 21.7494 2.56721Z"
      fill="#E73434"
    />
    <path
      d="M23.4351 3.69704C22.8769 3.67888 22.3416 3.4614 21.9182 3.08081C21.3613 3.56676 20.5372 3.871 19.786 3.57705C19.7821 3.63212 19.7711 3.68498 19.7711 3.74112C19.7711 6.43567 18.119 7.98109 15.2383 7.98109C15.1896 7.98114 15.1421 7.99677 15.1021 8.02589C15.0622 8.05502 15.0317 8.09623 15.0148 8.14399C14.9979 8.19176 14.9954 8.24379 15.0076 8.29308C15.0199 8.34238 15.0462 8.38658 15.0832 8.41975C15.9841 9.23856 17.138 9.68902 18.3318 9.68799C19.1321 9.68239 19.9217 9.49541 20.6461 9.13994C22.7231 8.14136 24.0653 6.0221 24.0653 3.74112C24.0653 3.68604 24.0545 3.63414 24.0508 3.58007C23.8539 3.65743 23.6454 3.69706 23.4351 3.69704Z"
      fill="#E73434"
    />
  </svg>
);

export const AddButton = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.5" cy="10.5" r="10" stroke="white" />
    <path
      d="M10.4948 16.5052L10.4948 5.49475"
      stroke="white"
      strokeLinecap="round"
    />
    <path d="M4.98962 11L16.0001 11" stroke="white" strokeLinecap="round" />
  </svg>
);

export const VegetarianIcon = <span style={{ color: '#45D854' }}>V</span>;

export const VeganIcon = <span style={{ color: '#DF7DE1' }}>Vegan</span>;

export const GlutenFreeIcon = <span style={{ color: '#FFD769' }}>Gluten Free</span>;

export const HalalIcon = <span style={{ color: '#FD902F' }}>H</span>;

export const PeanutIcon = <span style={{ color: 'orange' }}>Peanut</span>;

export const NutIcon = <span style={{ color: '#FFD580' }}>Nut</span>;

export const SoyIcon = <span style={{ color: 'brown' }}>Soy</span>;

export const DairyIcon = <span style={{ color: 'white' }}>Dairy</span>;

export const SesameFreeIcon = (
  <span style={{ color: '#FFF8BC' }}>Sesame-Free</span>
);

export const SesameIcon = <span style={{ color: '#FFF8BC' }}>Sesame</span>;

export const SignatureIcon = (
  <span style={{ color: '#CBC3E3' }}>Signature</span>
);

export const PracticingOrganicIcon = (
  <span style={{ color: '#45D854' }}>Practicing Organic</span>
);

export const CertifiedOrganicIcon = (
  <span style={{ color: '#45D854' }}>Certified Organic</span>
);

export const CertifiedSustainableIcon = (
  <span style={{ color: '#45D854' }}>Certified Sustainable</span>
);

export const BiodynamicIcon = (
  <span style={{ color: '#089116' }}>Biodynamic</span>
);

export const LocallySourcedIcon = (
  <span style={{ color: '#089116' }}>Locally Sourced</span>
);

export const DairyFreeIcon = (
  <span style={{ color: '#089116' }}>Dairy Free</span>
);

export const NutsFreeIcon = (
  <span style={{ color: '#089116' }}>Nuts Free</span>
);

export const ContainsGlutenIcon = (
  <span style={{ color: '#089116' }}>Contains Gluten</span>
);

export const ContainsSeedsIcon = (
  <span style={{ color: '#089116' }}>Contains Seeds</span>
);

export const LactoseFreeIcon = (
  <span style={{ color: '#089116' }}>Lactose Free</span>
);

export const CircleIcon = (params: {
  [k: string]: any;
}): React.ReactElement => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    stroke="white"
    fill="none"
    style={{ height: '100%' }}
    {...params}
  >
    <circle cx="10.5" cy="10.5" r="10" />
  </svg>
);

export const PlusCircleIcon = (params: {
  [k: string]: any;
}): React.ReactElement => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="nonzero"
    clipRule="evenodd"
    style={{ height: '100%' }}
    fill="none"
    stroke="white"
    {...params}
  >
    <circle cx="10.5" cy="10.5" r="10" />
    <path d="M10.4946 16.5052L10.4946 5.49475" strokeLinecap="round" />
    <path d="M4.98975 11L16.0002 11" strokeLinecap="round" />
  </svg>
);

export const MinusCircleIcon = (params: {
  [k: string]: any;
}): React.ReactElement => (
  <svg
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="nonzero"
    clipRule="evenodd"
    style={{ height: '100%' }}
    fill="none"
    stroke="white"
    {...params}
  >
    <circle cx="10.5" cy="10.5" r="10" />
    <path d="M4.98975 11L16.0002 11" strokeLinecap="round" />
  </svg>
);

export const ActiveMinusCircleIcon = (params: {
  [k: string]: any;
}): React.ReactElement => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...params}
  >
    <circle cx="10.5" cy="10.5" r="10.5" fill="#EADECC" />
    <path d="M4.98975 11L16.0002 11" stroke="#282828" strokeLinecap="round" />
  </svg>
);

export const ConfirmationIcon = (
  <svg
    width="65"
    height="65"
    viewBox="0 0 65 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32.5" cy="32.5" r="32.5" fill="#E9C795" />
    <path
      d="M26 32.6552L29.85 39L40 27"
      stroke="#282828"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditIcon = (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.11182 11.7972L12.6557 1.7761C13.0366 1.37617 13.6542 1.37617 14.035 1.7761L15.0989 2.89318C15.4798 3.29311 15.4798 3.94152 15.0989 4.34145L5.55501 14.3626L3.11182 11.7972Z"
      stroke="#E9C795"
    />
    <path
      d="M3.88472 15.3002L2.12149 15.4025L2.21891 13.5511L3.88472 15.3002Z"
      stroke="#E9C795"
    />
  </svg>
);

export const TrashIcon = (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="45" height="45" rx="22.5" fill="#282828" />
    <path
      d="M12.375 15.75H14.625H32.625"
      stroke="#E0DFDF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 15.75V13.5C18 12.9033 18.2371 12.331 18.659 11.909C19.081 11.4871 19.6533 11.25 20.25 11.25H24.75C25.3467 11.25 25.919 11.4871 26.341 11.909C26.7629 12.331 27 12.9033 27 13.5V15.75M30.375 15.75V31.5C30.375 32.0967 30.1379 32.669 29.716 33.091C29.294 33.5129 28.7217 33.75 28.125 33.75H16.875C16.2783 33.75 15.706 33.5129 15.284 33.091C14.8621 32.669 14.625 32.0967 14.625 31.5V15.75H30.375Z"
      stroke="#E0DFDF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.25 21.375V28.125"
      stroke="#E0DFDF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.75 21.375V28.125"
      stroke="#E0DFDF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const doubleLeftArrow = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 17L6 12L11 7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 17L13 12L18 7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const doubleRightArrow = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 17L18 12L13 7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 17L11 12L6 7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ListIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/list">
      <rect
        id="Rectangle"
        x="1.83325"
        y="1.8335"
        width="18.3333"
        height="18.3333"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        id="Path 2"
        d="M10.0833 6.41667H15.5833"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Path 2_2"
        d="M10.0833 11.0002H15.5833"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Path 2_3"
        d="M10.0833 15.5832H15.5833"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Oval"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41667 7.33333C6.92293 7.33333 7.33333 6.92293 7.33333 6.41667C7.33333 5.91041 6.92293 5.5 6.41667 5.5C5.91041 5.5 5.5 5.91041 5.5 6.41667C5.5 6.92293 5.91041 7.33333 6.41667 7.33333Z"
        fill="white"
      />
      <path
        id="Oval_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41667 11.9168C6.92293 11.9168 7.33333 11.5064 7.33333 11.0002C7.33333 10.4939 6.92293 10.0835 6.41667 10.0835C5.91041 10.0835 5.5 10.4939 5.5 11.0002C5.5 11.5064 5.91041 11.9168 6.41667 11.9168Z"
        fill="white"
      />
      <path
        id="Oval_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41667 16.4998C6.92293 16.4998 7.33333 16.0894 7.33333 15.5832C7.33333 15.0769 6.92293 14.6665 6.41667 14.6665C5.91041 14.6665 5.5 15.0769 5.5 15.5832C5.5 16.0894 5.91041 16.4998 6.41667 16.4998Z"
        fill="white"
      />
    </g>
  </svg>
);

export const ListIconBlack = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon/list">
      <rect
        id="Rectangle"
        x="1.83325"
        y="1.8335"
        width="18.3333"
        height="18.3333"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        id="Path 2"
        d="M10.0833 6.41667H15.5833"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Path 2_2"
        d="M10.0833 11.0002H15.5833"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Path 2_3"
        d="M10.0833 15.5832H15.5833"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Oval"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41667 7.33333C6.92293 7.33333 7.33333 6.92293 7.33333 6.41667C7.33333 5.91041 6.92293 5.5 6.41667 5.5C5.91041 5.5 5.5 5.91041 5.5 6.41667C5.5 6.92293 5.91041 7.33333 6.41667 7.33333Z"
        fill="black"
      />
      <path
        id="Oval_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41667 11.9168C6.92293 11.9168 7.33333 11.5064 7.33333 11.0002C7.33333 10.4939 6.92293 10.0835 6.41667 10.0835C5.91041 10.0835 5.5 10.4939 5.5 11.0002C5.5 11.5064 5.91041 11.9168 6.41667 11.9168Z"
        fill="black"
      />
      <path
        id="Oval_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41667 16.4998C6.92293 16.4998 7.33333 16.0894 7.33333 15.5832C7.33333 15.0769 6.92293 14.6665 6.41667 14.6665C5.91041 14.6665 5.5 15.0769 5.5 15.5832C5.5 16.0894 5.91041 16.4998 6.41667 16.4998Z"
        fill="black"
      />
    </g>
  </svg>
);

export const NoItemsIcon = (
  <svg
    width="140"
    height="123"
    viewBox="0 0 140 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.476 113.659L94.0528 105.968L114.049 65.8666L125.606 62.0002L129.473 73.5575L109.476 113.659Z"
      fill="#818181"
    />
    <rect
      x="26.084"
      y="19.082"
      width="80.8333"
      height="80.8333"
      stroke="#818181"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M54.4661 85.8175V85.8175C61.5265 80.0666 71.6556 80.0666 78.7161 85.8175V85.8175"
      stroke="#818181"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M54.4661 55.1545H78.7161"
      stroke="#818181"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M54.4661 43.5168H78.7161"
      stroke="#818181"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M54.4661 31.8161H78.7161"
      stroke="#818181"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.6508 81.805C46.883 81.805 48.6925 79.9955 48.6925 77.7633C48.6925 75.5312 46.883 73.7217 44.6508 73.7217C42.4186 73.7217 40.6091 75.5312 40.6091 77.7633C40.6091 79.9955 42.4186 81.805 44.6508 81.805Z"
      fill="#818181"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.4101 81.805C90.6422 81.805 92.4517 79.9955 92.4517 77.7633C92.4517 75.5312 90.6422 73.7217 88.4101 73.7217C86.1779 73.7217 84.3684 75.5312 84.3684 77.7633C84.3684 79.9955 86.1779 81.805 88.4101 81.805Z"
      fill="#818181"
    />
    <circle opacity="0.3" cx="5.5" cy="45.5" r="5.5" fill="#818181" />
    <circle opacity="0.3" cx="120.5" cy="5.5" r="5.5" fill="#818181" />
    <circle opacity="0.3" cx="73.5" cy="117.5" r="5.5" fill="#818181" />
  </svg>
);

export const ErrorIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 6V9.75" stroke="#FF6666" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12.75C9.41421 12.75 9.75 12.4142 9.75 12C9.75 11.5858 9.41421 11.25 9 11.25C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75Z"
      fill="#FF6666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
      stroke="#FF6666"
    />
  </svg>
);

export const FilterIcon = (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.37484 3.36719L7.9165 3.36719"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0833 3.36719L16.625 3.36719"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.375 9.18359L9.5 9.18359"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6667 9.18359L16.625 9.18359"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.37516 15.0039L6.3335 15.0039"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 15.0039L16.625 15.0039"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9165 1.18359L7.9165 5.54723"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6665 7.00391L12.6665 11.3675"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.3335 12.8203L6.3335 17.1839"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const GridViewIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
  </svg>
);

export const CheckCircle = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 13L14.1666 20L10.5 16.5"
      stroke="#E9C795"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      stroke="#E9C795"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
