import { useEffect } from 'react';

export function usePwaIcon(sizes: string, src?: string | false | null) {
  useEffect(() => {
    if (!src) {
      return;
    }

    const removedLinks: Element[] = [];

    for (const link of [
      ...document.querySelectorAll(
        'link[rel="apple-touch-icon"], link[rel="icon"]',
      ),
    ]) {
      link.remove();
      removedLinks.push(link);
    }

    const links: HTMLLinkElement[] = [];

    {
      const link = document.createElement('link');
      link.setAttribute('rel', 'apple-touch-icon');
      link.setAttribute('sizes', sizes);
      link.setAttribute('href', src);
      document.head.appendChild(link);
      links.push(link);
    }

    {
      const link = document.createElement('link');
      link.setAttribute('rel', 'icon');
      link.setAttribute('sizes', sizes);
      link.setAttribute('href', src);
      document.head.appendChild(link);
      links.push(link);
    }

    return () => {
      for (const link of links) {
        link.remove();
      }
      for (const link of removedLinks) {
        document.head.appendChild(link);
      }
    };
  }, [src]);
}
