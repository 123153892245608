import React, { useState, useEffect, useCallback, useContext } from 'react';
import cx from 'clsx';
import ReactGA from 'react-ga4';
import * as R from 'ramda';
import { Button } from 'react-bootstrap';
import { Item } from '../models/menu.model';
import ItemImageCarousel from '../components/ItemImageCarousel.component';
import ItemSummary from '../components/ItemSummary.component';
import { store, State } from '../state/store';
import { buildAddItem } from '../state/selectedItemList/actionCreators.selectedItemList';
import { dispatch } from '../state/store';
import './MenuItem.component.css';
import { buildSelectedOptions } from '../utils/selection.utils';
import { useMaybeMenufy } from '../integrations/UseIntegrations';
import { useSelector } from 'react-redux';
import { SelectedItem } from '../models/selection.model';
import { Tags } from './Tags.component';
import MediaQuery from 'react-responsive';
import { training } from '../App';

const reduxDispatchItem = R.compose(dispatch, buildAddItem);

function MenuItem({
  item,
  onClick,
  highlighted,
  onConfirm,
  small = false,
}: {
  item: Item;
  highlighted: boolean;
  onClick: (item: Item, event: React.MouseEvent) => unknown;
  onConfirm: (name: string, message: string) => unknown;
  small?: boolean;
}): React.ReactElement {
  const [dispatchItem] = useMaybeMenufy(reduxDispatchItem);
  const [displayDescription, setDisplayDescription] = useState(true);
  const isTraining = useContext(training);
  const isSmall = small && !item.isHighlighted;

  const order = useSelector((state: State) => state.selectedItemList.order);

  function handleClick(event: React.MouseEvent) {
    onClick(item, event);
  }

  function handleAddToOrder() {
    ReactGA.event({
      category: 'Order',
      action: 'added',
      label: item.name,
      value: 1,
    });
    dispatchItem(item, [], 1);
    onConfirm(item.name, 'Successfully added to your list.');
  }

  const handleShowDescription = useCallback(
    (event: React.MouseEvent) => {
      setDisplayDescription(!displayDescription);
      event.stopPropagation();
    },
    [displayDescription],
  );

  // eslint-disable-next-line no-constant-condition
  if (isSmall) {
    const dietaryTags = item.tags.filter(
      (filteredTag) => filteredTag.label === 'dietary',
    );
    const allergyTags = item.tags.filter(
      (filteredTag) => filteredTag.label === 'allergy',
    );
    const specialTags = item.specialTags || undefined;
    return (
      <div className={cx('menu-item', { highlighted })} onClick={handleClick}>
        <div className="item-card-small">
          <div className="item-summary-small">
            <div className="name">{item.name}</div>
            <div className="description">{item.description}</div>
            <div className="row">
              {item.price && (
                <div className="price">${(item.price / 100).toFixed(2)}</div>
              )}
              <Tags tags={dietaryTags} />
              <Tags tags={allergyTags} />
              <Tags labels={specialTags} />
            </div>
          </div>
          {item.images.length && (
            <ItemImageCarousel
              images={item.images}
              onClick={handleClick}
              clickToScroll={item.images.length > 1}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={cx('menu-item', { highlighted })} onClick={handleClick}>
      {item.images.length ? (
        <div className="item-card">
          <ItemImageCarousel
            images={item.images}
            onClick={handleClick}
            clickToScroll={item.images.length > 1}
          />
          {isTraining ? (
            <ItemSummary item={item} displayContent={displayDescription} />
          ) : (
            <ItemSummary item={item} displayContent={true} />
          )}
        </div>
      ) : (
        <div className="item-card no-image" onClick={handleClick}>
          {isTraining ? (
            <ItemSummary item={item} displayContent={displayDescription} />
          ) : (
            <ItemSummary item={item} displayContent={true} />
          )}
        </div>
      )}
      <MediaQuery maxWidth={1040}>
        {item.options.length ? (
          <div className={isTraining ? 'action-trainging' : 'action'}>
            <Button
              className="customize-item-button"
              variant="dark"
              onClick={handleClick}
            >
              Customize
            </Button>
            {isTraining ? (
              <Button
                className="description-button"
                variant="dark"
                onClick={handleShowDescription}
              >
                {!displayDescription ? 'Show Info' : 'Hide Info'}
              </Button>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className={isTraining ? 'action-trainging' : 'action'}>
            <Button
              className="customize-item-button"
              variant="dark"
              onClick={handleClick}
            >
              Customize
            </Button>
            {isTraining ? (
              <Button
                className="description-button"
                variant="dark"
                onClick={handleShowDescription}
              >
                {!displayDescription ? 'Show Info' : 'Hide Info'}
              </Button>
            ) : (
              ''
            )}
          </div>
        )}
      </MediaQuery>
      
      {/* <MediaQuery minWidth={481}>
        {item.images.length ? (
          <div className="item-card">
            <ItemImageCarousel
              images={item.images}
              onClick={handleClick}
              clickToScroll={item.images.length > 1}
            />
            <ItemSummary item={item} truncated />
          </div>
        ) : (
          <div className="item-card no-image" onClick={handleClick}>
            <ItemSummary item={item} truncated />
          </div>
        )}
        <div className="action">
          {item.options.length ? (
            <></>
          ) : (
            <></>
          )}
        </div>
      </MediaQuery> */}
    </div>
  );
}

export default MenuItem;
