import { parse, getSubdomain } from 'tldts';

export function getLocationSubDomain(): string {
  if (process.env.NODE_ENV !== 'production') {
    return (
      getSubdomain(window.location.hostname, { validHosts: ['localhost'] }) ||
      'sunandmoon'
    );
  }
  return getSubdomain(window.location.hostname) || '';
}
