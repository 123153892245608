import { groupBy } from 'ramda';
import { SelectedItem, SelectedOption } from '../models/selection.model';
import { Item } from '../models/menu.model';
import { v4 as uuid } from 'uuid';

export const groupSelectedItemByItem = groupBy(
  <T extends SelectedItem>(selItem: T) => selItem.item.name,
);

export function buildSelectedOptions(
  item: Item,
  optionValueTable: number[][] = [],
): SelectedOption[] {
  const options: SelectedOption[] = [];
  optionValueTable.forEach((valueTable, i) => {
    const option: SelectedOption = { option: item.options[i], values: [] };
    valueTable.forEach((valueCount, j) => {
      if (valueCount <= 0) {
        return;
      }
      option.values.push({
        value: item.options[i].values[j],
        count: valueCount,
      });
    });
    if (option.values.length > 0) {
      options.push(option);
    }
  });
  return options;
}

export function buildSelectedItem(
  item: Item,
  optionValueTable: number[][] = [],
  numberOfItems: number,
): SelectedItem {
  const quantity: number = numberOfItems;
  const options: SelectedOption[] = buildSelectedOptions(
    item,
    optionValueTable,
  );

  return {
    id: uuid(),
    item,
    options,
    quantity,
  };
}

export function updateSelectedItem(
  selItem: SelectedItem,
  optionValueTable: number[][] = [],
  numberOfItems: number,
): SelectedItem {
  const quantity: number = numberOfItems;
  const options: SelectedOption[] = buildSelectedOptions(
    selItem.item,
    optionValueTable,
  );

  return {
    id: selItem.id,
    item: selItem.item,
    options,
    quantity,
  };
}
