import { cx, css } from '@emotion/css';
import { useState } from 'react';

export function Title({
  title,
  variant,
  left,
  textColor,
}: {
  title: string;
  variant?: string;
  left?: boolean;
  textColor?: string;
}) {
  const [maxWordLength, setMaxWord] = useState(0);
  title.split(' ').forEach((word) => {
    if (word.length > maxWordLength) {
      setMaxWord(word.length);
    }
  });
  return (
    <div
      className={cx(
        'nom-title',
        css`
          padding: 8px;
          color: ${textColor ? textColor : '#fff'};
          text-transform: uppercase;
          font: 35px var(--font-sans);
          ${left ? 'text-align: left !important;' : null}
          ${maxWordLength > 10 ? 'font-size: 30px;' : null}
        `,
      )}
    >
      {title}
    </div>
  );
}
