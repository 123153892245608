import { css } from '@emotion/css';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import * as M from '../models/menu.model';
import { customAnalyticsEvent } from '../utils/analytics.utils';

type PromotionType = {
  title: string;
  titleLocalized?: string;
  description: string;
  descriptionLocalized?: string;
  link: string;
  itemId: string;
  restaurant: M.Restaurant;
};

export function Promotion({
  title,
  titleLocalized,
  description,
  descriptionLocalized,
  link,
  itemId,
  restaurant,
}: PromotionType): JSX.Element {
  const navigate = useNavigate();

  const handleItemClick = useCallback(() => {
    // Custom analytics
    const eventData = {
      category: 'Promotions',
      action: 'Promotion Clicked',
      label: title,
      time: new Date().toUTCString(),
      id: itemId,
    };
    customAnalyticsEvent(eventData);
    
    if (link) {
      navigate(link);
    } else if (itemId) {
      // TODO navigate to itemId
    }
  }, [navigate, link, itemId]);

  function convertTextToJSX(text: string) {
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const parts = [];

    let lastIndex = 0;
    let match;

    // Iterate over all matches
    while ((match = regex.exec(text)) !== null) {
      // Push the plain text up to the match
      parts.push(text.substring(lastIndex, match.index));

      // eslint-disable-next-line
      parts.push(<a target="_blank" rel="noopener" key={match.index} href={match[2]}>
          {match[1]}
        </a>,
      );

      // Update the lastIndex to the end of the current match
      lastIndex = match.index + match[0].length;
    }

    // Push the remaining plain text after the last match
    parts.push(text.substring(lastIndex));

    return <>{parts}</>;
  }

  return (
    <div
      onClick={handleItemClick}
      className={css`
        max-width: 90%;
        text-align: left;
        margin: 0 auto;
        cursor: pointer;
        margin-bottom: 15px;
      `}
    >
      <div
        className={css`
          max-width: 100%;
          text-align: left;
          padding: 9px 20px;
          cursor: pointer;
          background-color: ${restaurant?.settings?.promotionBannerColor ||
          '#f7f6f4'};
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            className={css`
              text-transform: uppercase;
              font: 16px var(--font-sans);
              color: ${restaurant?.settings?.promotionTextColor || '#829995'};
            `}
          >
            {titleLocalized || title}
          </div>
          <div
            className={css`
              font: 13px var(--font-serif);
              color: ${restaurant?.settings?.promotionTextColor || '#3d3935'};
            `}
          >
            {convertTextToJSX(descriptionLocalized || description || '')}
          </div>
        </div>
        {link && (
          <Button
            className={css`
              max-width: 60px;
              max-height: 60px;
              margin-left: auto;
              margin-right: 0;
              margin: 4px;
              padding-left: 5px;
              padding-right: 5px;
              min-width: 60px;
              background-color: ${restaurant?.settings?.promotionCTAColor ||
              '#829995'};
              color: ${restaurant?.settings?.promotionCTATextColor ||
              '#f7f6f4'};
              text-transform: uppercase;
            `}
            variant="light"
            size="sm"
          >
            Learn More
          </Button>
        )}
      </div>
    </div>
  );
}

export default Promotion;
