import React from 'react';
import { css } from '@emotion/css';

import { Tab } from '../components/Tab.component';
import { Menu, Section, Item, Theme } from '../models/menu.model';

const container = css`
  height: 64px;
  padding: 16px 230px;
  align-items: center;
  display: flex;
  background-color: var(--main-bg-color);
  gap: 64px;
`;

export const MainNavigation: React.FC<{ menu: Menu }> = ({ menu }) => {
  const info = menu.info || {};

  if (
    !info.about &&
    !info.reservations &&
    !info.contact &&
    !info.order &&
    !info.hours
  ) {
    return <></>;
  }

  return (
    <div className={container}>
      <Tab to="/explore" text="Our Menu" />
      {info.about && <Tab to="/about" text="About Us" />}
      {info.reservations && <Tab to={info.reservations} text="Reservations" />}
      {info.contact && <Tab to={{ search: 'contact=1' }} text="Contact" />}
      {info.order && <Tab to={info.order} text="Order Online" />}
      {info.hours && <Tab to={{ search: 'hours=1' }} text="Hours" />}
    </div>
  );
};
