import { useEffect, useMemo, useState } from 'react';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import FullBleed from './FullBleed';
import ListItem from './ListItem';
import SectionHeader from './SectionHeader';
import * as M from '../models/menu.model';
import { css } from '@emotion/css';
import Markdown from './Markdown';
import KipsuForm from './KipsuForm';
import KipsuButton from './KipsuButton';
import { State } from '../state/store';
import { useSelector } from 'react-redux';
import { getLocalizedValue } from '../utils/translate.utils';

/**
 * Example: https://www.figma.com/file/HFdZpzKn7MSGPhx2pBtVRF/Nom-Waldorf?node-id=929%3A24684&t=GLGArAUzSWebGayf-4
 */
export function SectionInline({
  restaurant,
  section,
  sectionPathAry,
  navParent,
}: {
  restaurant: M.Restaurant;
  section: G.SectionV2;
  sectionPathAry: G.SectionV2[];
  navParent?: string;
}): JSX.Element {
  const [modalVis, setModalVis] = useState(false);

  const path = useMemo(
    () => sectionPathAry.map((s) => s.id).join('/'),
    [sectionPathAry],
  );

  const childSections = useMemo(
    () =>
      section.childSectionIds
        ?.map((sectionId) => restaurant.sectionById[sectionId])
        .filter((s) => !s.disabled) || [],
    [section],
  );

  const items = useMemo(
    () => section.itemIds?.map((itemId) => restaurant.itemById[itemId]) || [],
    [section],
  );

  const selectedLanguage = useSelector(
    (state: State) => state.language.language,
  );

  // Form stuff
  const [isForm, setForm] = useState(false);
  const [formData, setFormData] = useState<Record<string, string>>({})
  const [emailBody, setEmail] = useState('');

  const CHOICES = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th'];

  const updateForm = (field: string, data: string) => {
    const newForm = formData;
    newForm[field] = data;
    setFormData(newForm);

    console.log('formData state object:', formData, Object.keys(formData));

    let output = '';
    Object.keys(formData).map(key => {
      output += `${key}: ${formData[key]}, %0A` 
    });
    setEmail(output);

    console.log('new email, ', output);
  }

  const inRoomDining = useMemo(
    () =>
      sectionPathAry[sectionPathAry.length - 1]?.name === 'In Room Dining Menu',
    [sectionPathAry],
  );

  useEffect(() => {
    setForm(window.location.href.includes('b2403490-cfb0-11ee-a4a6-b32723d253c2'));
  }, [])

  const sectionHeaderText = getLocalizedValue(
    section,
    'namesLocalized',
    selectedLanguage,
    section.name ?? '',
  );
  const sectionDescription = getLocalizedValue(
    section,
    'descriptionsLocalized',
    selectedLanguage,
    section.description ?? '',
  );

  return (
    <>
      {modalVis ? (
        <div
          className={css`
            width: 100vw;
            height: 100vh;
            position: fixed;
            left: 0;
            z-index: 10;
            bottom: 0;
            background: #00000094;
          `}
          onClick={() => setModalVis(!modalVis)}
        ></div>
      ) : null}
      {section.name === 'Spanish' || section.name === 'English' ? (
        <>{/* Don't display a header. */}</>
      ) : childSections.length > 0 ? (
        <>
          {/* Show full-bleed header and blue description. */}
          {(section.images && section.images.length > 0) || section.name ? (
            <FullBleed
              title={section.name === 'Spanish' ? 'Español' : sectionHeaderText}
              variant="framed"
              bgSrc={
                section.images && section.images.length > 0
                  ? section.images[0]
                  : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADAQMAAACOOjyFAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAHVaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJYTVAgQ29yZSA1LjQuMCI+CiAgIDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+CiAgICAgIDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiCiAgICAgICAgICAgIHhtbG5zOnRpZmY9Imh0dHA6Ly9ucy5hZG9iZS5jb20vdGlmZi8xLjAvIj4KICAgICAgICAgPHRpZmY6Q29tcHJlc3Npb24+MTwvdGlmZjpDb21wcmVzc2lvbj4KICAgICAgICAgPHRpZmY6T3JpZW50YXRpb24+MTwvdGlmZjpPcmllbnRhdGlvbj4KICAgICAgICAgPHRpZmY6UGhvdG9tZXRyaWNJbnRlcnByZXRhdGlvbj4yPC90aWZmOlBob3RvbWV0cmljSW50ZXJwcmV0YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgoC2IAFAAAAA1BMVEVHcEyC+tLSAAAAAXRSTlMAQObYZgAAAApJREFUCB1jAAMAAAYAAcXjo3IAAAAASUVORK5CYII='
              }
              restaurant={restaurant}
            />
          ) : null}
          {section.description ? (
            <Markdown
              className={css`
                font: 17px var(--font-sans);
                text-align: left;
                padding: 20px;
                background-color: ${restaurant.settings?.mainColor || '#000'};
                color: ${restaurant.settings?.sectionHeaderTextColor};
              `}
            >
              {sectionDescription}
            </Markdown>
          ) : null}
        </>
      ) : (
        <>
          {/* Show blue header and white description. */}
          <SectionHeader
            headerText={
              section.name === 'Spanish' ? 'Español' : sectionHeaderText
            }
            restaurant={restaurant}
          />
          {section.description ? (
            <Markdown
              className={css`
                font: 17px var(--font-sans);
                text-align: left;
                padding: 20px;

                /*
                 *  If we have a white description followed by a white item list,
                 * we don't want to the 10px space in between.
                 */
                margin-bottom: -10px;
              `}
            >
              {sectionDescription}
            </Markdown>
          ) : null}
        </>
      )}

      {/* KIPSU FORM */}
      {modalVis &&
        restaurant.settings?.kipsuNumber &&
        section.hasSectionCTAButton ? (
        <KipsuForm
          requestedItem={section.name}
          settings={restaurant.settings!}
          mainColor={restaurant.settings?.mainColor || ''}
          isInRoomDining={inRoomDining}
          sectionById={restaurant.sectionById}
        />
      ) : null}

      {/* KIPSU BUTTON */}
      {restaurant.settings?.kipsuNumber && section.hasSectionCTAButton ? (
        <button
          className={css`
            ${'background-color: ' +
            (restaurant?.settings?.mainColor
              ? restaurant.settings.mainColor
              : '#032165') +
            ';'}
            padding: 20px 30px;
            cursor: pointer;
            margin-top: 32px;
            text-transform: uppercase;
            text-align: center;
            text-decoration: none;
            color: #ffffff !important;
            font: 15px var(--font-sans);
            display: inline-flex;
            width: 100%;
            margin-top: 0;
            display: flex;
            justify-content: center;
            padding-right: 0;
            padding-left: 0;
          `}
          onClick={() => setModalVis(!modalVis)}
        >
          {inRoomDining ? 'COLLECT MY TRAY' : 'MAKE RESERVATION'}
        </button>
      ) : null}

      {items.map((item, i) => {
        const tagValues: string[] = [];
        item.tagIds.forEach((tagId) => {
          const tagObj = restaurant?.tags?.find((tag) => tag.id == tagId);
          if (tagObj) {
            tagValues.push(tagObj.value);
          }
        });

        const itemTitle = getLocalizedValue(
          item,
          'namesLocalized',
          selectedLanguage,
          item.name,
        );
        const itemDescription = getLocalizedValue(
          item,
          'descriptionsLocalized',
          selectedLanguage,
          item.description ?? '',
        );

        return (
          <>
            {item.inStock ? (
              <>
                {i === 0 ? (
                  <div
                    className={css`
                      height: 10px;
                      width: 100%;
                    `}
                  ></div>
                ) : null}
                {isForm && item.name == 'Requested Dates' ?
                  <>
                    <div className={css`
                    background: ${(restaurant?.settings?.mainColor || '#032165')};
                    padding: 20px 30px;
                    text-transform: uppercase;
                    text-align: center;
                    margin-top: 15px;
                    color: ${restaurant?.settings?.sectionHeaderTextColor || '#fff'};
                    font: 17px var(--font-sans);
                  `}>Requested Dates</div>
                    {CHOICES.map(choice =>
                      <div className={css``} key={choice}>
                        <div className={css`
                        font-size: 18px;
                        padding: 10px;
                      `}>
                          {choice} choice
                        </div>
                        <div className={css`
                        border-bottom: 1px solid lightgrey;
                        margin: 15px;
                        margin-top: 0px;
                        padding-bottom: 25px;
                      `}>
                          Arrival Date<br />
                          <input type="text"
                            className={css`
                          margin: 0 10px;
                          padding: 5px;
                          width: 300px;
                        `}
                            onChange={(e) => updateForm(choice + ' Choice Arrival', e.target.value)}
                          /><br />
                          Departure Date<br />
                          <input type="text"
                            className={css`
                          margin: 0 10px;
                          padding: 5px;
                          width: 300px;
                        `}
                            onChange={(e) => updateForm(choice + ' Choice Departure', e.target.value)}
                          /><br />
                          Residence # Request<br />
                          <input type="text"
                            className={css`
                          margin: 0 10px;
                          padding: 5px;
                          width: 300px;
                        `}
                            onChange={(e) => updateForm(choice + ' Choice Residence # Request', e.target.value)}
                          />
                        </div>
                      </div>)}

                    <div className={css`
                      display: flex;
                      justify-content: center;
                      width: 100%;
                    `}>
                      <KipsuButton
                        requestedItem="N/A"
                        settings={restaurant.settings!}
                        sectionById={(restaurant as M.Restaurant).sectionById}
                        formBtnUrl={"mailto:vikas@nommenu.com?subject=Ownership%20Request%20Form&body="+emailBody} />
                    </div>
                  </>
                  :
                  <>
                    <ListItem
                      key={item.id}
                      restaurant={restaurant}
                      id={item.id}
                      colorScheme="light"
                      to={'/' + path + '/' + item.id}
                      title={item.name}
                      titleLocalized={itemTitle}
                      description={itemDescription || ''}
                      price={item.price?.toString() || ''}
                      section={section.name}
                      expanded={section.type === 'expanded'}
                      tags={tagValues}
                    />
                    {isForm ?
                      <input type="text"
                        className={css`
                          margin: 0 10px;
                          padding: 5px;
                          width: 300px;
                        `}
                        onChange={(e) => updateForm(item.name, e.target.value)}
                      /> : null}
                  </>}

                {i === items.length - 1 ? (
                  <div
                    className={css`
                      height: 10px;
                      width: 100%;
                    `}
                  ></div>
                ) : null}
              </>
            ) : null}
          </>
        );
      })}
      {childSections.map((childSection) => {
        const childSectionTitle = getLocalizedValue(
          childSection,
          'namesLocalized',
          selectedLanguage,
          childSection.name ?? '',
        );
        const childSectionDescription = getLocalizedValue(
          childSection,
          'descriptionsLocalized',
          selectedLanguage,
          childSection.description ?? '',
        );

        if (
          childSection.childSectionIds &&
          childSection.childSectionIds.length > 0
        ) {
          return (
            <ListItem
              key={childSection.id}
              restaurant={restaurant}
              image={childSection.images ? childSection.images[0] : ''}
              id={childSection.id}
              description={childSectionDescription || ''}
              type="vertical"
              colorScheme="light"
              to={
                '/' +
                path +
                '/' +
                (navParent ? navParent + '/' : '') +
                childSection.id
              }
              title={childSection.name}
              titleLocalized={childSectionTitle}
              expanded={section.type === 'expanded'}
              section={childSection.name}
            />
          );
        } else {
          return (
            <SectionInline
              key={childSection.id}
              restaurant={restaurant}
              section={childSection}
              sectionPathAry={sectionPathAry}
            />
          );
        }
      })}
    </>
  );
}
