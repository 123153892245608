import React from 'react';
import { Item, Tag } from '../models/menu.model';
import { filterByValue } from '../utils/filter.utils';
import './ItemSummary.component.css';
import { Tags } from './Tags.component';
interface ItemSummaryParams {
  item: Item;
  displayContent?: boolean;
  truncated?: boolean;
  onClick?: () => void;
}

function ItemSummary(params: ItemSummaryParams): React.ReactElement {
  const { item, truncated, displayContent, onClick } = params;
  const dietaryTags = item.tags.filter(
    (filteredTag) => filteredTag.label === 'dietary',
  );
  const allergyTags = item.tags.filter(
    (filteredTag) => filteredTag.label === 'allergy',
  );
  const specialTags = item.specialTags || undefined;
  return (
    <div className="item-summary" onClick={onClick}>
      <div className="name-tags-price">
        {displayContent ?
        <div className="name-tags">
          <div className="name">{item.name}</div>
          <Tags tags={dietaryTags} />
          <Tags tags={allergyTags} />
          <Tags labels={specialTags} /> 
        </div> : 
        <div className="name-tags">
          <div className="name">{item.name}</div>
          </div>}
        {displayContent ?
        (!!item.price ? (
          <div className="price">${(item.price / 100).toFixed(2)}</div>
        ) : (
          !!item.options[0]?.values[0]?.price && (
            <div className="price">
              $ {(item.options[0].values[0].price / 100).toFixed(2)}
            </div>
          )
        )) : ""}
      </div>
      {item.description && displayContent? (
        truncated ? (  
          <div className="description">
            <div className="overflow">{item.description}</div>
          </div>
        ) : (
          <div className="description">{item.description}</div>
        )
      ) : (
        // If no description on menu view, this will prevent the "add to list" button from being misaligned.
        truncated && (
          <div className="description">
            <div className="overflow">&nbsp;</div>
          </div>
        )
      )}
    </div>
  );
}
export default ItemSummary;
