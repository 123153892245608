export const PinterestIcon: React.FC<{
  color?: string;
  className?: string;
}> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      className={props.className}
    >
      <path
        d="M10.5149 0C4.72152 0 0.0253906 4.69613 0.0253906 10.4886C0.0253906 14.9328 2.78864 18.7285 6.69114 20.2554C6.59927 19.425 6.51702 18.1528 6.72702 17.2463C6.91864 16.4264 7.95727 12.0339 7.95727 12.0339C7.95727 12.0339 7.64314 11.4039 7.64314 10.4755C7.64314 9.02038 8.48927 7.92837 9.54014 7.92837C10.4361 7.92837 10.8684 8.60125 10.8684 9.40537C10.8684 10.3057 10.297 11.6515 10.0004 12.8984C9.75102 13.9423 10.5254 14.7928 11.5535 14.7928C13.4155 14.7928 14.8505 12.8284 14.8505 9.99162C14.8505 7.48825 13.0454 5.73125 10.4685 5.73125C7.48477 5.73125 5.73564 7.973 5.73564 10.2804C5.73564 11.1842 6.08039 12.1555 6.51352 12.6788C6.60014 12.7837 6.61152 12.8756 6.58789 12.9806C6.50914 13.3088 6.33152 14.0297 6.29564 14.1733C6.24927 14.3701 6.14514 14.4104 5.94477 14.3176C4.63664 13.7139 3.81589 11.7994 3.81589 10.2524C3.81589 6.94837 6.22039 3.90688 10.7459 3.90688C14.3841 3.90688 17.2139 6.503 17.2139 9.9645C17.2139 13.5826 14.9328 16.4937 11.76 16.4937C10.6978 16.4937 9.70027 15.9434 9.34677 15.2871L8.69139 17.7791C8.45602 18.6935 7.81289 19.8371 7.38064 20.5319C8.36327 20.8337 9.39839 21 10.4869 21C16.268 21 20.9738 16.3056 20.9738 10.5114C20.9738 4.71625 16.268 0.02275 10.4869 0.02275L10.5149 0Z"
        fill={props.color}
      />
    </svg>
  );
};
