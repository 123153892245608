import { css, cx } from '@emotion/css';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

const Markdown: React.FC<{
  children: string;
  className?: string;
}> = (props) => {
  return (
    <ReactMarkdown
      className={cx(
        css`
          a {
            text-decoration: underline;
          }
          p {
            margin: 0;
          }
          * + p {
            margin-top: 0.5em;
          }
          ul {
            margin: 0 0 0 0.1em;
            padding: 0 0 0 1em;
            list-style-type: square;
          }
        `,
        props.className,
      )}
      linkTarget="_blank"
      remarkPlugins={[remarkBreaks]}
    >
      {props.children}
    </ReactMarkdown>
  );
};

export default Markdown;
