import { css } from '@emotion/css';
import { Link, useNavigate } from 'react-router-dom';

import * as G from '../../nom-server/src/generated/frontendGraphql';
import * as M from '../models/menu.model';
import LanguageToggle from './LanguageToggle';

const NavBar: React.FC<{
  colorScheme: string;
  backTo: string;
  backLabel: string;
  settings?: G.RestaurantSettings | null;
}> = (props) => {
  const themedBackgroundColor =
    props.colorScheme === 'blue'
      ? props.settings?.navbarBackgroundColor
        ? props.settings?.navbarBackgroundColor
        : '#032165'
      : '#000000';

  const navigate = useNavigate();

  return (
    <div
      className={css`
        background: ${themedBackgroundColor};
        color: ${props.settings?.navbarTextColor || '#000'};
        padding: 12px;
        display: flex;
        text-transform: uppercase;
        align-items: center;
        z-index: 10;
        position: sticky;
        top: 0;
        box-shadow: 0px 0 4px #00000066;

        font: 15px var(--font-sans);
      `}
    >
      <div
        className={css`
          flex-basis: 0;
          flex-grow: 1;
          text-align: left;
          display: flex;
        `}
      >
        <div
          onClick={() => navigate(-1)}
          className={css`
            display: flex;
            color: inherit;
            text-decoration: none;
            cursor: pointer;
          `}
        >
          <div
            className={css`
              line-height: 1.15;
            `}
          >
            «&nbsp;
          </div>
          {props.backLabel}
        </div>
      </div>

      {props.settings?.logo && (
        <div
          className={css`
            flex-basis: 20%;
            flex-grow: 0;
            text-align: center;
          `}
        >
          <Link to="/">
            <img src={props.settings?.logo} className={css`
              max-height: 55px;
            `}
            />
          </Link>
        </div>
      )}
      <div
        className={css`
          flex-basis: 0;
          flex-grow: 1;
          text-align: right;
        `}
      >
        <div>
          {/* Language toggle */}
          {props.settings?.languages ? (
            <LanguageToggle
              languages={props.settings?.languages}
              fontColor={props.settings?.sectionHeaderTextColor || ''}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
