import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import * as R from 'ramda';
import * as G from '../../nom-server/src/generated/frontendGraphql';

import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import Footer from './Footer';
import PageHome from './PageHome';
import { Page } from './Page';
import { usePageTitle } from './utils/usePageTitle';
import { usePwaIcon } from './utils/usePwaIcon';
import NavBar from './NavBar';
import SelectorWrapper from './Selector';
import ChatWrapper from './Chat';

const GOOGLE_ANALYTICS_ID = 'UA-246573123-1';

/*
 * Registers a history listener on mount which
 * scrolls to the top of the page on route change
 */
export const useScrollTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
};

function ScrollTop() {
  useScrollTop();
  return null;
}

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(
      location.pathname,
      undefined,
      document.querySelector('.nom-title')?.textContent || undefined,
    );
    // Remove utm params
    const url = new URL(window.location.href);
    for (const [key] of [...url.searchParams]) {
      if (key.startsWith('utm_')) {
        url.searchParams.delete(key);
      }
    }
    window.history.replaceState(window.history.state, '', url.href);
  }, [location.pathname]);
}

function PageViews() {
  usePageViews();
  return null;
}

export function App({ menu }: { menu: G.RestaurantV2 }): JSX.Element {
  const [, setGaInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    setGaInitialized(true);
  }, []);

  const restaurant = useMemo(() => {
    return {
      ...menu,
      sectionById: R.indexBy(R.prop('id'), menu.sections || []),
      itemById: R.indexBy(R.prop('id'), menu.items || []),
      promotedItemById: R.indexBy((p) => p.itemId || '', menu.promotions || []),
    };
  }, [menu]);

  // eslint-disable-next-line
  usePageTitle(restaurant.settings?.appTitle);
  // eslint-disable-next-line
  usePwaIcon('512x512', restaurant.settings?.appIcon);

  (window as any).restaurant = restaurant;

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <>
          <ScrollTop />
          <PageViews />
          <PageHome restaurant={restaurant} />
          <Footer restaurant={restaurant} />
        </>
      ),
      errorElement: <>Unknown page</>,
    },
    {
      path: '/*',
      element: (
        <>
          <ScrollTop />
          <PageViews />
          <Page restaurant={restaurant} />
        </>
      ),
    },
    {
      path: '/search',
      element: (
        <>
          {window.location.origin.includes('wptest') ? (
            <>
              <ScrollTop />
              <PageViews />
              <SelectorWrapper restaurant={restaurant} />
              <Footer restaurant={restaurant} />
            </>
          ) : (
            <>
              <ScrollTop />
              <PageViews />
              <PageHome restaurant={restaurant} />
              <Footer restaurant={restaurant} />
            </>
          )}
        </>
      ),
    },
    {
      path: '/chat',
      element: (
        <>
          {window.location.origin.includes('wptest') ||
          window.location.origin.includes('hlctest') ? (
            <>
              <ScrollTop />
              <PageViews />
              <ChatWrapper restaurant={restaurant} />
              {/* <Footer restaurant={restaurant} /> */}
            </>
          ) : (
            <>
              <ScrollTop />
              <PageViews />
              <PageHome restaurant={restaurant} />
              <Footer restaurant={restaurant} />
            </>
          )}
        </>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}
