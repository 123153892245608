import { css, cx } from '@emotion/css';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

import * as M from '../models/menu.model';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import KipsuButton from './KipsuButton';
import { InstagramIcon } from './icons/instagramIcon';
import { FacebookIcon } from './icons/facebookIcon';
import { TripAdvisorIcon } from './icons/tripadvisorIcon';
import { PinterestIcon } from './icons/pinterestIcon';
import { YoutubeIcon } from './icons/youtubeIcon';
import { TikTokIcon } from './icons/tiktokIcon';

const socials = css`
  display: grid;
  width: 60px;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 10px;
  padding-top: 7px;
`;

const image = css`
  width: 18px;
  height: 23px;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const parseLink = (link: string) => {
  // link format: ['[Link Text](linkurl)', '[Link Text](linkurl)']
  if (!link.includes('(') || !link.includes('[')) {
    return [link, link];
  }
  const url = link.split('(')[1].split(')')[0];
  const text = link.split('[')[1].split(']')[0];

  return [text, url];
};

const Footer: React.FC<{
  restaurant: G.RestaurantV2;
}> = (props) => {
  const footerBackground = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    padding: 40px 0px;
    gap: 35px;

    position: relative;
    min-height: 90px;

    background: ${props.restaurant.settings?.footerBackgroundColor ||
    '#1b1a20'};
  `;

  const footerBackgroundEmpty = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    position: relative;
    background: ${props.restaurant.settings?.footerBackgroundColor ||
    '#1b1a20'};
  `;

  const leftSide = css`
    height: 85px;

    font: 10px var(--font-sans);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    padding-left: 25px;

    color: ${props.restaurant.settings?.footerTextColor || '#fff'};
  `;

  const rightSide = css`
    height: 120px;
    text-transform: uppercase;
    font: 13px var(--font-sans);
    line-height: 105%;
    align-items: center;
    padding-right: 15px;
    color: ${props.restaurant.settings?.footerTextColor || '#fff'};
  `;

  const websites = css`
    display: grid;
    width: fit-content;
  `;

  const webLinks = css`
    text-decoration: underline !important;
    color: ${props.restaurant.settings?.footerTextColor || '#fff'};
    padding-top: 8px;
    width: max-content;
  `;

  return (
    <>
      {/* End Footer CTA */}
      <div className={footerBackground}>
        <div className={leftSide}>
          {/** Empty div for left side links */}
          <span>Social</span>

          <div className={socials}>
            {props.restaurant.info?.instagram ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={props.restaurant.info?.instagram || ''}
              >
                <InstagramIcon
                  className={image}
                  color={props.restaurant.settings?.footerTextColor || 'white'}
                />
              </a>
            ) : null}

            {props.restaurant.info?.facebook ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={props.restaurant.info?.facebook || ''}
              >
                <FacebookIcon
                  className={image}
                  color={props.restaurant.settings?.footerTextColor || 'white'}
                />
              </a>
            ) : null}

            {props.restaurant.info?.tripadvisor ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={props.restaurant.info?.tripadvisor || ''}
              >
                <TripAdvisorIcon
                  className={image}
                  color={props.restaurant.settings?.footerTextColor || 'white'}
                />
              </a>
            ) : null}

            {props.restaurant.info?.pintrest ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={props.restaurant.info?.pintrest || ''}
              >
                <PinterestIcon
                  className={image}
                  color={props.restaurant.settings?.footerTextColor || 'white'}
                />
              </a>
            ) : null}

            {props.restaurant.info?.youtube ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={props.restaurant.info?.youtube || ''}
              >
                <YoutubeIcon
                  className={image}
                  color={props.restaurant.settings?.footerTextColor || 'white'}
                />
              </a>
            ) : null}

            {props.restaurant.info?.tiktok ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={props.restaurant.info?.tiktok || ''}
              >
                <TikTokIcon
                  className={image}
                  color={props.restaurant.settings?.footerTextColor || 'white'}
                />
              </a>
            ) : null}
          </div>
        </div>

        <div
          className={
            window.location.hostname.includes('hiltonloscabos')
              ? cx(
                  rightSide,
                  css`
                    padding-bottom: 0;
                  `,
                )
              : rightSide
          }
        >
          {/* TODO: modify to work with markdown */}
          {/* <ReactMarkdown remarkPlugins={[remarkBreaks]}> */}
          {props.restaurant.displayName} <br />
          {props.restaurant.info?.address || ''}
          {/* </ReactMarkdown> */}
          {props.restaurant.info && props.restaurant.info.links ? (
            <div className={websites}>
              {props.restaurant.info?.links?.map((link, i) =>
                link ? (
                  <a
                    key={i}
                    target="_blank"
                    rel="noreferrer"
                    className={webLinks}
                    href={parseLink(link)[1]}
                  >
                    {parseLink(link)[0]}
                  </a>
                ) : null,
              )}
            </div>
          ) : null}
        </div>
      </div>
    {/* Footer CTA */}
    {props.restaurant!.settings!.kipsuNumber ? 
      <div
        className={cx(
          footerBackgroundEmpty,
          css`
            padding-bottom: 0px;
            padding-top: 0px;
          `,
        )}
      >
        <KipsuButton
          requestedItem="N/A"
          settings={props.restaurant.settings!}
          sectionById={(props.restaurant as M.Restaurant).sectionById}
          isFooter={true}
        />
      </div>: null}
      <div
        className={cx(
          footerBackground,
          css`
            padding-top: 15px;
          `,
        )}
      >
        <a href="https://nommenu.com/" target="_blank" rel="noreferrer">
          <div
            className={css`
              color: ${props.restaurant.settings?.footerTextColor || '#fff'};;
              font: 15px var(--font-sans);
              line-height: 1.5em;
              text-align: center;
              padding: 0 15px;
            `}
          >
            Designed and built by Nom
            {props.restaurant.displayName
              ? `, in close collaboration with ${props.restaurant.displayName}`
              : ''}
            .
          </div>
        </a>
      </div>
    </>
  );
};

export default Footer;
