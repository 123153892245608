/**
 * @file This file creates the order view to show order details.
 */

import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import Modal from 'react-bootstrap/Modal';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import { useMatch, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';

import SelectedItemList from './SelectedItemList.container';
import ConfirmationMsg from '../components/ConfirmationMsg.component';
import { Menu } from '../models/menu.model';
import { SelectedItem } from '../models/selection.model';
import ItemDetails from './ItemDetails.container';
import { buildRemoveItem } from '../state/selectedItemList/actionCreators.selectedItemList';
import { store, State } from '../state/store';
import { ConfirmationIcon } from '../icons';
import './OrderView.container.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
function OrderView({
  menu,
  selectionId,
}: {
  menu: Menu;
  selectionId?: string;
}) {
  const order = useSelector((state: State) => state.selectedItemList.order);
  const selectedItem = Object.values(order).find(
    (selectedItem) => selectedItem.id === selectionId,
  );
  console.log('sel item', selectedItem);
  console.log('sel id', selectionId);

  const navigate = useNavigate();
  const clearItemSelection = () => navigate(-1);
  const dispatch = useDispatch();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationItem, setConfirmationItem] = useState<
    string | undefined
  >();
  const [confirmationMessage, setConfirmationMessage] = useState<
    string | undefined
  >();

  const [confirmRemoval, setConfirmRemoval] = useState(false);

  const [itemToRemove, setItemToRemove] = useState<SelectedItem | undefined>();

  function handleRemoveItemFromOrder(id: string, name: string) {
    dispatch(buildRemoveItem(id));
    hideConfirmRemoval();
    handleShowConfirmation(name, 'was removed from your list.');
  }

  function showConfirmation() {
    setConfirmationOpen(true);
  }

  function hideConfirmation() {
    setConfirmationOpen(false);
  }

  function handleShowConfirmation(itemName?: string, confirmationMsg?: string) {
    setConfirmationItem(itemName);
    setConfirmationMessage(confirmationMsg);
    showConfirmation();
    setTimeout(() => hideConfirmation(), 2000);
  }

  function showConfirmRemoval() {
    setConfirmRemoval(true);
  }

  function hideConfirmRemoval() {
    setConfirmRemoval(false);
  }

  function handleShowRemoveConfirmation(item: SelectedItem) {
    setItemToRemove(item);
    showConfirmRemoval();
  }

  return (
    <div className="order-view">
      <SelectedItemList
        onConfirm={handleShowConfirmation}
        onRemove={handleShowRemoveConfirmation}
        theme={menu.theme}
      />

      {selectedItem && (
        <MediaQuery maxWidth={1040}>
          <div className="item-details-sheet-mobile">
            <SwipeableBottomSheet
              open
              onChange={clearItemSelection}
              bodyStyle={{ borderRadius: '30px 30px 0 0' }}
            >
              <ItemDetails
                itemName={selectedItem.item.name}
                item={selectedItem.item}
                onClose={clearItemSelection}
                contest={menu.contest}
                theme={menu.theme}
                onConfirm={handleShowConfirmation}
                currentValueCounts={selectedItem.options.map((op) =>
                  op.values.map((val) => val.count),
                )}
                selectedItem={selectedItem}
                onRemove={handleShowRemoveConfirmation}
              />
            </SwipeableBottomSheet>
          </div>
        </MediaQuery>
      )}
      {confirmationItem && confirmationMessage && (
        <div className="confirmation-msg">
          <Modal
            show={confirmationOpen}
            onHide={hideConfirmation}
            centered={true}
            dialogClassName="confirmation-msg"
            size="sm"
          >
            <ConfirmationMsg
              icon={ConfirmationIcon}
              itemName={confirmationItem}
              message={confirmationMessage}
            />
          </Modal>
        </div>
      )}
      <div className="confirm-removal">
        <Modal
          show={confirmRemoval}
          onHide={hideConfirmRemoval}
          centered={true}
          dialogClassName="confirm-removal"
          size="sm"
        >
          {itemToRemove && (
            <div className="confirm-removal">
              <div className="header">
                Are you sure you want to remove from list?
              </div>
              <div className="subheader">
                {itemToRemove?.item.name} will no longer be part of your list.
              </div>
              <div className="message-buttons">
                <Button className="cancel-button" onClick={hideConfirmRemoval}>
                  Cancel
                </Button>
                <Button
                  className="remove-button"
                  onClick={() =>
                    handleRemoveItemFromOrder(
                      itemToRemove.id,
                      itemToRemove.item.name,
                    )
                  }
                >
                  Yes, Remove
                </Button>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default OrderView;
