import React from 'react';
import cx from 'classnames';
import ReactGA from 'react-ga4';
import * as R from 'ramda';
import { Button } from 'react-bootstrap';

import * as G from '../../nom-server/src/generated/frontendGraphql';
import ItemImageCarousel from '../components/ItemImageCarousel.component';
import ItemSummary from '../components/ItemSummary.component';
import ItemGroupSummary from './ItemGroupSummary.component';
import { useMaybeMenufy } from '../integrations/UseIntegrations';
import { buildAddItem } from '../state/selectedItemList/actionCreators.selectedItemList';
import { dispatch } from '../state/store';
import { Item, ItemGroup } from '../models/menu.model';

import './MenuItemGroup.component.css';
import MediaQuery from 'react-responsive';

const reduxDispatchItem = R.compose(dispatch, buildAddItem);

function MenuItemGroup({
  itemGroup,
  onConfirm,
}: {
  itemGroup: ItemGroup;
  onConfirm: (name: string, message: string) => unknown;
}): React.ReactElement {
  const { items } = itemGroup;
  const [dispatchItem] = useMaybeMenufy(reduxDispatchItem);
  const images = items.flatMap((item) => item.images);
  const hasOptions = items.some((item) => item.options.length);

  function handleClick(event: React.MouseEvent) {
    // onClick(itemGroup, event);
  }

  function handleAddToOrder() {
    const label = items.map((item) => item.name).join(', ');
    ReactGA.event({
      category: 'Order',
      action: 'added',
      label,
      value: 1,
    });
    items.forEach((item) => dispatchItem(item as unknown as Item, [], 1));
    onConfirm(label, 'have been successfully added to your list.');
  }

  return (
    <div className={cx('menu-item-group')}>
      {images.length ? (
        <div className="item-card" onClick={handleClick}>
          <ItemImageCarousel
            images={images}
            clickToScroll={images.length > 1}
          />
          <ItemGroupSummary itemGroup={itemGroup} />
        </div>
      ) : (
        <div className="item-card no-image" onClick={handleClick}>
          <ItemGroupSummary itemGroup={itemGroup} />
        </div>
      )}
      <MediaQuery maxWidth={1040}>
        <div className="action">
          <Button
            className="add-item-button"
            variant="light"
            onClick={handleAddToOrder}
          >
            Add to My List
          </Button>
        </div>
      </MediaQuery>
    </div>
  );
}

export { MenuItemGroup };
