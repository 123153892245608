import { css } from '@emotion/css';
import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { ListItemArrow } from './ListItemArrow';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import { customAnalyticsEvent } from '../utils/analytics.utils';
import * as M from '../models/menu.model';
import Markdown from './Markdown';
import cx from 'clsx';
import { useSelector } from 'react-redux';
import { State } from '../state/store';

const StyledItemDescription: React.FC<{
  description?: string;
  truncated?: boolean;
  maxTextHeight?: number;
  isSans?: boolean;
}> = (props) => {
  return (
    <div
      className={css`
        ${props.isSans
          ? 'font: 13px var(--font-sans);'
          : 'font: 13px var(--font-serif);'}
        opacity: 60%;
      `}
    >
      <Markdown
        className={
          props.truncated
            ? css`
                --max-lines: 1;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                position: relative;
                padding-right: 24px;
                max-height: calc(1rem * var(--max-lines));
                overflow: hidden;
                ${props.isSans
                  ? 'font: 13px var(--font-sans);'
                  : 'font: 13px var(--font-serif);'}
              `
            : props.maxTextHeight
            ? css`
                display: -webkit-box;
                -webkit-line-clamp: ${Math.floor(
                  props.maxTextHeight / 15,
                )}; // 15px line-height: # lines = height/line-height
                -webkit-box-orient: vertical;
                overflow: hidden;
                ${props.isSans
                  ? 'font: 13px var(--font-sans);'
                  : 'font: 13px var(--font-serif);'}
              `
            : ''
        }
      >
        {props.description || ''}
      </Markdown>
    </div>
  );
};

const textOnlyItemClass = css`
  display: flex;
  align-items: center;
  padding: 9px 20px;
  gap: 5px;
  text-decoration: none;
  color: #000;
  scroll-margin: 60px;
`;

const promotedClass = css`
  background: #b2b2b266;
`;

const TextOnlyItem: React.FC<{
  price?: string;
  colorScheme?: string;
  title: string;
  titleLocalized?: string;
  description: string;
  badges?: string[];
  page: string;
  section: string;
  to: string;
  isPromoted?: boolean;
  expanded?: boolean;
  arrowColor?: string;
  tags?: string[];
  tagColor?: string;
  tagTextColor?: string;
  sectionById?: M.Restaurant["sectionById"];
  id?: string;
}> = (props) => {
  let itemPath = '';
  if (props.page) {
    itemPath += props.page + '/';
  }
  if (props.section) {
    itemPath += props.section + '/';
  }
  const textEventData = {
    category: 'Item Clicks',
    action: 'TextItem Clicked',
    label: itemPath + props.title,
  };

  const registerClick = () => {
    let rootSectionName = '';
    if(props && props.sectionById){
      const rootSectionId = window.location.pathname.split('/')[1];
      rootSectionName = props?.sectionById[rootSectionId].name;
    }

    ReactGA.event(textEventData);
    // Custom Analytics
    const extendedEventData = {
      category:
        textEventData.category +
        (window.origin.includes('localhost') ? ' Localhost' : ''),
      label: textEventData.label,
      action: textEventData.action,
      time: new Date().toUTCString(),
      rootSectionName: rootSectionName,
      id: props.id,
    };
    customAnalyticsEvent(extendedEventData);
  };

  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (props.isPromoted && ref.current && window.location.hash) {
      setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [ref, props.isPromoted]);

  const itemContentsRender = (
    <>
      <div
        className={css`
          flex-grow: 1;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            className={css`
              text-transform: uppercase;
              font: 16px var(--font-sans);
            `}
          >
            {props.titleLocalized || props.title}
          </div>
          {props.price && (
            <div
              className={css`
                font: 15px var(--font-sans);
                word-break: normal;
                min-width: 55px;
                text-align: left;
                padding-left: 10px;
              `}
            >
              {props.price !== '0' ? '$' + parseInt(props.price) / 100 : null}
            </div>
          )}
        </div>
        {props.tags?.map((tag, i) => (
          <span
            key={i}
            className={css`
              font: 10px var(--font-sans);
              text-transform: uppercase;
              color: ${props.tagTextColor};
              padding: 2px 6px;
              margin-right: 6px;
              background-color: ${props.tagColor};
            `}
          >
            {tag}
          </span>
        ))}
        <StyledItemDescription
          description={props.description}
          truncated={!props.expanded}
        />
      </div>
      <div>
        {!props.expanded && (
          <ListItemArrow color={props.arrowColor || 'black'} />
        )}
      </div>
    </>
  );

  if (props.expanded) {
    return (
      <span
        className={cx(
          textOnlyItemClass,
          props.isPromoted && window.location.hash && promotedClass,
        )}
      >
        {itemContentsRender}
      </span>
    );
  }

  return (
    <Link
      ref={ref}
      onClick={registerClick}
      className={cx(
        textOnlyItemClass,
        props.isPromoted && window.location.hash && promotedClass,
      )}
      to={props.to}
    >
      {itemContentsRender}
    </Link>
  );
};

const VerticalImageItem: React.FC<{
  title: string;
  titleLocalized?: string;
  description?: string;
  image: string;
  page: string; // Menu/page name for GA
  section: string; // Menu section name for GA
  to: string;
  isPromoted?: boolean;
  isSearchResult?: boolean;
  arrowColor?: string;
  sectionById?: M.Restaurant["sectionById"];
  id?: string;
}> = (props) => {
  const [height, setHeight] = useState(200); // default height of 200px before offset calculation
  const handleOnLoad = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const imgElement = event.target as HTMLElement | null;
      setHeight(imgElement?.offsetHeight ? imgElement.offsetHeight - 30 : 0); // 26px = text padding
    },
    [setHeight],
  );

  let itemPath = '';
  if (props.page) {
    itemPath += props.page + '/';
  }
  if (props.section) {
    itemPath += props.section + '/';
  }
  const imgEventData = {
    category: 'Item Clicks',
    action: 'VerticalItem Clicked',
    label: itemPath + props.title,
  };

  const registerClick = () => {
    let rootSectionName = '';
    if(props && props.sectionById){
      const rootSectionId = window.location.pathname.split('/')[1];
      rootSectionName = props?.sectionById[rootSectionId].name;
    }

    ReactGA.event(imgEventData);
    // Custom Analytics
    const extendedEventData = {
      category:
        imgEventData.category +
        (window.origin.includes('localhost') ? ' Localhost' : ''),
      label: imgEventData.label,
      action: imgEventData.action,
      time: new Date().toUTCString(),
      rootSectionName: rootSectionName,
      id: props.id,
    };
    customAnalyticsEvent(extendedEventData);
  };

  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (props.isPromoted && ref.current && window.location.hash) {
      setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 1500);
    }
  }, [ref, props.isPromoted]);

  return (
    <Link
      ref={ref}
      className={css`
        display: flex;
        flex-grow: 1;
        padding: 10px 20px;
        align-items: ${props.isSearchResult ? `flex-start` : `center`};
        text-decoration: none;
        color: #000;
        ${props.isPromoted && window.location.hash
          ? `background: #B2B2B266;`
          : null}
      `}
      to={props.to}
    >
      {props.image ? (
        <img
          onLoad={(e) => handleOnLoad(e)}
          src={props.image}
          className={css`
            max-width: 50%;
          `}
        />
      ) : null}
      <div
        onClick={registerClick}
        className={css`
          padding-left: 15px;
          width: 100%;
        `}
      >
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 11px;
            justify-content: space-between;
          `}
        >
          <div
            className={css`
              flex-grow: 1;
            `}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <div
                className={css`
                  text-transform: uppercase;
                  font: 16px var(--font-sans);
                  margin-bottom: 10px;
                `}
              >
                {props.titleLocalized || props.title}
              </div>
            </div>
            <StyledItemDescription
              description={props.description}
              maxTextHeight={props.isSearchResult ? height - 50 : height}
              isSans={true}
            />
          </div>
          <ListItemArrow color={props.arrowColor || 'black'} />
        </div>
      </div>
    </Link>
  );
};

const ListItem: React.FC<{
  type?: 'vertical' | 'horizontal';
  id: string;
  price?: string;
  colorScheme: string;
  to: string;
  title: string;
  titleLocalized?: string;
  description?: string;
  badges?: string[];
  image?: string;
  page?: string;
  section?: string;
  expanded?: boolean;
  restaurant: M.Restaurant;
  isSearchResult?: boolean;
  tags?: string[];
}> = (props) => {
  const isPromoted = useMemo(() => {
    return !!props.restaurant.promotedItemById[props.id];
  }, [props.restaurant, props.id]);

  if (props.type === 'vertical' && props.image != null) {
    return (
      <VerticalImageItem
        to={props.to}
        title={props.title}
        titleLocalized={props.titleLocalized}
        description={props.description}
        image={props.image}
        page={props.page ? props.page : ''}
        section={props.section ? props.section : ''}
        isPromoted={isPromoted}
        isSearchResult={props.isSearchResult ? props.isSearchResult : false}
        arrowColor={props.restaurant.settings?.listArrowColor || ''}
        sectionById={props.restaurant.sectionById}
        id={props.id}
      />
    );
  }
  return (
    <TextOnlyItem
      to={props.to}
      title={props.title}
      titleLocalized={props.titleLocalized}
      description={props.description || ''}
      price={props.price}
      page={props.page ? props.page : ''}
      section={props.section ? props.section : ''}
      isPromoted={isPromoted}
      expanded={props.expanded}
      arrowColor={props.restaurant.settings?.listArrowColor || ''}
      tags={props.tags}
      tagColor={props.restaurant.settings?.tagColor || '#40a07b'}
      tagTextColor={props.restaurant.settings?.tagTextColor || '#fff'}
      sectionById={props.restaurant.sectionById}
      id={props.id}
    />
  );
};

export default ListItem;
