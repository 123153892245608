import * as R from 'ramda';

import { SelectedItemListState as State } from './types.selectedItemList';
import { SelectedItemListAction as Action } from './actions.selectedItemList';

export function selectedItemListReducer(
  state: State | undefined,
  action: Action,
): State {
  state = state || { order: {} };

  switch (action.type) {
    case 'AddItem':
      return {
        ...state,
        order: R.assoc(action.item.id, action.item, state.order),
      };
    case 'RemoveItem':
      return {
        ...state,
        order: R.dissoc(action.id, state.order),
      };
    case 'UpdateItem':
      return {
        ...state,
        order: R.set(R.lensProp(action.id), action.item, state.order),
      };
    default:
      return state;
  }
}
