/**
 * @file This file contains utility functions related to the frontend models.
 */

import { groupBy, indexBy, sortBy } from 'ramda';
import * as R from 'ramda';

import {
  Tag,
  Item,
  Menu,
  Theme,
  Section,
  Slide,
  Subsection,
  Option,
  ItemGroup,
} from '../models/menu.model';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import _, { iteratee } from 'lodash';

export type HasTags = {
  tags: Tag[];
};

export const indexById = indexBy(<T extends { id?: unknown }>(e: T) =>
  String(e.id),
);

const getName = R.prop('name');

function get<T extends Record<string, unknown>>(obj: T) {
  return (key: keyof T) => obj[key];
}

export const groupTagByLabel = groupBy(<T extends Tag>(tag: T) => tag.label);

export const indexTagByValue = indexBy(<T extends Tag>(tag: T) => tag.value);

export const indexItemByName = indexBy(<T extends Item>(item: T) => item.name);

// Replacement tag used when an item has no tags.
export const unTaggedTag: Tag = { label: 'meta', value: 'untagged' };

// Sorts items with images followed by items without.
export const sortItemByImage = sortBy(
  (item: Item) => -Math.min(item.images.length, 1),
);

// Randomize items in a list
export function randomizeItem(items: Item[]) {
  items.sort((a, b) => 0.5 - Math.random());
  return items;
}

export function tagToString(tag: Tag): string {
  return `${tag.label}:${tag.value}`;
}

// Pluralizes items correctly
export function pluralize(count: number, noun: string) {
  return `${count} ${noun}${count !== 1 ? 's' : ''}`;
}

function resolveObjs<T>(getter: (id: string) => T, ids?: string[] | null): T[] {
  return _.compact(ids?.map(getter) || []);
}

export function itemToMenuItem(it: G.Item): Item {
  return {
    id: String(it.id),
    name: it.name,
    images: it.images,
    description: it.description,
    ingredients: it.ingredients,
    price: it.price,
    options: it.optionGroups.map((og) => ({
      name: og.name,
      values: og.options,
      min: og.min,
      max: og.max,
      // TODO add: notIncrementable
    })),
    tags: it.tags?.map((t) => ({
      label: t.label || '', // Fix
      value: t.value,
    })),
    inStock: it.inStock || undefined,
    // TODO add: note, specialTags, ...
  };
}

// Converts backend RestaurantV2 to frontend Menu
export function restaurantV2ToMenu(restaurant: G.RestaurantV2): Menu {
  const {
    rootSectionIds,
    sections,
    items,
    itemGroups,
    tags,
    optionGroups,
    slides,
    settings,
    specialAnnouncements,
  } = restaurant;
  const sectionById = indexById(sections || []);
  const itemById = indexById(items || []);
  const itemGroupById = indexById(itemGroups || []);
  const tagById = indexById(tags || []);
  const optionGroupById = indexById(optionGroups || []);

  const getSectionById = get(sectionById);
  const getItemById = get(itemById);
  const getItemGroupById = get(itemGroupById);
  const getTagById = get(tagById);
  const getOptionGroupById = get(optionGroupById);

  const theme: Theme = {
    ...settings,
    specialAnnouncements,
  };

  const menuSlides: Slide[] =
    slides?.map((slide: G.RestaurantSlide) => ({
      text: slide.text || '',
      description: slide.description || undefined,
      contents: slide.contents ?? undefined,
      image: slide.image || undefined,
      desktopImage: slide.desktopImage || undefined,
      nextPage: slide.nextButtonText || undefined,
      prevPage: slide.prevButtonText || undefined,
    })) || [];

  function tagToTag(tag: G.TagV2): Tag {
    const label = tag.label || '';
    return {
      ...tag,
      label,
    };
  }

  function optionGroupToOption(optionGroup: G.OptionGroupV2): Option {
    const { options } = optionGroup;
    const values = options;
    return {
      ...optionGroup,
      values,
    };
  }

  function itemToItem(item: G.ItemV2): Item {
    const { optionGroupIds, tagIds } = item;
    const options = resolveObjs(getOptionGroupById, optionGroupIds).map(
      optionGroupToOption,
    );
    const tags = resolveObjs(getTagById, tagIds).map(tagToTag);
    return {
      ...item,
      options,
      tags,
    };
  }

  function itemGroupToItemGroup(itemGroup: G.ItemGroupV2): ItemGroup {
    const { itemIds } = itemGroup;
    const items = resolveObjs(getItemById, itemIds).map(itemToItem);
    return {
      ...itemGroup,
      items,
    };
  }

  function sectionToSubsection(section: G.SectionV2): Subsection {
    const { itemIds, itemGroupIds } = section;
    const items = resolveObjs(getItemById, itemIds).map(itemToItem);
    const itemGroups = resolveObjs(getItemGroupById, itemGroupIds).map(
      itemGroupToItemGroup,
    );
    return {
      ...section,
      note: section.description,
      items,
      itemGroups,
    };
  }

  function sectionToSection(section: G.SectionV2): Section {
    const { childSectionIds } = section;
    const subsections = resolveObjs(getSectionById, childSectionIds).map(
      sectionToSubsection,
    );
    return {
      ...section,
      subsections,
    };
  }

  const menuSections = resolveObjs(getSectionById, rootSectionIds).map(
    sectionToSection,
  );

  return {
    ...restaurant,
    slides: menuSlides,
    sections: menuSections,
    contest: false, // Fix
    theme,
    name: restaurant.displayName || restaurant.name,
    // integrations: restaurant.integrations,
  };
}

// Converts backend Restaurant to frontend Menu
export function restaurantToMenu(restaurant: G.Restaurant): Menu {
  const theme: Theme = {
    ...restaurant.settings,
    specialAnnouncements: restaurant.specialAnnouncements,
  };

  const sections: Section[] = restaurant.rootSections.map((s) => {
    const section = {
      name: s.name,
      timedMenu: {
        availableDays: s.availableDays || [],
        startTime: s.startTime || '',
        endTime: s.endTime || '',
      },
      disabled: s.disabled,
      note: s.description,
      subsections: s.childSections.length
        ? s.childSections.map((cs) => ({
            name: cs.name,
            note: cs.note,
            disabled: cs.disabled,
            items: cs.items.map(itemToMenuItem),
            itemGroups: cs.itemGroups.map((ig) => ({
              ...ig,
              id: String(ig.id),
              itemIds: ig.itemIds.map(String),
              items: [], // gets set later
              __typename: 'ItemGroupV2' as const,
            })),
          }))
        : [{ name: 'no subsections defined', items: [], itemGroups: [] }],
    };
    return section;
  });

  const items = sections.flatMap((s) =>
    s.subsections.flatMap((ss) => ss.items),
  );
  const itemById = indexById(items);

  sections.forEach((s) =>
    s.subsections.forEach((ss) => {
      ss.itemGroups = ss.itemGroups?.map((rig) => {
        return {
          ...rig,
          items: rig.itemIds
            .map((id) => ({
              id: '0',
              ...itemById[id],
              optionGroups: [],
              sections: [],
              ingredients: [],
              tags: [],
            }))
            .filter((e) => e),
        };
      });
    }),
  );

  return {
    ...restaurant,
    slides:
      restaurant.slides?.map((slide: G.RestaurantSlide) => ({
        text: slide.text || '',
        description: slide.description || undefined,
        contents: slide.contents ?? undefined,
        image: slide.image || undefined,
        desktopImage: slide.desktopImage || undefined,
        nextPage: slide.nextButtonText || undefined,
        prevPage: slide.prevButtonText || undefined,
      })) || [],
    sections,
    contest: false, // Fix
    theme,
    name: restaurant.displayName || restaurant.name,
    // integrations: restaurant.integrations,
  };
}

export function mapMenuToRestaurant(
  menu: Menu,
  restaurantBase: G.Restaurant,
  name: string,
  displayName: string,
): G.Restaurant {
  if (!menu) throw Error('Missing menu object');
  return {
    ...restaurantBase,
    name,
    displayName,
    settings: {
      mainColor: menu.theme?.mainColor,
      gotStarted: true,
      filters: menu.theme?.filters,
    },
    slides: menu.slides.map((slide) => ({
      text: slide.text,
      description: slide.description,
      contents: slide.contents,
      image: slide.image,
      desktopImage: slide.desktopImage,
      nextButtonText: slide.nextPage,
      prevButtonText: slide.prevPage,
    })),
    rootSections: menu.sections.map((section) => ({
      id: Math.floor(Math.random() * 2 ** 31),
      name: section.name,
      childSections: section.subsections.map((subsection) => ({
        id: Math.floor(Math.random() * 2 ** 31),
        name: subsection.name,
        childSections: [],
        parentSections: [],
        items: subsection.items.map((item) => ({
          id: Math.floor(Math.random() * 2 ** 31),
          name: item.name,
          images: item.images,
          description: item.description,
          ingredients: item.ingredients || [],
          price: item.price,
          // available: true,
          optionGroups: item.options.map((option) => ({
            id: Math.floor(Math.random() * 2 ** 31),
            restaurantId: restaurantBase.id,
            name: option.name,
            options: option.values.map((value) => ({
              id: Math.floor(Math.random() * 2 ** 31),
              restaurantId: restaurantBase.id,
              name: value.name,
              price: value.price,
            })),
            min: option.min,
            max: option.max,
          })),
          tags: item.tags.map((tag) => ({
            id: Math.floor(Math.random() * 2 ** 31),
            label: tag.label,
            value: tag.value,
            items: [],
          })),
          sections: [],
          inStock: true,
        })),
        itemGroups: [],
      })),
      itemGroups: [],
      parentSections: [],
      items: [],
    })),
    stringified: '',
  };
}
(window as any).mapMenuToRestaurant = mapMenuToRestaurant;
