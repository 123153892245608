/**
 * @file This file creates the Filter Panel which allows user to show only
 * certain types of items.
 */

import React, { useMemo } from 'react';
import cx from 'classnames';
import ReactGA from 'react-ga4';
import { innerJoin } from 'ramda';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux';

import { unTaggedTag, tagToString, HasTags } from '../utils/model.utils';
import { Tag } from '../models/menu.model';
import { FilterTag } from '../models/filter.model';
import { filtersByLabel } from '../utils/filter.utils';
import { State } from '../state/store';
import { BottomSheetTopLine, Checkmark } from '../icons/index';
import { Theme } from '../models/menu.model';

import './FilterPanel.component.css';

export function useFilter<T extends HasTags>(list: T[]): T[] {
  const filterState = useSelector((state: State) => state.filters.filterMap);

  const filterTags: FilterTag[] = useMemo(
    () =>
      Object.values(filterState).filter((filterState) => filterState.checked),
    [filterState],
  );

  const filterInValues = useMemo(
    () => filterTags.filter((filterTag) => filterTag.type === 'include'),
    [filterTags],
  );
  const filterOutValues = useMemo(
    () => filterTags.filter((filterTag) => filterTag.type === 'exclude'),
    [filterTags],
  );

  // If filterInValues is empty, assume all values remain.
  const filterInList = useMemo(
    () =>
      filterInValues.length
        ? list.filter(
            (hasTags: HasTags) =>
              innerJoin(
                (itemTag: Tag, filterTag: FilterTag) =>
                  (itemTag.label === filterTag.label &&
                    itemTag.value === filterTag.value) ||
                  (itemTag.label === 'meta' &&
                    itemTag.value === 'always include'),
                hasTags.tags,
                filterInValues,
              ).length,
          )
        : list,
    [filterInValues, list],
  );

  const filterOutList = useMemo(
    () =>
      filterInList.filter(
        (hasTags) =>
          innerJoin(
            (itemTag: Tag, filterTag: FilterTag) =>
              itemTag.label === filterTag.label &&
              itemTag.value === filterTag.value,
            hasTags.tags.length ? hasTags.tags : [unTaggedTag],
            filterOutValues,
          ).length === 0,
      ),
    [filterInList, filterOutValues],
  );

  // No filters mean no change.
  if (filterTags.length === 0) {
    return list;
  }

  return filterOutList;
}

interface FilterPanelParams {
  className?: string;
  onClose?: () => void;
  theme?: Theme;
}

function FilterPanel(params: FilterPanelParams): React.ReactElement {
  const { className, onClose, theme } = params;

  const dispatch = useDispatch();
  const filterState = useSelector((state: State) => state.filters.filterMap);

  function handleFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    if (checked) {
      ReactGA.event({
        category: 'Filter',
        action: 'activated',
        label: name,
      });
    }
    dispatch({
      type: 'SetFilterMap',
      filterMap: {
        ...filterState,
        [name]: { ...filterState[name], checked },
      },
    });
  }

  function handleClickClose() {
    onClose && onClose();
  }

  function filterTypeToCheckbox(ft: FilterTag) {
    const uniqFilterName = tagToString(ft);
    /* List of icons that will be used as labels instead */
    const iconsAsLabel = ['sesame-free', 'signature'];
    return (
      <Form.Check key={ft.value} id={ft.value}>
        {/* Allergy icon will be only displayed as label */}
        <FormCheck.Label className="filter-label-wrapper">
          <div className="filter-label">
            {ft.type === 'exclude' || iconsAsLabel.includes(ft.value) ? (
              <div className="allergy-label">{ft.icon}</div>
            ) : (
              ft.value
            )}
          </div>
          {ft.type != 'exclude' && !iconsAsLabel.includes(ft.value) && (
            <div className="icon">{ft.icon}</div>
          )}
        </FormCheck.Label>
        <FormCheck.Input
          name={uniqFilterName}
          type="checkbox"
          onChange={handleFilterChange}
          checked={filterState[uniqFilterName].checked}
        />
        <div className="checkmark">{Checkmark}</div>
      </Form.Check>
    );
  }

  const dietaryFilters =
    theme && theme.filters
      ? (filtersByLabel.dietary || [])
          .filter((filter) => theme?.filters?.includes(filter.value))
          .map(filterTypeToCheckbox)
      : (filtersByLabel.dietary || []).map(filterTypeToCheckbox);

  const allergyFilters =
    theme && theme.filters
      ? (filtersByLabel.allergy || [])
          .filter((filter) => theme?.filters?.includes(filter.value))
          .map(filterTypeToCheckbox)
      : (filtersByLabel.dietary || []).map(filterTypeToCheckbox);

  return (
    <div className={cx('filter-panel', className)}>
      <div className="slide-indicator">{BottomSheetTopLine} </div>
      {onClose && (
        <Button
          className="close-button"
          variant="light"
          onClick={handleClickClose}
        >
          Done
        </Button>
      )}
      <div className="filter-title">
        <h4>Filters</h4>
      </div>
      {dietaryFilters.length ? (
        <>
          <div className="filter-group-title">
            <b>Dietary preferences</b>
          </div>
          <div className="filter-group">{dietaryFilters}</div>
        </>
      ) : null}
      {allergyFilters.length ? (
        <>
          <div className="filter-group-title">
            <b>Allergens</b>
          </div>
          <div className="filter-group">{allergyFilters}</div>
        </>
      ) : null}
    </div>
  );
}

export default FilterPanel;
