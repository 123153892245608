import _ from 'lodash';
import * as R from 'ramda';
import { useMemo } from 'react';
import { cx, css } from '@emotion/css';

import * as G from '../../nom-server/src/generated/frontendGraphql';

import { useNavigate, useParams } from 'react-router-dom';
import Footer from './Footer';
import Item from './Item';
import NavBar from './NavBar';
import { SectionExpanded } from './SectionExpanded';
import { SectionInline } from './SectionInline';
import { SectionNav } from './SectionNav';
import * as M from '../models/menu.model';
import { useGlobalStyles } from './utils/useGlobalStyles';
import FloatingChat from './FloatingChat';

export function Page({ restaurant }: { restaurant: M.Restaurant }) {
  const params = useParams();
  // Url path segments
  const pathAry = useMemo(() => params['*']?.split('/') || [], [params['*']]);

  useGlobalStyles(`
  html {
    background: black;
  }
  body {
    margin: 0 auto;
    max-width: 600px;
  }
  `);

  const firstPath = _.head(pathAry);
  const maybeFirstSection = firstPath
    ? restaurant.sectionById[firstPath]
    : undefined;

  // Maps the path segments into sections
  const pathSections = useMemo(() => {
    // Starts with the 2nd path segment because the first is taken care of when rendering Page
    return _.tail(pathAry).reduce(
      (sectionAry, sectionId) => {
        // Uses reduce to walk the section tree and find the correct segment objects from ids
        const lastSection = _.last(sectionAry);
        if (lastSection?.childSectionIds?.includes(sectionId)) {
          sectionAry.push(restaurant.sectionById[sectionId]);
          return sectionAry;
        }
        return sectionAry;
      },
      maybeFirstSection ? [maybeFirstSection] : [],
    );
  }, [pathAry]);

  // If the last id is an item, get it
  const item = useMemo(() => {
    const lastPathId = _.last(pathAry);
    const maybeItem = lastPathId && restaurant.itemById[lastPathId];
    return maybeItem;
  }, [pathAry]);

  // Finds which section subsegment to render
  const renderableSections = useMemo(() => {
    // Find last terminal section. 'inline' is the only terminal section.
    const idx = _.findLastIndex(
      pathSections,
      (section) => section.type === 'inline',
    );
    // If not found, render all sections
    if (idx === -1) {
      return pathSections;
    }
    // If the last section in path is is terminal, render it
    if (idx === pathSections.length - 1) {
      return pathSections.slice(-1);
    }
    // Render all sections after the last terminal section
    return pathSections.slice(idx + 1);
  }, [pathSections]);

  // Reverses it so its easier to render
  const reversedRenderableSections = useMemo(
    () => R.reverse(renderableSections),
    [renderableSections],
  );

  if (item) {
    return (
      <>
        <NavBar
          colorScheme="blue"
          backTo={'/' + pathAry.slice(0, -1).join('/')}
          backLabel={'Back'}
          settings={restaurant.settings}
        />
        <Item item={item} restaurant={restaurant} />
        <Footer restaurant={restaurant} />
        <FloatingChat item={item} restaurant={restaurant} />
      </>
    );
  }

  console.log(reversedRenderableSections)

  return (
    <>
      <NavBar
        colorScheme="blue"
        backTo={'/' + pathAry.slice(0, -1).join('/')}
        backLabel={'Back'}
        settings={restaurant.settings}
      />
      <ReversedSections
        restaurant={restaurant}
        reversedSections={reversedRenderableSections}
        sectionPathAry={pathSections}
      />
      <Footer restaurant={restaurant} />
      <FloatingChat
        sections={reversedRenderableSections}
        restaurant={restaurant}
      />
    </>
  );
}

function ReversedSections({
  restaurant,
  reversedSections,
  sectionPathAry,
  children,
}: {
  restaurant: M.Restaurant;
  sectionPathAry: G.SectionV2[];
  reversedSections: G.SectionV2[];
  children?: React.ReactNode;
}): JSX.Element {
  const lastSection = _.head(reversedSections);
  if (!lastSection) {
    return <>{children}</>;
  }

  switch (lastSection.type) {
    case 'nav':
      // https://www.figma.com/file/HFdZpzKn7MSGPhx2pBtVRF/Nom-Waldorf?node-id=929%3A24225&t=skiOGA3MCdogcVjz-4
      return (
        <ReversedSections
          restaurant={restaurant}
          sectionPathAry={sectionPathAry.slice(0, -1)}
          reversedSections={_.tail(reversedSections)}
        >
          <SectionNav
            key={lastSection?.id}
            restaurant={restaurant}
            section={lastSection}
            sectionPathAry={sectionPathAry}
          >
            {children}
          </SectionNav>
        </ReversedSections>
      );
    case 'inline':
      // https://www.figma.com/file/HFdZpzKn7MSGPhx2pBtVRF/Nom-Waldorf?node-id=864%3A28140&t=skiOGA3MCdogcVjz-4
      // Should always be the last section rendered, so it doesnt have children
      return (
        <ReversedSections
          restaurant={restaurant}
          sectionPathAry={sectionPathAry.slice(0, -1)}
          reversedSections={_.tail(reversedSections)}
        >
          <SectionInline
            key={lastSection?.id}
            restaurant={restaurant}
            section={lastSection}
            sectionPathAry={sectionPathAry}
          />
        </ReversedSections>
      );
    case 'expanded':
    // https://www.figma.com/file/HFdZpzKn7MSGPhx2pBtVRF/Nom-Waldorf?node-id=929%3A24684&t=skiOGA3MCdogcVjz-4
    default:
      return (
        <ReversedSections
          restaurant={restaurant}
          sectionPathAry={sectionPathAry.slice(0, -1)}
          reversedSections={_.tail(reversedSections)}
        >
          <SectionExpanded
            key={lastSection?.id}
            restaurant={restaurant}
            section={lastSection}
            sectionPathAry={sectionPathAry}
          >
            {children}
          </SectionExpanded>
        </ReversedSections>
      );
  }
}
