import { css } from '@emotion/css';
import * as G from '../../nom-server/src/generated/frontendGraphql';

const Header = ({
  settings,
  displayName,
}: {
  settings?: G.RestaurantSettings | null;
  displayName: string;
}) => {
  return (
    <div
      className={css`
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
      `}
    >
      {settings?.headerImage && (
        <img
          src={settings.headerImage}
          className={css`
            padding-bottom: 25px;
            max-height: 350px;
            max-width: 90%;
          `}
        />
      )}
      {settings?.headerLogo ? (
        <img
          src={settings.headerLogo}
          className={css`
            max-height: 60px;
            max-width: 90%;
          `}
        />
      ) : (
        <div
          className={css`
            font-size: 25px;
            font-family: var(--font-sans);
            padding: 15px 0;
            width: 300px;
            color: ${settings?.sectionHeaderTextColor
              ? settings.sectionHeaderTextColor
              : '#fff'};
          `}
        >
          {displayName}
        </div>
      )}
    </div>
  );
};

export default Header;
