import { combineReducers } from 'redux';
import { selectedItemListReducer } from './selectedItemList/reducer.selectedItemList';
import { integrationsReducer } from './integrations/reducer.integrations';
import { filtersReducer } from './filters/reducer.filters';
import { chatReducer } from './chat/reducer.chat';
import { languageReducer } from './language/reducer.language';

export default combineReducers({
  selectedItemList: selectedItemListReducer,
  integrations: integrationsReducer,
  filters: filtersReducer,
  chat: chatReducer,
  language: languageReducer,
});
