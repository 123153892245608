/**
 * @file This file is used for utility functions that relate to events (e.g.
 * browser events, react synethetic events, etc).
 */

import React from 'react';

export function preventDefault<T = unknown>(event: React.SyntheticEvent<T>) {
  event.preventDefault();
}
