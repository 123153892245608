export const YoutubeIcon: React.FC<{
  color?: string;
  className?: string;
}> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 21 21"
      xmlSpace="preserve"
      className={props.className}
    >
      <path
        className="st0"
        fill={props.color}
        d="M8.35,13.62V7.38l5.49,3.12L8.35,13.62z M20.56,5.41c-0.24-0.91-0.95-1.63-1.86-1.87  C17.07,3.1,10.5,3.1,10.5,3.1s-6.57,0-8.2,0.44C1.39,3.79,0.68,4.5,0.44,5.41C0,7.06,0,10.5,0,10.5s0,3.44,0.44,5.09  c0.24,0.91,0.95,1.63,1.86,1.87c1.64,0.44,8.2,0.44,8.2,0.44s6.57,0,8.2-0.44c0.9-0.24,1.62-0.96,1.86-1.87  C21,13.94,21,10.5,21,10.5S21,7.06,20.56,5.41"
      />
    </svg>
  );
};
