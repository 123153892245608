import { AddItem, RemoveItem, UpdateItem } from './actions.selectedItemList';
import { Item } from '../../models/menu.model';
import { SelectedItem } from '../../models/selection.model';
import {
  buildSelectedItem,
  updateSelectedItem,
} from '../../utils/selection.utils';

export const buildAddItem = (
  itemToAdd: Item,
  optionValueTable: number[][] = [],
  numberOfItems: number,
): AddItem => {
  return {
    type: 'AddItem',
    item: buildSelectedItem(itemToAdd, optionValueTable, numberOfItems),
  };
};

export const buildRemoveItem = (id: string): RemoveItem => ({
  type: 'RemoveItem',
  id,
});

export const buildUpdateItem = (
  id: string,
  updatedItem: SelectedItem,
  optionValueTable: number[][] = [],
  numberOfItems: number,
): UpdateItem => {
  return {
    type: 'UpdateItem',
    id,
    item: updateSelectedItem(updatedItem, optionValueTable, numberOfItems),
  };
};
