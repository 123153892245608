import { css } from '@emotion/css';
import { useEffect, useState } from 'react';
import KipsuButton from './KipsuButton';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import * as M from '../models/menu.model';

const inputStyles = css`
  border: 1px solid #9f9f9f;
  border-radius: 3px;
  height: 40px;
  font-size: 16px;
  color: lightgrey;
  background-color: #161616;
  margin: 5px auto;
  width: 300px;
  padding: 0 15px;
`;

const KipsuForm: React.FC<{
  requestedItem: string;
  mainColor: string;
  settings: G.RestaurantSettings;
  isInRoomDining?: boolean;
  sectionById: M.Restaurant["sectionById"];
}> = (props) => {
  const [party, setParty] = useState(0);
  const [celebration, setCelebration] = useState('');
  const [allergies, setAllergies] = useState('');
  const [fb, setFB] = useState('');
  const [roomNumber, setRoom] = useState(0);

  return (
    <div
      className={css`
        padding: 30px;
        max-width: 100vw;
        width: 400px;
        margin: 0 auto;
        left: 0;
        right: 0;
        position: fixed;
        min-height: 450px;
        top: 20vh;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        z-index: 20;
        background-color: black;
      `}
    >
      <div
        className={css`
          background: black;
          padding-bottom: 30px;
          padding-top: 10px;
          margin-top: 32px;
          text-transform: uppercase;
          text-align: center;
          text-decoration: none;
          color: #ffffff !important;
          font: 15px var(--font-sans);
          display: inline-flex;
          width: 100%;
          margin-top: 0;
          display: flex;
          justify-content: center;
          padding-right: 0;
          padding-left: 0;
          font-size: 18px;
        `}
      >
        {props.isInRoomDining ? 'COLLECT MY TRAY' : 'CONTACT CONCIERGE'}
      </div>
      {props.isInRoomDining ? (
        <input
          type="number"
          placeholder={'Room Number'}
          onKeyUp={(e) =>
            setRoom(parseInt((e.target as HTMLInputElement).value))
          }
          className={inputStyles}
        />
      ) : (
        <div className={css`
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        `}
        >
          <input
            type="number"
            placeholder={'Party size'}
            onKeyUp={(e) =>
              setParty(parseInt((e.target as HTMLInputElement).value))
            }
            className={inputStyles}
          />
          <input
            type="text"
            placeholder={'Allergies or dietary restrictions'}
            onKeyUp={(e) => setAllergies((e.target as HTMLInputElement).value)}
            className={inputStyles}
          />
          <input
            type="text"
            placeholder={'Any preferences for F&B'}
            onKeyUp={(e) => setFB((e.target as HTMLInputElement).value)}
            className={inputStyles}
          />
          <input
            type="text"
            placeholder={'Are you celebrating anything?'}
            onKeyUp={(e) =>
              setCelebration((e.target as HTMLInputElement).value)
            }
            className={inputStyles}
          />
        </div>
      )}
      <div
        className={css`
          height: 30px;
          width: 300px;
        `}
      ></div>
      <KipsuButton
        requestedItem={props.requestedItem}
        fullWidth={true}
        partySize={party}
        fb={fb}
        allergies={allergies}
        celebration={celebration}
        formEnabled={true}
        mainColor={props.mainColor ? props.mainColor : ''}
        settings={props.settings}
        roomNumber={roomNumber}
        isInRoomDining={props.isInRoomDining}
        sectionById={props.sectionById}
      />
    </div>
  );
};

export default KipsuForm;
