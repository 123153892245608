import React, { useCallback } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { Item } from '../models/menu.model';
import { NotchLeft, NotchRight } from '../icons';

import Image from './Image.component';

import './ItemImageCarousel.component.css';

function handleImageError(event: React.SyntheticEvent<HTMLImageElement>) {
  event.currentTarget.onerror = null;
  event.currentTarget.src =
    'https://tetranoir.github.io/nom/apple-touch-icon.png';
}

interface ItemImageCarouselParams {
  images: string[];
  clickToScroll?: boolean;
  onClick?: (event: React.MouseEvent) => unknown;
}

function ItemImageCarousel(
  params: ItemImageCarouselParams,
): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const { images, onClick, clickToScroll = true } = params;

  const handleImageClick = useCallback(
    (e: React.MouseEvent) => {
      onClick?.(e);
    },
    [onClick],
  );

  const handleArrowClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const carouselItems = images.map((image) => (
    <Carousel.Item key={image}>
      <Image
        className="item-image-underlay"
        loading="lazy"
        src={image}
        onClick={handleImageClick}
        onError={handleImageError}
      />
      <Image
        className="item-image"
        loading="lazy"
        src={image}
        onClick={handleImageClick}
        onError={handleImageError}
      />
    </Carousel.Item>
  ));

  return (
    <Carousel
      className="item-image-carousel"
      interval={null}
      wrap
      controls={clickToScroll}
      onClick={handleArrowClick}
      nextIcon={NotchRight}
      prevIcon={NotchLeft}
      indicators={false}
    >
      {carouselItems}
    </Carousel>
  );
}

export default ItemImageCarousel;
