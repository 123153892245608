import { IntegrationsState as State } from './types.integrations';
import { IntegrateMenufy as Action } from './actions.integrations';

export function integrationsReducer(
  state: State | undefined,
  action: Action,
): State {
  state = state || {};

  switch (action.type) {
    case 'IntegrateMenufy':
      return {
        ...state,
        menufy: action.menufy,
      };
    default:
      return state;
  }
}
