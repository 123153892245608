import React, { useRef, useEffect } from 'react';
import QRCode from 'qrcode';

import './QrCode.component.css';

interface QrCodeParams {
  title?: string;
  // Text that will be turned into a qr code.
  text: string;
}

function QrCode(params: QrCodeParams) {
  const { title, text } = params;

  const canvasEl = useRef(null);

  useEffect(() => {
    if (canvasEl) {
      QRCode.toCanvas(canvasEl.current, text);
    }
  }, [canvasEl]);

  return (
    <div className="qr-code">
      <canvas ref={canvasEl}></canvas>
      {title && <h5>{title}</h5>}
    </div>
  );
}

export default QrCode;
