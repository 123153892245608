import { cx, css } from '@emotion/css';
import { useEffect, useMemo, useState } from 'react';
import SearchBar from './SearchBar';
import NavBar from './NavBar';
import './Selector.css';

import * as M from '../models/menu.model';
import * as G from '../../nom-server/src/generated/frontendGraphql';
import ListItem from './ListItem';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SectionHeader from './SectionHeader';
import KipsuButton from './KipsuButton';
import { useGlobalStyles } from './utils/useGlobalStyles';

const discoverOptions = [
  'RELAXING',
  'THRILLING',
  'EXCITING',
  'ROMANTIC',
  'Tasty',
  'boozy',
];
const experienceOptions = [
  'FOR KIDS',
  'FOR COUPLES',
  'FOR LARGE GROUPS',
  'FOR TEENS',
  'FOR FAMILIES OF ALL AGES',
  'LESSONS',
];

interface SelectorProps {
  options?: Array<string>;
  title?: string;
  select: any;
  updated?: string;
  big: boolean;
}

const Selector = ({ options, title, select, updated, big }: SelectorProps) => {
  const [selected, setSelected] = useState('');

  const clickOption = (option: string) => {
    setSelected(option);
    select(option);
    console.log(option);
  };

  useEffect(() => {
    setSelected('');
  }, [updated]);

  return (
    <div
      className={css`
        text-align: center;
        background-color: ${'#032165'};
        color: white;
        padding-top: 5%;
      `}
    >
      {title?.toUpperCase()}
      <div className={big ? 'bigScrollContainer' : 'smallScrollContainer'}>
        <div className={big ? 'bigScroll' : 'smallScroll'}>
          {options?.map((option) => (
            <div
              key={option}
              onClick={() => clickOption(option)}
              className={cx(
                css`
                  margin: 10px;
                  border-radius: 5px;
                  min-width: max-content;
                  width: max-content;
                  padding: 0px 5px;
                  cursor: pointer;
                  border: 1px solid transparent;
                `,
                selected == option ? 'filterButton' : '',
              )}
            >
              {option.toUpperCase()}
            </div>
          ))}
        </div>

        <div className={big ? 'bigScroll' : 'smallScroll'}>
          {options?.map((option) => (
            <div
              key={option}
              onClick={() => clickOption(option)}
              className={cx(
                css`
                  margin: 10px;
                  border-radius: 5px;
                  min-width: max-content;
                  width: max-content;
                  padding: 0px 5px;
                  cursor: pointer;
                  border: 1px solid transparent;
                `,
                selected == option ? 'filterButton' : '',
              )}
            >
              {option.toUpperCase()}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SelectorWrapper = ({ restaurant }: { restaurant: M.Restaurant }) => {
  useGlobalStyles(`
  html {
    background: ${restaurant.settings?.mainColor || '#000'};
  }
  body {
    margin: 0 auto;
    max-width: 600px;
  }
  `);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [discoverOption, setDiscover] = useState(
    'discover' in Object.fromEntries([...searchParams])
      ? searchParams.get('discover')
      : '',
  );
  const [experienceOption, setExperience] = useState(
    'experience' in Object.fromEntries([...searchParams])
      ? searchParams.get('experience')
      : '',
  );
  const [searchResults, setResults] = useState<Array<G.ItemV2>>([]);
  const [searchResultSections, setSections] = useState<
    Record<string, G.SectionV2>
  >({});
  const [updated, setUpdated] = useState('');

  function clickDiscover(selected: string) {
    setDiscover(selected);
  }

  function clickExperience(selected: string) {
    setExperience(selected);
  }

  const [itemPathsById, setItemPathsById] = useState<
    Record<string, string[][]>
  >({});

  // Load and process backend restaurant
  useEffect(() => {
    // Set itemPathsBy(Item)Id
    setItemPathsById(processRestaurants());
  }, [restaurant.rootSectionIds, restaurant.sectionById]);

  const processRestaurants = () => {
    const itemPathsById: Record<string, string[][]> = {};

    // eslint-disable-next-line no-inner-declarations
    function dfs(section: G.SectionV2, path: string[]) {
      section.itemIds?.forEach((itemId) => {
        if (!itemPathsById[itemId]) {
          itemPathsById[itemId] = [];
        }
        itemPathsById[itemId].push(path);
      });
      section.childSectionIds?.forEach((section) => {
        dfs(restaurant.sectionById[section], path.concat([section]));
      });
    }
    restaurant.rootSectionIds?.forEach((section) =>
      dfs(restaurant.sectionById[section], [section]),
    );
    return itemPathsById;
  };

  const performSearch = (discover: string, experience: string) => {
    const list: Array<G.ItemV2> = [];
    const sections: Record<string, G.SectionV2> = {};
    let itemPathsID: Record<string, string[][]> = itemPathsById;
    if (Object.entries(itemPathsById).length === 0) {
      itemPathsID = processRestaurants();
    }
    console.log(itemPathsById);
    restaurant.items?.forEach((item) => {
      if (
        item.name.toUpperCase().includes(discover) ||
        item.name.toUpperCase().includes(experience) ||
        item.description?.toUpperCase().includes(discover) ||
        item.description?.toUpperCase().includes(experience)
      ) {
        for (const i in itemPathsID) {
          if (i == item.id) {
            sections[item.id] = restaurant.sectionById[itemPathsID[i][0][0]];
          }
        }
        list.push(item);
      }
    });
    setSections(sections);
    console.log(sections);
    setResults(list);
    console.log(list);
  };

  const itemPaths = (id: string) => {
    let path = window.location.origin;
    itemPathsById[id]?.forEach((id) => {
      path += '/' + id;
    });
    path += '/' + id;
    return path;
  };

  const newSearch = () => {
    setDiscover('');
    setExperience('');
    if (updated == '') {
      setUpdated('reset');
    } else {
      setUpdated('');
    }
  };

  useEffect(() => {
    // Add query with options and search
    if (discoverOption && experienceOption) {
      let url = '';
      if ('discover' in Object.fromEntries([...searchParams])) {
        url = window.location.href;
      } else {
        url =
          window.location.href +
          '?discover=' +
          encodeURIComponent(discoverOption) +
          '&&experience=' +
          encodeURIComponent(experienceOption);
      }

      window.history.replaceState(null, '', url);

      performSearch(discoverOption, experienceOption);
    }
  }, [discoverOption, experienceOption]);

  return (
    <div
      className={css`
        background-color: ${restaurant.settings?.mainColor || '#000'};
        text-align: center;
        padding-bottom: 10%;
      `}
    >
      <div
        className={css`
          padding-bottom: 10%;
        `}
      >
        <div
          onClick={() => {
            if (discoverOption && experienceOption) {
              setDiscover('');
              setExperience('');
              navigate('/search');
            } else {
              navigate('/');
            }
          }}
        >
          <NavBar
            colorScheme="blue"
            backTo={'/'}
            backLabel={'Home'}
            settings={restaurant.settings}
          />
        </div>

        <div
          className={css`
            height: 15px;
          `}
        ></div>

        <SearchBar
          preFilledText={
            discoverOption || experienceOption
              ? discoverOption + ' EXPERIENCES ' + experienceOption
              : 'EXPLORE PEDREGAL'
          }
          to="/search"
          newSearch={newSearch}
        />

        <div
          className={css`
            height: 15px;
          `}
        ></div>

        {searchResults.length === 0 || !discoverOption || !experienceOption ? (
          <>
            <Selector
              title="discover"
              options={discoverOptions}
              select={clickDiscover}
              updated={updated}
              big={false}
            />
            <Selector
              title="experience"
              options={experienceOptions}
              select={clickExperience}
              updated={updated}
              big={true}
            />
            {searchResults.length === 0 &&
            experienceOption &&
            discoverOption ? (
              <div
                className={css`
                  text-align: center;
                  color: white;
                  margin-top: 2em;
                `}
              >
                NO RESULTS FOUND
              </div>
            ) : null}
          </>
        ) : (
          <div
            className={css`
              background-color: white;
            `}
          >
            {[...new Set(Object.values(searchResultSections))].map(
              (section) => (
                <div key={section.name}>
                  <SectionHeader
                    headerText={section.name}
                    restaurant={restaurant}
                  />
                  <div
                    className={css`
                      padding: 20px 0px;
                    `}
                  >
                    {searchResults.map(
                      (childSection) =>
                        searchResultSections[childSection.id] == section && (
                          <ListItem
                            key={childSection.id}
                            restaurant={restaurant}
                            image={
                              childSection.images ? childSection.images[0] : ''
                            }
                            id={childSection.id || ''}
                            description={
                              childSection.description
                                ? childSection.description.split('.').length >=
                                  2
                                  ? childSection.description.split('.')[0] +
                                    '.' +
                                    childSection.description.split('.')[1]
                                  : childSection.description.split('.')[0]
                                : ''
                            }
                            type="vertical"
                            colorScheme="light"
                            to={itemPaths(childSection.id) || ''}
                            expanded={false}
                            title={childSection.name}
                            isSearchResult={true}
                          />
                        ),
                    )}
                    <ListItem
                      restaurant={restaurant}
                      type="vertical"
                      colorScheme="light"
                      title={'See other ' + section.name}
                      id={section.id}
                      to={section.id}
                      expanded={true}
                      image={section.images ? section.images[0] : ''}
                    />
                  </div>
                </div>
              ),
            )}
            <div
              className={css`
                background-color: black;
                justify-content: center;
                padding-top: 40px;
                padding-bottom: 40px;
                text-align: center;
              `}
            >
              <SectionHeader
                headerText="LOOKING FOR SOMETHING ELSE?"
                background="#000000"
                restaurant={restaurant}
              />
              {restaurant.settings?.kipsuNumber ? (
                <KipsuButton
                  requestedItem=""
                  isSearch={true}
                  mainColor={restaurant.settings?.mainColor || ''}
                  settings={restaurant.settings!}
                  sectionById={restaurant.sectionById}
                />
              ) : null}
            </div>
          </div>
        )}

        {restaurant.settings?.headerLogo && (
          <div
            className={css`
              text-align: center;
              margin: 2em 0;
              width: 100%;
            `}
          >
            <img src={restaurant.settings.headerLogo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectorWrapper;
