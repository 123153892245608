import { useState, useEffect } from 'react';
import { Menu } from '../models/menu.model';
import { FetcherResponse } from './UseFetcher';

import sunandmoon from './sunandmoon.json';
import tigerlily from './tigerlily.json';
import prabh from './prabh.json';
import pineapplethai from './pineapplethai.json';
import mustbethai from './mustbethai.json';
import coupdethai from './coupdethai.json';
import moshimoshi from './moshimoshi.json';
import mersea from './mersea.json';
import rooh from './rooh.json';
import baanyaai from './baanyaai.json';
import sakridcoffee from './sakridcoffee.json';
import bushido from './bushido.json';
import coltsneckstillhouse from './coltsneckstillhouse.json';
import meetinghouse from './meetinghouse.json';
import treeofwishes from './treeofwishes.json';
import pastasisters from './pastasisters.json';
import butchersdaughter from './butchersdaughter.json';
import berkeleysocialclub from './berkeleysocialclub.json';
import tabscroll from './tabscroll.json';
import immthai from './immthai.json';
import islandvintagewinebar from './islandvintagewinebar.json';
import ristobar from './ristobar.json';
import shosha from './shosha.json';
import tonyalbaspizza from './tonyalbaspizza.json';
import battery from './battery.json';

// The key must match the customer's subdomain.
const keyedCustomerData = {
  // sunandmoon,
  // tigerlily,
  // prabh,
  // pineapplethai,
  // mustbethai,
  // coupdethai,
  // moshimoshi,
  // mersea,
  // rooh,
  // baanyaai,
  // sakridcoffee,
  // bushido,
  // coltsneckstillhouse,
  // meetinghouse,
  // butchersdaughter,
  // pastasisters,
  // berkeleysocialclub,
  // tabscroll,
  // immthai,
  // islandvintagewinebar,
  //ristobar,
  shosha,
  tonyalbaspizza,
  battery,
};

(window as any).keyedCustomerData = keyedCustomerData;

type CustomerId = keyof typeof keyedCustomerData;

export function getMenu(customerId: string): Promise<Menu> {
  if (customerId in keyedCustomerData) {
    return Promise.resolve(keyedCustomerData[customerId as CustomerId]);
  }
  return Promise.reject(new Error(`No menu for "${customerId}" exists.`));
}

export function useGetMenu(customerId: string): FetcherResponse<Menu> {
  const [data, setData] = useState<Menu | undefined>(undefined);
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        setData(await getMenu(customerId));
      } catch (error) {
        error instanceof Error && setError(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [customerId]);

  return { data, loading, error };
}

export type Api = typeof getMenu;
