/**
 * @file This file is used to define all the React providers used in the app.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import './index.css';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary.component';
import * as serviceWorker from './serviceWorker';
import { store } from './state/store';
import {
  createClient,
  dedupExchange,
  fetchExchange,
  Provider as UrqlProvider,
} from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';

// TODO fix static port
export const url =
  process.env.NODE_ENV === 'development'
    ? `${window.location.protocol}//${window.location.hostname}:8080`
    : `${window.location.protocol}//${window.location.hostname}`;

const fetchOptions =
  process.env.NODE_ENV === 'development'
    ? ({
        credentials: 'include',
      } as const)
    : ({} as const);

const client = createClient({
  url: url + '/graphql',
  exchanges: [dedupExchange, cacheExchange({}) as any, fetchExchange],
  preferGetMethod: 'force',
  fetchOptions,
});

ReactDOM.render(
  <React.StrictMode>
    <UrqlProvider value={client}>
      <ReduxProvider store={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </ReduxProvider>
    </UrqlProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
