import { PathPattern } from 'react-router';
import {
  useNavigate,
  useMatch,
  useLocation,
  matchPath,
} from 'react-router-dom';

export function useMatches(paths: (string | PathPattern)[]) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return paths.map((path) => useMatch(path)).find(Boolean);
}
