import { Msg, ChatActionTypes } from './types.chat';

export const addMessage = (message: Msg): ChatActionTypes => ({
  type: 'ADD_MESSAGE',
  payload: message,
});

export const setWelcomeMessage = (message: string): ChatActionTypes => ({
  type: 'SET_WELCOME_MESSAGE',
  payload: message,
});

export const initializeWebSocket = (
  webSocketInstance: WebSocket | null,
): ChatActionTypes => ({
  type: 'INITIALIZE_WEBSOCKET',
  payload: webSocketInstance,
});

export type ChatActions =
  | ReturnType<typeof addMessage>
  | ReturnType<typeof setWelcomeMessage>
  | ReturnType<typeof initializeWebSocket>;
