/**
 * @file This file creates the item Details to show menu item details.
 */

import React, { useLayoutEffect, useState, useContext } from 'react';
import ReactGA from 'react-ga4';
import Button from 'react-bootstrap/Button';
import MediaQuery from 'react-responsive';
import * as R from 'ramda';
import { isEqual } from 'lodash';
import cx from 'clsx';

import { Item, Theme, Option } from '../models/menu.model';
import ItemImageCarousel from '../components/ItemImageCarousel.component';
import ItemOption from '../components/ItemOption.component';
import { XButton, TrashIcon } from '../icons';

import { useSelector } from 'react-redux';
import { buildSelectedOptions } from '../utils/selection.utils';
import { store, State } from '../state/store';
import { SelectedItem } from '../models/selection.model';

import { useDispatch } from 'react-redux';

import {
  ChiliOneIcon,
  ChiliTwoIcon,
  ChiliThreeIcon,
  ChiliFourIcon,
} from '../icons';
import PhotoContest from '../components/PhotoContest.component';
import ItemSummary from '../components/ItemSummary.component';
import Image from '../components/Image.component';
import {
  buildAddItem,
  buildUpdateItem,
  buildRemoveItem,
} from '../state/selectedItemList/actionCreators.selectedItemList';
import { dispatch } from '../state/store';
import { useMaybeMenufy } from '../integrations/UseIntegrations';

import './ItemDetails.container.css';
import { recordAndRedirect } from '../utils/analytics.utils';
import { training } from '../App';
const reduxDispatchItem = R.compose(dispatch, buildAddItem);

function ItemDetails({
  itemName,
  item,
  onClose,
  contest,
  theme,
  onConfirm,
  currentValueCounts,
  selectedItem,
  onRemove,
}: {
  itemName?: string;
  item?: Item;
  onClose: () => void;
  contest: boolean;
  theme?: Theme;
  onConfirm: (name: string, message: string) => unknown;
  currentValueCounts?: number[][];
  selectedItem?: SelectedItem;
  onRemove?: (item: SelectedItem) => unknown;
}): React.ReactElement {
  const [dispatchItem] = useMaybeMenufy(reduxDispatchItem);
  const [valueCounts, setValueCounts] = useState<number[][]>([]);
  const [itemCount, setItemCount] = useState(1);
  const [orderError, setOrderError] = useState<string>('');
  const order = useSelector((state: State) => state.selectedItemList.order);
  const isTraining = useContext(training);
  function defaultValueCounts() {
    return (item?.options || []).map((op) => op.values.map(() => 0));
  }

  function incValueCounts(i: number, j: number) {
    if (valueCounts[i][j] == null) {
      valueCounts[i][j] = 0;
    }
    valueCounts[i][j] += 1;
    setValueCounts([...valueCounts]);
  }

  function decValueCounts(i: number, j: number) {
    valueCounts[i][j] -= 1;
    setValueCounts([...valueCounts]);
  }

  useLayoutEffect(() => {
    if (selectedItem && selectedItem.quantity > 1) {
      setItemCount(selectedItem.quantity);
    }
    if (currentValueCounts && currentValueCounts.length) {
      setValueCounts(currentValueCounts);
    } else {
      setValueCounts(defaultValueCounts());
    }
  }, [item, currentValueCounts]);

  function incItemCount() {
    setItemCount(itemCount + 1);
  }

  function decItemCount() {
    if (itemCount > 1) {
      setItemCount(itemCount - 1);
    }
  }

  /* To reset states after adding item to order */
  function resetCounts() {
    setItemCount(1);
    setValueCounts(defaultValueCounts());
  }

  function handleAddItemToOrder() {
    if (item) {
      ReactGA.event({
        category: 'Order',
        action: 'added',
        label: item.name,
        value: itemCount,
      });
      const selectedOptions = buildSelectedOptions(item, valueCounts);
      const matchingItemsInOrder: SelectedItem[] = Object.values(order).filter(
        (selItem) =>
          selItem.item === item && isEqual(selItem.options, selectedOptions),
      );
      // Check if item with specified options is already in order; if so, update
      if (matchingItemsInOrder.length) {
        dispatch(
          buildUpdateItem(
            matchingItemsInOrder[0].id,
            matchingItemsInOrder[0],
            valueCounts,
            matchingItemsInOrder[0].quantity + itemCount,
          ),
        );
      } else {
        dispatchItem(item, valueCounts, itemCount);
      }
      onConfirm(item.name, 'successfully added to your list.');
    }

    onClose();
    resetCounts();
  }

  function handleUpdateSelectedItem() {
    if (selectedItem) {
      dispatch(
        buildUpdateItem(selectedItem.id, selectedItem, valueCounts, itemCount),
      );
      onClose();
      onConfirm(selectedItem.item.name, 'was updated.');
      setTimeout(() => resetCounts(), 2000);
    }
  }

  function handleRemoveItemFromOrder() {
    if (selectedItem && onRemove) {
      onRemove(selectedItem);
    }
  }

  function recordStoryInteraction() {
    ReactGA.event({
      category: 'Story',
      action: 'link followed',
    });
  }

  if (!item) {
    return (
      <div className="item-details">
        <h3>Item, {itemName}, not found</h3>
      </div>
    );
  }

  const ItemImages = () => (
    <>
      <MediaQuery maxWidth={1040}>
        {/* Temporarily setting to false to prevent arrows on single images */}
        <ItemImageCarousel
          key={item.name}
          images={item.images}
          clickToScroll={false}
        />
      </MediaQuery>
      <MediaQuery minWidth={1041}>
        {item.images.map((image) => {
          return (
            <div key={image}>
              <Image
                className="details-item-image"
                loading="lazy"
                src={image}
              />
            </div>
          );
        })}
      </MediaQuery>
    </>
  );

  let tooFewOptions = false;
  let tooManyOptions = false;
  const renderOptions = item.options.map((option, i) => {
    const totalOptionCount = R.sum(valueCounts[i] || []);
    tooFewOptions = Boolean(option.min && totalOptionCount < option.min);
    tooManyOptions = Boolean(option.max && totalOptionCount > option.max);
    return (
      <ItemOption
        key={option.name}
        item={item}
        option={option}
        opIdx={i}
        valueCounts={valueCounts}
        incValueCounts={incValueCounts}
        decValueCounts={decValueCounts}
      />
    );
  });

  const optionErrorOrRun = (fn: () => void) => () => {
    if (tooFewOptions) {
      setOrderError('Too few options chosen.');
      return;
    }
    if (tooManyOptions) {
      setOrderError('Too many options chosen.');
      return;
    }
    setOrderError('');
    fn();
  };

  const wrappedUpdateSelectedItem = optionErrorOrRun(handleUpdateSelectedItem);
  const wrappedAddItemToOrder = optionErrorOrRun(handleAddItemToOrder);

  return (
    <div className="item-details">
      <button className="back-btn" onClick={onClose}>
        {XButton}
      </button>
      <div className="desktop-images-col">
        {item.images.length ? (
          <ItemImages />
        ) : (
          <div className="item-image-placeholder">
            {contest && theme && theme.contestLink && theme.contestTitle ? (
              <PhotoContest
                link={theme.contestLink}
                title={theme.contestTitle}
              />
            ) : null}
          </div>
        )}
      </div>
      <div className="desktop-details-col">
        <ItemSummary item={item } displayContent = {true}/>
        {item.story && (
          <div className="story">
            <div className="story-title">{item.story.title}</div>
            <p>
              {item.story.text}{' '}
              {item.story.link && item.story.hyperlink && (
                <a
                  href={item.story.link}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    //e.preventDefault();
                    recordAndRedirect(item.story?.link, 'story');
                  }}
                >
                  {item.story.hyperlink}
                </a>
              )}
            </p>
          </div>
        )}
        {item.note && <div className="note">{item.note}</div>}
        {renderOptions}

        {item.spiceMinimum && (
          <div className="spice">
            <h1> Spice Options </h1>

            <div className="chili-box">
              {item.spiceMinimum === 1 && (
                <label className="check-container flex">
                  <span className="checkmarkbox">{ChiliOneIcon}</span>
                </label>
              )}
              {item.spiceMinimum < 3 && (
                <label className="check-container">
                  <span className="checkmarkbox">{ChiliTwoIcon}</span>
                </label>
              )}

              <label className="check-container">
                <span className="checkmarkbox">{ChiliThreeIcon}</span>
              </label>

              <label className="check-container">
                <span className="checkmarkbox">{ChiliFourIcon}</span>
              </label>
            </div>
          </div>
        )}
      </div>
      {selectedItem ? (
        <div className="update-order-buttons">
          {/* TODO: Make this a reusable component to prevent redudency */}
          <div className="inc-button-trash-row">
            <div className="increment-buttons updated-version">
              <span onClick={() => decItemCount()} className="inc-button">
                –⁠
              </span>
              <span style={{ fontSize: '20px' }}>{itemCount}</span>
              <span onClick={() => incItemCount()} className="inc-button">
                +
              </span>
            </div>
            <Button
              className="remove-order-button"
              variant="light"
              onClick={handleRemoveItemFromOrder}
            >
              {TrashIcon}
            </Button>
          </div>

          <Button
            className="update-order-button"
            variant="light"
            onClick={wrappedUpdateSelectedItem}
          >
            Update Item
          </Button>
          {orderError ? (
            <div className="order-error">{orderError}</div>
          ) : undefined}
        </div>
      ) : (
        <div className="add-order-buttons">
          <div className="increment-buttons">
            <span onClick={() => decItemCount()} className="inc-button">
              –⁠
            </span>
            <span style={{ fontSize: '20px' }}>{itemCount}</span>
            <span onClick={() => incItemCount()} className="inc-button">
              +
            </span>
          </div>
          <Button
            className="add-order-button"
            variant="light"
            onClick={wrappedAddItemToOrder}
          >
            Add to My List
          </Button>
          {orderError ? (
            <div className="order-error">{orderError}</div>
          ) : undefined}
        </div>
      )}
    </div>
  );
}

export default ItemDetails;
