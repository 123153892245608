import React from 'react';
import cx from 'clsx';
import { css } from '@emotion/css';
import { useMatch, Link, To } from 'react-router-dom';

const tabStyles = css`
  color: #000000;
  &:hover {
    color: #000000;
  }
`;

const matchedStyles = css`
  font-weight: 600;
  text-decoration: underline;
`;

export const Tab: React.FC<{
  to?: To;
  text?: string;
  onClick?: (e: React.MouseEvent) => void;
}> = ({ to = '', text, onClick, children }) => {
  const href = typeof to === 'string' ? to : to.pathname || 'NONE';
  const matchRoute = useMatch(href);

  if (href.startsWith('http')) {
    return (
      <a
        href={href}
        className={cx(tabStyles, { [matchedStyles]: to && matchRoute })}
        onClick={onClick}
        rel="noreferrer"
        target="_blank"
      >
        {text}
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      className={cx(tabStyles, { [matchedStyles]: to && matchRoute })}
      onClick={onClick}
    >
      {text}
      {children}
    </Link>
  );
};

export default Tab;
