import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import cx from 'classnames';

import './SelItemSummary.component.css';
import { SelectedItemSummary, SelectedItem } from '../models/selection.model';
import Image from './Image.component';

import { EditIcon, TrashIcon, ErrorIcon } from '../icons';
import { buildUpdateItem } from '../state/selectedItemList/actionCreators.selectedItemList';

interface SelItemSummaryParams {
  itemSummary: SelectedItemSummary;
  editButton: boolean;
  onRemove: (item: SelectedItem) => unknown;
  onConfirm: (name: string, message: string) => unknown;
  onClick: (
    selectedItem: SelectedItemSummary,
    event: React.MouseEvent,
  ) => unknown;
}

function SelItemSummary(params: SelItemSummaryParams): React.ReactElement {
  const { itemSummary, editButton, onRemove, onConfirm, onClick } = params;
  const dispatch = useDispatch();
  const initialItemQuantity: number = itemSummary.quantity;
  const currentValueCounts: number[][] = itemSummary.options.map((op) =>
    op.values.map((val) => val.count),
  );

  const [editView, setEditView] = useState(false);
  const [itemQuantity, setItemQuantity] = useState(initialItemQuantity);

  function handleEdit() {
    setEditView(!editView);
  }

  function incItemQuantity() {
    setItemQuantity(itemQuantity + 1);
  }

  function decItemQuantity() {
    if (itemQuantity > 1) {
      setItemQuantity(itemQuantity - 1);
    }
  }

  function handleUpdateItem() {
    dispatch(
      buildUpdateItem(
        itemSummary.id,
        itemSummary,
        currentValueCounts,
        itemQuantity,
      ),
    );
    setEditView(false);
    onConfirm(itemSummary.item.name, 'was updated.');
  }

  function handleClick(event: React.MouseEvent) {
    onClick(itemSummary, event);
  }

  const navigate = useNavigate();
  function handleEditAddOns() {
    navigate(`/order/${itemSummary.id}`);
    setEditView(false);
  }

  function handleRemoveItemMessage(item: SelectedItemSummary) {
    onRemove(item);
  }

  return (
    <div
      key={itemSummary.id}
      className={cx('selected-item-summary', {
        'selected-item-edit-view': editView,
      })}
      onClick={handleClick}
    >
      <div className="images-item-trash">
        <div className="images-item">
          {itemSummary.item.images.length ? (
            <Image
              className="item-image"
              loading="lazy"
              src={itemSummary.item.images[0]}
            />
          ) : null}

          <div className="name-values-price">
            <div className="sel-item-name">
              {itemSummary.item.name} &nbsp;
              {itemSummary.quantity > 1 && (
                <div className="quant"> x {itemSummary.quantity} </div>
              )}
            </div>
            {itemSummary.values.length ? (
              <>
                {editView && (
                  <div className="edit-options">
                    <div>Add-ons:</div>
                    <div
                      style={{ textDecoration: 'underline' }}
                      onClick={handleEditAddOns}
                    >
                      Edit
                    </div>
                  </div>
                )}

                <div className="value-list">
                  {itemSummary.values.map(
                    (option) =>
                      option.value.name !== 'No Modifiers' && (
                        <div
                          className="sel-item-value-price"
                          key={option.value.name}
                        >
                          <div>
                            {option.count} {option.value.name}
                          </div>

                          <div> &nbsp; </div>
                        </div>
                      ),
                  )}
                </div>
              </>
            ) : null}
            <div className="item-price">
              {' '}
              ${(itemSummary.totalPrice / 100).toFixed(2)}
            </div>
          </div>
        </div>
        <Button
          className="modify-order-button"
          variant="light"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            handleRemoveItemMessage(itemSummary);
          }}
        >
          {TrashIcon}
        </Button>
      </div>

      {!itemSummary.values.length && itemSummary.item.options.length ? (
        <div className="modification-notice">
          <div className="mod-text">
            {ErrorIcon} This item needs customization.
          </div>
          <div className="customize-button" onClick={handleEditAddOns}>
            Customize
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SelItemSummary;
