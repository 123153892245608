import { createStore, applyMiddleware, Middleware } from 'redux';
import rootReducer from './reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { SelectedItemListState } from './selectedItemList/types.selectedItemList';
import { SelectedItemListAction } from './selectedItemList/actions.selectedItemList';
import { IntegrationsState } from './integrations/types.integrations';
import { IntegrationsAction } from './integrations/actions.integrations';
import { FiltersState } from './filters/types.filters';
import { FiltersAction } from './filters/actions.filters';
import { ChatState } from './chat/types.chat';
import { ChatActions } from './chat/actions.chat';
import { LanguageState } from './language/types.language';
import { LanguageActions } from './language/actions.language';

export type Action =
  | SelectedItemListAction
  | IntegrationsAction
  | FiltersAction
  | ChatActions
  | LanguageActions;

export type State = {
  selectedItemList: SelectedItemListState;
  integrations: IntegrationsState;
  filters: FiltersState;
  chat: ChatState;
  language: LanguageState;
};

const middlewares: Middleware[] = [];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

// eslint-disable-next-line @typescript-eslint/ban-types
export const store = createStore<State, Action, {}, {}>(
  rootReducer,
  undefined,
  composedEnhancers,
);

export const dispatch = store.dispatch;

// Hot reloading
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./reducer', () => store.replaceReducer(rootReducer));
}
