import { css } from '@emotion/css';
import GridComponent from './GridComponent';
import Header from './Header';
import SearchBar from './SearchBar';
import Promotion from './Promotion';
import LanguageToggle from './LanguageToggle';
import { useMemo, useState } from 'react';
import * as M from '../models/menu.model';
import { useGlobalStyles } from './utils/useGlobalStyles';
import { DateTime } from 'luxon';
import Modal from './Modal';
import { customAnalyticsEvent } from '../utils/analytics.utils';
import { useSelector } from 'react-redux';
import { State } from '../state/store';
import { getLocalizedValue } from '../utils/translate.utils';

function PageHome({ restaurant }: { restaurant: M.Restaurant }): JSX.Element {
  useGlobalStyles(`
  html {
    background: ${
      restaurant?.settings?.homeBackgroundColor
        ? restaurant.settings.homeBackgroundColor
        : '#032165'
    };
  }
  body {
    margin: 0 auto;
    max-width: 600px;
  }
  `);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    if (!isModalOpen) {
      const extendedEventData = {
        category: `Chatbot Clicks${
          window.origin.includes('localhost') ? ' Localhost' : ''
        }`,
        label: 'Modal Chat Entrypoint Clicked',
        action: 'Modal Chat Entrypoint Clicked',
        time: new Date().toUTCString(),
      };
      customAnalyticsEvent(extendedEventData);
    }
    setIsModalOpen(!isModalOpen);
  };

  const selectedLanguage = useSelector(
    (state: State) => state.language.language,
  );

  const dt = DateTime.local();
  (window as any).dt = dt;
  const currentDay = dt.weekday - 1;
  const currentTime = dt.toLocaleString(DateTime.TIME_24_SIMPLE);

  const sections = useMemo(() => {
    return (
      restaurant.rootSectionIds?.map(
        (sectionId) => restaurant.sectionById[sectionId],
      ) || []
    );
  }, [restaurant]);

  const blocks = useMemo(() => {
    return sections
      .filter((section) => !section.disabled)
      .map((s) => {
        const sectionTitle =
          selectedLanguage !== 'en' && 'namesLocalized' in s
            ? (s as any).namesLocalized?.[selectedLanguage]
            : s.name;
        return {
          title: s.name,
          titleLocalized: sectionTitle,
          to: s.id,
          src: s.images ? s.images[0] : '',
        };
      });
  }, [sections, selectedLanguage]);

  const finalPromotions = useMemo(() => {
    return (
      restaurant.promotions?.filter((promotion) => {
        if (promotion.disabled) {
          return false;
        }

        const promotionEndTime = promotion.endTime;
        const promotionStartTime = promotion.startTime;
        const availableDays = promotion.availableDays;

        // Invalid/incomplete/missing timed promotion -> render normally
        if (
          !promotionEndTime ||
          !promotionStartTime ||
          !availableDays?.length
        ) {
          return true;
        }

        // Check if current time within timed promotion config
        if (
          availableDays.includes(currentDay) &&
          currentTime >= promotionStartTime &&
          currentTime <= promotionEndTime
        ) {
          return true;
        }

        // Don't render/use
        return false;
      }) || []
    );
  }, [restaurant]);

  return (
    <div
      className={css`
        background-color: ${restaurant?.settings?.homeBackgroundColor
          ? restaurant.settings.homeBackgroundColor
          : '#032165'};
        text-align: center;
      `}
    >
      {/* Promotions */}
      <div
        className={css`
          padding-top: ${restaurant.settings?.enLink && restaurant.settings?.esLink ? '4%' : '10%'};
          padding-bottom: 25px;
        `}
      >
        {/* TODO -YIFANG TRANSLATE TOGGLE CODE*/}

        {/* Language toggle */}
        {restaurant.settings?.languages ? (
          <LanguageToggle
            languages={restaurant.settings?.languages}
            fontColor={restaurant.settings?.mainColor || ''}
          />
        ) : null}

        {/* END COPIED TRANSLATE TOGGLE CODE HERE */}
        {finalPromotions?.map((p) => {
          const promotionTitle = getLocalizedValue(
            p,
            'titlesLocalized',
            selectedLanguage,
            p.title || '',
          );
          const promotionDescription = getLocalizedValue(
            p,
            'descriptionsLocalized',
            selectedLanguage,
            p.description || '',
          );

          return (
            <Promotion
              key={p.title}
              title={p.title || ''}
              titleLocalized={promotionTitle || ''}
              itemId={p.itemId || ''}
              description={p.description || ''}
              descriptionLocalized={promotionDescription || ''}
              link={p.link || ''}
              restaurant={restaurant}
            />
          );
        })}
      </div>

      {/* Header Image */}
      <Header
        settings={restaurant.settings}
        displayName={restaurant.displayName}
      />
      <div
        className={css`
          border-top: 2px solid
            ${restaurant.settings?.homeSeparatorLineColor || '#fff'};
          width: 90%;
          margin: auto auto 10px auto;
        `}
      ></div>

      {/* wptest AI chatbot */}
      {window.location.origin.includes('wptest') ||
      window.location.origin.includes('hlctest') ? (
        <>
          {/* Override default search bar behavior */}
          <div className={css`
              margin-top: 15px;
            `}>
            <div onClick={toggleModal}>
              <SearchBar preFilledText="Ask NOMĀDO AI Anything" to="" restaurant={restaurant} />
            </div>
            <Modal
              isOpen={isModalOpen}
              onClose={toggleModal}
              restaurant={restaurant}
            />
          </div>
        </>
      ) : null}

      {/* Gridcells */}
      {blocks && <GridComponent blocks={blocks} restaurant={restaurant} />}

      {/* Footer Image */}
      {restaurant.settings?.footerImage && (
        <>
          <div
            className={css`
              ${restaurant?.settings?.homeSeparatorLineColor
                ? 'border-top: 2px solid ' +
                  restaurant?.settings?.homeSeparatorLineColor +
                  ';'
                : 'border-top: 2px solid white;'}
              width: 90%;
              margin: 5% auto;
            `}
          ></div>
          <img
            src={restaurant?.settings?.footerImage}
            className={css`
              padding-bottom: 15px;
              max-height: 300px;
              max-width: 90%;
            `}
          />
        </>
      )}
    </div>
  );
}

export default PageHome;
